// Import libraries.
import { I18n } from "@lingui/core";
import { select, put, all, takeLatest, call } from "redux-saga/effects";

// Import types.
import { CookieConsentLevel } from "utils/CookieConsent";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";

// Import redux actions.
import { SET_APPLICATION_INFORMATION } from "store/actions/app";
import { SET_SESSION } from "store/actions/session";
import { SET_AVAILABLE_COMPANIES } from "store/actions/availableCompanies";
import { SET_CURRENT_COMPANY_ALIAS } from "store/actions/currentCompanyAlias";
import { SET_AVAILABLE_APPS } from "store/actions/availableApps";
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";
import { CLOUD_CODE_EDITOR_RESET } from "store/actions/cloudCodeEditor";

// Import redux sagas.
import { saveSession } from "./sessionSagas";
import { loadTheme } from "./themeSagas";
import { populateCurrentUser } from "./userSagas";
import { populateAvailableApps, setAppId } from "./appSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import LocalStorageUtils from "utils/LocalStorage";
import StringUtils from "utils/String";

interface PopulateAvailableCompanies {
    type: "company.populateAvailableCompanies";
}

interface PopulateCurrentCompanyAlias {
    type: "company.populateCurrentCompanyAlias";
}

interface SetCompanyId {
    type: "company.setCompanyId";
    payload: {
        i18n: I18n;
        companyId: string;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

interface SetCompanyIdAlias {
    type: "company.setCompanyIdAlias";
    payload: {
        companyIdAlias: string | null;
        appId?: string | null;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

// Attempts to populate the available companies that are accessible by the current user.
export function* populateAvailableCompanies(_action?: PopulateAvailableCompanies) {
    try {
        console.log("Populating Available Companies...");

        const response: HttpResponse = yield Http.GET("admin/serveradmin/getAdminUserCompanyInfoEnabledOnly", { superCompaniesOnly: false });
        if (Http.isStatusOk(response) && Array.isArray(response.data)) {
            const availableCompanies = response.data.map(processTeamInfo);

            // Sort the available companies by name.
            availableCompanies.sort((a, b) => a.companyName.localeCompare(b.companyName));

            yield put(SET_AVAILABLE_COMPANIES(availableCompanies));

            return true;
        }
    } catch (error: any) {
        console.error("populateAvailableCompanies - ERROR", error);
    }

    yield put(SET_AVAILABLE_COMPANIES([]));

    return false;
}

// Attempts to populate the current company alias.
export function* populateCurrentCompanyAlias(_action?: PopulateCurrentCompanyAlias) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    try {
        // Only proceed with fetching the current company alias if one is selected.
        if (session.companyIdAlias) {
            console.log("Populating Current Company Alias...");

            const response: HttpResponse = yield Http.GET("admin/serveradmin/team-admin-company-read", Http.JSON_HEADERS);
            if (Http.isStatusOk(response) && response.data) {
                const currentCompanyAlias = processTeamInfo(response.data);

                yield put(SET_CURRENT_COMPANY_ALIAS(currentCompanyAlias));

                return true;
            }
        }
    } catch (error: any) {
        console.error("populateCurrentCompanyAlias - ERROR", error);
    }

    yield put(SET_CURRENT_COMPANY_ALIAS(null));

    return false;
}

// Attempts to set the current user's company id.
export function* setCompanyId(action: SetCompanyId) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current user's profile id.
    const getCurrentUserProfileId = (state: PortalState): string | null => (state.currentUser ? state.currentUser.profileId : null);
    const currentUserProfileId: string | null = yield select(getCurrentUserProfileId);

    // Get the currently available companies.
    const getAvailableCompanies = (state: PortalState): TeamInfo[] => [...state.availableCompanies];
    const availableCompanies: TeamInfo[] = yield select(getAvailableCompanies);

    // Get the target company.
    const targetCompany = availableCompanies.find((item) => item.companyId === action.payload.companyId) || null;

    // If the target company is not available, then just return (nothing to do).
    if (!targetCompany) return false;

    let autoSelectCompanyIdAlias: string | null = null;
    let autoSelectAppId: string | null = null;

    try {
        console.log("Selecting Company", targetCompany.companyId);

        if (!applicationInformation.loadingBasicState) {
            // Indicate that we are now loading basic state.
            applicationInformation.loadingBasicState = true;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Attempt to set the current company id on the server.
        const response: HttpResponse = yield Http.POST("admin/selectCompany", { companyId: targetCompany.companyId }, null, Http.JSON_HEADERS);
        if (Http.isStatusOk(response)) {
            // Set various fields on the current session.
            session.isSuper = targetCompany.isSuper;
            session.isTeamAdmin = targetCompany.isAdmin;
            session.companyId = targetCompany.companyId;
            delete session.companyIdAlias;
            delete session.appId;
            delete session.playerId;
            session.requiresTermsOfServiceAcceptance = false;

            // Check for any redirects.
            if (!StringUtils.isNullOrEmpty(response.data.redirect)) {
                if (response.data.redirect === "../admin/terms") {
                    session.requiresTermsOfServiceAcceptance = true;
                } else {
                    console.log("### Processing Redirect ###", response.data.redirect);

                    document.location = response.data.redirect;

                    return false;
                }
            }

            // Update the session in the redux store.
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);

            // Load the company theme, current company alias, available companies and available apps.
            yield all([
                call(loadTheme, { type: "theme.loadTheme", payload: { companyId: session.companyId } }),
                call(populateCurrentUser, { type: "user.populateCurrentUser", payload: { i18n: action.payload.i18n, basic: false } }),
                call(populateAvailableApps),
            ]);

            if (!session.isSuper) {
                // If the newly selected company is a Non-SUPER team, then update the "lastteam_autoselect_<profileId>" in local storage.
                // This is used to auto-select the Non-SUPER company dropdown list when logging in.
                LocalStorageUtils.setItem(
                    "lastteam_autoselect_" + currentUserProfileId,
                    JSON.stringify({
                        companyId: session.companyId,
                    }),
                    CookieConsentLevel.FUNCTIONALITY
                );
            }

            // Update the "lastteam_<profileId>" in local storage.
            // This represents that last company the user was logged in with (either a SUPER team or a Non-SUPER team).
            LocalStorageUtils.setItem(
                "lastteam_" + currentUserProfileId,
                JSON.stringify({
                    companyId: session.companyId,
                }),
                CookieConsentLevel.FUNCTIONALITY
            );

            if (session.isSuper) {
                // Auto-select the last company alias (if possible).
                let lastAliasData: { companyId: string } | null = null;
                try {
                    const lastAliasData_RAW = LocalStorageUtils.getItem("lastalias_" + currentUserProfileId);

                    if (lastAliasData_RAW) {
                        lastAliasData = JSON.parse(lastAliasData_RAW);
                    }
                } catch (e: any) {}

                if (lastAliasData?.companyId && lastAliasData.companyId.trim().length > 0) {
                    autoSelectCompanyIdAlias = lastAliasData.companyId;
                }
            } else {
                // Auto-select the last app (if possible).
                let lastGameData: { gameId: string } | null = null;
                try {
                    const lastGameData_RAW = LocalStorageUtils.getItem("lastgame_" + currentUserProfileId + "_" + session.companyId);

                    if (lastGameData_RAW) {
                        lastGameData = JSON.parse(lastGameData_RAW);
                    }
                } catch (e: any) {}

                if (lastGameData?.gameId && lastGameData.gameId.trim().length > 0) {
                    autoSelectAppId = lastGameData.gameId;
                }
            }

            return true;
        }
    } catch (error: any) {
        console.error("setCompanyId - ERROR", error);
    } finally {
        console.log("Done Selecting Company!");

        // Get the current available apps.
        const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
        const availableApps: AppInfo[] = yield select(getAvailableApps);

        // Get the target app.
        const autoSelectApp = availableApps.find((item) => item.appId === autoSelectAppId) || null;

        if (autoSelectApp) {
            yield call(setAppId, { type: "app.setAppId", payload: { appId: autoSelectAppId, path: action.payload.path, history: action.payload.history } });
        } else if (autoSelectCompanyIdAlias) {
            yield call(setCompanyIdAlias, { type: "company.setCompanyIdAlias", payload: { companyIdAlias: autoSelectCompanyIdAlias, path: action.payload.path, history: action.payload.history } });
        } else {
            if (applicationInformation.loadingBasicState) {
                // Indicate that we are no longer loading basic state.
                applicationInformation.loadingBasicState = false;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }

            // If the optional path and history are both present, push the requested path onto the history.
            if (action.payload.history && action.payload.path && action.payload.history.location.pathname !== action.payload.path) {
                action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            }
        }
    }

    // Set various fields on the current session.
    session.isSuper = false;
    session.isTeamAdmin = false;
    delete session.companyId;
    delete session.companyIdAlias;
    delete session.appId;
    delete session.playerId;
    delete session.requiresTermsOfServiceAcceptance;
    delete session.isLiveLocked;

    // Update the session in the redux store.
    yield put(SET_SESSION(CloneUtils.clone(session)));
    yield call(saveSession);

    // Reset the Cloud Code Editor state.
    yield put(CLOUD_CODE_EDITOR_RESET());

    // Clear the available privileges and screen settings.
    yield put(SET_AVAILABLE_PRIVILEGES([]));
    yield put(SET_SCREEN_SETTINGS([]));

    // Clear ther available apps.
    yield put(SET_AVAILABLE_APPS([]));

    return false;
}

// Attempts to set the current user's company id alias.
export function* setCompanyIdAlias(action: SetCompanyIdAlias) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current user's profile id.
    const getCurrentUserProfileId = (state: PortalState): string | null => (state.currentUser ? state.currentUser.profileId : null);
    const currentUserProfileId: string | null = yield select(getCurrentUserProfileId);

    // If the target company alias is not available, then just return (nothing to do).
    if (!action.payload.companyIdAlias) return false;

    let autoSelectAppId: string | null = null;

    try {
        console.log("Selecting Company Alias", action.payload.companyIdAlias);

        if (!applicationInformation.loadingBasicState) {
            // Indicate that we are now loading basic state.
            applicationInformation.loadingBasicState = true;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Attempt to set the current company id alias on the server.
        const response: HttpResponse = yield Http.POST("admin/serveradmin/super-set-team-for-team-menu-context", { teamId: action.payload.companyIdAlias, gameId: action.payload.appId }, null, Http.JSON_HEADERS);
        if (Http.isStatusOk(response)) {
            // Set various fields on the current session.
            session.companyIdAlias = action.payload.companyIdAlias;
            delete session.appId;
            delete session.playerId;

            // Update the session in the redux store.
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);

            // Load the company alias theme, current company alias and available apps.
            yield all([call(loadTheme, { type: "theme.loadTheme", payload: { companyId: session.companyIdAlias } }), call(populateCurrentCompanyAlias), call(populateAvailableApps)]);

            // Update the "lastalias_<profileId>" in local storage.
            LocalStorageUtils.setItem(
                "lastalias_" + currentUserProfileId,
                JSON.stringify({
                    companyId: session.companyIdAlias,
                }),
                CookieConsentLevel.FUNCTIONALITY
            );

            if (action.payload.appId && action.payload.appId.trim().length > 0) {
                // Auto-select the supplied app (if possible).
                autoSelectAppId = action.payload.appId;
            } else {
                // Auto-select the last app (if possible).
                let lastGameData: { gameId: string } | null = null;
                try {
                    const lastGameData_RAW = LocalStorageUtils.getItem("lastgame_" + currentUserProfileId + "_" + session.companyIdAlias);

                    if (lastGameData_RAW) {
                        lastGameData = JSON.parse(lastGameData_RAW);
                    }
                } catch (e: any) {}

                if (lastGameData?.gameId && lastGameData.gameId.trim().length > 0) {
                    autoSelectAppId = lastGameData.gameId;
                } else {
                    // Otherwise trigger the selection of the app (if applicable) based on the returned "appId" or the presence of a single app.
                    // When we set the companyIdAlias we may receive an "appId" in the response. If so then select that app. Failing that,
                    // there may only be a single app available, in that case we select that app. Otherwise we don't have a currently selected app.
                    const preSelectedAppId: string | null = response.data?.gameId || null;

                    if (preSelectedAppId && preSelectedAppId.trim().length > 0) {
                        autoSelectAppId = preSelectedAppId;
                    } else {
                        // Get the currently available apps.
                        const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
                        const availableApps: AppInfo[] = yield select(getAvailableApps);

                        if (availableApps.length === 1) {
                            autoSelectAppId = availableApps[0].appId;
                        }
                    }
                }
            }

            return true;
        }
    } catch (error: any) {
        console.error("setCompanyIdAlias - ERROR", error);
    } finally {
        console.log("Done Selecting Company Alias!");

        // Get the current available apps.
        const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
        const availableApps: AppInfo[] = yield select(getAvailableApps);

        // Get the target app.
        const autoSelectApp = availableApps.find((item) => item.appId === autoSelectAppId) || null;

        if (autoSelectApp) {
            yield call(setAppId, { type: "app.setAppId", payload: { appId: autoSelectAppId, path: action.payload.path, history: action.payload.history } });
        } else {
            if (applicationInformation.loadingBasicState) {
                // Indicate that we are no longer loading basic state.
                applicationInformation.loadingBasicState = false;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }

            // If the optional path and history are both present, push the requested path onto the history.
            if (action.payload.history && action.payload.path && action.payload.history.location.pathname !== action.payload.path) {
                action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            }
        }
    }

    // Set various fields on the current session.
    delete session.companyIdAlias;
    delete session.appId;
    delete session.playerId;
    delete session.isLiveLocked;

    // Update the session in the redux store.
    yield put(SET_SESSION(CloneUtils.clone(session)));
    yield call(saveSession);

    // Reset the Cloud Code Editor state.
    yield put(CLOUD_CODE_EDITOR_RESET());

    // Clear the available privileges and screen settings.
    yield put(SET_AVAILABLE_PRIVILEGES([]));
    yield put(SET_SCREEN_SETTINGS([]));

    // Clear the available apps.
    yield put(SET_AVAILABLE_APPS([]));

    // Clear the current company alias.
    yield put(SET_CURRENT_COMPANY_ALIAS(null));

    return false;
}

export const companyGeneratorMap = {
    "company.setCompanyId": setCompanyId,
    "company.setCompanyIdAlias": setCompanyIdAlias,
};

export default function* root() {
    yield all([
        takeLatest("company.populateAvailableCompanies", populateAvailableCompanies),
        takeLatest("company.populateCurrentCompanyAlias", populateCurrentCompanyAlias),
        takeLatest("company.setCompanyId", setCompanyId),
        takeLatest("company.setCompanyIdAlias", setCompanyIdAlias),
    ]);
}
