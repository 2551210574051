// Import libraries.
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";

// Import types.
import User from "types/common/User";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";

// Import components.
import IconButton from "components/common/button/IconButton";
import WidgetContainer from "components/common/widgets/WidgetContainer";

// Import icons.
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Report";

interface STATE_PROPS {
    currentUser: User | null;
    applicationInformation: ApplicationInformation;
}

interface DISPATCH_PROPS {
    dismissSystemMessage: () => void;
}
interface OWN_PROPS {}

interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
        applicationInformation: state.applicationInformation,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        dismissSystemMessage: () => dispatch({ type: "app.dismissSystemMessage" }),
    };
};

class SystemMessageBanner extends React.PureComponent<PROPS> {
    onClose = () => {
        this.props.dismissSystemMessage();
    };

    render() {
        const { classes, applicationInformation, currentUser } = this.props;

        const language = currentUser?.preferredLanguage || "en";

        const message = applicationInformation.systemMessage.message[language];

        const urgencyLevel = applicationInformation.systemMessage.urgencyLevel;

        const isDismissed = applicationInformation.systemMessage.isDismissed;

        if (urgencyLevel === 0 || !message || isDismissed) return null;

        return (
            <WidgetContainer
                className={classnames({
                    [classes.root]: true,
                    [classes.info]: urgencyLevel === 1,
                    [classes.warning]: urgencyLevel === 2,
                    [classes.error]: urgencyLevel === 3,
                })}
            >
                <div className={classes.content}>
                    {urgencyLevel === 1 && <InfoIcon className={classes.icon} />}
                    {urgencyLevel === 2 && <WarningIcon className={classes.icon} />}
                    {urgencyLevel === 3 && <ErrorIcon className={classes.icon} />}

                    <div className={classes.message} dangerouslySetInnerHTML={{ __html: message }} />

                    {urgencyLevel === 1 && (
                        <IconButton id={"dismiss"} onClick={this.onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </div>
            </WidgetContainer>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",

            minWidth: "unset",
            maxWidth: "calc(100% - 0.625em)",

            margin: "0.3125em",

            padding: "0.625em",

            border: "0.1em solid",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
        },
        icon: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "flex-start",
            marginRight: "0.3125em",
        },
        info: {
            backgroundColor: "var(--system-message-banner-info-background-color, inherit)",
            color: "var(--system-message-banner-info-color, inherit)",
        },
        warning: {
            backgroundColor: "var(--system-message-banner-warning-background-color, inherit)",
            color: "var(--system-message-banner-warning-color, inherit)",
        },
        error: {
            backgroundColor: "var(--system-message-banner-error-background-color, inherit)",
            color: "var(--system-message-banner-error-color, inherit)",
        },
        message: {
            flex: "1 1 auto",
            alignItems: "stretch",
            "& > *": {
                margin: 0,
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SystemMessageBanner));
