/**
 * Default Portal Branding.
 *
 * These are applied right from the get-go and are overridden by team/user themes.
 */
export const cssVariables = {
    /********************/
    /* Portal-X General */
    /********************/

    "font-family": "",
    "font-size": "1em",
    "line-height": "1.5",

    "primary-background-color": "rgba(36, 45, 52, 1)",
    "primary-color": "rgba(255, 255, 255, 1)",
    "primary-border-color": "rgba(45, 55, 62, 1)",

    "secondary-background-color": "rgba(45, 55, 62, 1)",
    "secondary-color": "rgba(204, 204, 204, 1)",
    "secondary-border-color": "rgba(75, 92, 104, 1)",

    /************************/
    /* Portal-X Breadcrumbs */
    /************************/

    "primary-breadcrumbs-background-color": "rgba(21, 60, 108, 1)",
    "primary-breadcrumbs-color": "rgba(255, 255, 255, 1)",
    "primary-breadcrumbs-border-color": "rgba(21, 60, 108, 1)",

    "secondary-breadcrumbs-background-color": "rgba(60, 72, 81, 1)",
    "secondary-breadcrumbs-color": "rgba(204, 204, 204, 1)",
    "secondary-breadcrumbs-border-color": "rgba(60, 72, 81, 1)",

    /*********************************/
    /* Portal-X Progress Indicators */
    /*********************************/

    "progress-indicator-color": "rgba(0, 159, 255, 1)",
    "progress-indicator-text-color": "rgba(155, 155, 155, 1)",

    /***********************/
    /* Portal-X Scrollbars */
    /***********************/

    "scrollbar-size": "0.4em",
    "scrollbar-track-color": "rgba(45, 55, 62, 1)",
    "scrollbar-thumb-color": "rgba(60, 72, 81, 1)",
    "scrollbar-thumb-radius": "0.2em",

    /******************/
    /* Portal-X Links */
    /******************/

    "link-color": "rgba(0, 159, 255, 1)",

    /*******************/
    /* Portal-X Header */
    /*******************/

    "header-background-color": "rgba(12, 41, 76, 1)",
    "header-color": "rgba(255, 255, 255, 1)",
    "header-border-color": "rgba(12, 41, 76, 1)",

    "header-menu-launcher-color": "rgba(255, 255, 255, 1)",
    "header-intercom-launcher-color": "rgba(255, 220, 0, 1)",

    /***********************/
    /* Portal-X Navigation */
    /***********************/

    "navigation-background-color": "rgba(17, 27, 39, 1)",
    "navigation-color": "rgba(255, 255, 255, 1)",
    "navigation-border-color": "rgba(55, 57, 65, 1)",

    "navigation-disabled-background-color": "rgba(17, 27, 39, 1)",
    "navigation-disabled-color": "rgba(55, 57, 65, 1)",

    "navigation-app-selector-background-color": "rgba(17, 27, 39, 1)",
    "navigation-app-selector-color": "rgba(255, 255, 255, 1)",
    "navigation-app-selector-border-color": "rgba(55, 57, 65, 1)",

    "navigation-app-selector-hover-background-color": "rgba(0, 109, 186, 1)",
    "navigation-app-selector-hover-color": "rgba(255, 220, 0, 1)",

    "navigation-app-selector-active-background-color": "rgba(255, 220, 0, 1)",
    "navigation-app-selector-active-color": "rgba(0, 109, 186, 1)",

    "navigation-team-selector-background-color": "rgba(17, 27, 39, 1)",
    "navigation-team-selector-color": "rgba(255, 255, 255, 1)",
    "navigation-team-selector-border-color": "rgba(55, 57, 65, 1)",

    "navigation-team-selector-hover-background-color": "rgba(0, 109, 186, 1)",
    "navigation-team-selector-hover-color": "rgba(255, 220, 0, 1)",

    "navigation-team-selector-active-background-color": "rgba(255, 220, 0, 1)",
    "navigation-team-selector-active-color": "rgba(0, 109, 186, 1)",

    "navigation-super-context-background-color": "rgba(251, 21, 8, 0.1)",
    "navigation-super-context-color": "rgba(251, 21, 8, 1)",

    "navigation-team-context-background-color": "rgba(43, 112, 252, 0.1)",
    "navigation-team-context-color": "rgba(43, 112, 252, 1)",

    "navigation-app-context-background-color": "rgba(26, 194, 98, 0.1)",
    "navigation-app-context-color": "rgba(26, 194, 98, 1)",

    /*****************/
    /* Portal-X Logs */
    /*****************/

    "logs-sys-info-color": "inherit",
    "logs-sys-warning-color": "inherit",
    "logs-sys-error-color": "inherit",

    "logs-app-info-color": "inherit",
    "logs-app-warning-color": "inherit",
    "logs-app-error-color": "inherit",

    "logs-sys-info-font-weight": "inherit",
    "logs-sys-warning-font-weight": "inherit",
    "logs-sys-error-font-weight": "inherit",

    "logs-app-info-font-weight": "inherit",
    "logs-app-warning-font-weight": "inherit",
    "logs-app-error-font-weight": "inherit",

    "logs-sys-info-font-style": "inherit",
    "logs-sys-warning-font-style": "inherit",
    "logs-sys-error-font-style": "inherit",

    "logs-app-info-font-style": "inherit",
    "logs-app-warning-font-style": "inherit",
    "logs-app-error-font-style": "inherit",

    /**********************/
    /* Portal-X Gradients */
    /**********************/

    /* Gradient start and end colors (for coloring gradients). */
    /* Gradients are not really used (currently). */
    "gradient-start-color": "rgba(255, 255, 255, 1)",
    "gradient-end-color": "rgba(255, 255, 255, 1)",

    /***********************/
    /* Portal-X Tabs */
    /***********************/

    "tabs-background-color": "initial",
    "tabs-color": "initial",

    "tabs-hover-background-color": "rgba(255, 255, 255, 0.03)",
    "tabs-hover-color": "initial",

    "tabs-active-background-color": "rgba(60, 72, 81, 1)",
    "tabs-active-color": "rgba(0, 159, 255, 1)",
    "tabs-active-border-color": "rgba(0, 159, 255, 1)",
    "tabs-active-indicator-size": "0.25em",

    "tabs-disabled-background-color": "initial",
    "tabs-disabled-color": "initial",

    /***************************/
    /* Portal-X Input Controls */
    /***************************/

    "field-height": "2em",
    "field-line-height": "1.5",

    "field-background-color": "rgba(60, 72, 81, 1)",
    "field-color": "rgba(149, 171, 188, 1)",
    "field-border-color": "rgba(0, 159, 255, 1)",

    "field-focused-background-color": "rgba(45, 55, 62, 1)",
    "field-focused-color": "rgba(255, 255, 255, 1)",
    "field-focused-border-color": "rgba(122, 122, 122, 1)",

    "field-disabled-background-color": "rgba(193, 193, 193, 1)",
    "field-disabled-color": "rgba(0, 0, 0, 1)",
    "field-disabled-border-color": "rgba(193, 193, 193, 1)",

    "field-error-background-color": "rgba(237, 190, 192, 1)",
    "field-error-color": "rgba(174, 13, 10, 1)",
    "field-error-border-color": "rgba(174, 13, 10, 1)",

    /*********************/
    /* Portal-X Popovers */
    /*********************/

    "popup-menu-background-color": "rgba(45, 55, 62, 1)",
    "popup-menu-color": "rgba(204, 204, 204, 1)",
    "popup-menu-border-color": "rgba(75, 92, 104, 1)",

    "popup-menu-box-shadow": "0px 0px 3px 0px rgba(255, 255, 255, 1)",

    "popup-menu-hover-background-color": "rgba(255, 255, 255, 0.1)",
    "popup-menu-hover-color": "inherit",

    "popup-menu-active-background-color": "rgba(60, 72, 81, 1)",
    "popup-menu-active-color": "rgba(255, 255, 255, 1)",

    /********************/
    /* Portal-X Buttons */
    /********************/

    /* Neutral */
    "button-neutral-background-color": "rgba(0, 0, 0, 0)",
    "button-neutral-color": "rgba(0, 159, 255, 1)",
    "button-neutral-border-color": "rgba(0, 0, 0, 0)",

    "button-neutral-hover-background-color": "rgba(45, 55, 62, 1)",
    "button-neutral-hover-color": "rgba(149, 171, 188, 1)",
    "button-neutral-hover-border-color": "rgba(45, 55, 62, 1)",

    "button-neutral-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-neutral-disabled-color": "rgba(0, 0, 0, 1)",
    "button-neutral-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Primary */
    "button-primary-background-color": "rgba(0, 159, 255, 1)",
    "button-primary-color": "rgba(255, 255, 255, 1)",
    "button-primary-border-color": "rgba(0, 159, 255, 1)",

    "button-primary-hover-background-color": "rgba(48, 107, 141, 1)",
    "button-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-primary-hover-border-color": "rgba(48, 107, 141, 1)",

    "button-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Secondary */
    "button-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-secondary-color": "rgba(0, 159, 255, 1)",
    "button-secondary-border-color": "rgba(0, 159, 255, 1)",

    "button-secondary-hover-background-color": "rgba(26, 33, 40, 1)",
    "button-secondary-hover-color": "rgba(204, 204, 204, 1)",
    "button-secondary-hover-border-color": "rgba(204, 204, 204, 1)",

    "button-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Samantic - Positive (Primary) */
    "button-semantic-positive-primary-background-color": "rgba(70, 209, 77, 1)",
    "button-semantic-positive-primary-color": "rgba(255, 255, 255, 1)",
    "button-semantic-positive-primary-border-color": "rgba(70, 209, 77, 1)",

    "button-semantic-positive-primary-hover-background-color": "rgba(31, 135, 36, 1)",
    "button-semantic-positive-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-semantic-positive-primary-hover-border-color": "rgba(31, 135, 36, 1)",

    "button-semantic-positive-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-positive-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-positive-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Samantic - Positive (Secondary) */
    "button-semantic-positive-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-semantic-positive-secondary-color": "rgba(26, 194, 98, 1)",
    "button-semantic-positive-secondary-border-color": "rgba(26, 194, 98, 1)",

    "button-semantic-positive-secondary-hover-background-color": "rgba(23, 29, 33, 1)",
    "button-semantic-positive-secondary-hover-color": "rgba(22, 161, 81, 1)",
    "button-semantic-positive-secondary-hover-border-color": "rgba(22, 161, 81, 1)",

    "button-semantic-positive-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-positive-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-positive-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Semantic - Negative (Primary) */
    "button-semantic-negative-primary-background-color": "rgba(235, 64, 55, 1)",
    "button-semantic-negative-primary-color": "rgba(255, 255, 255, 1)",
    "button-semantic-negative-primary-border-color": "rgba(235, 64, 55, 1)",

    "button-semantic-negative-primary-hover-background-color": "rgba(141, 37, 31, 1)",
    "button-semantic-negative-primary-hover-color": "rgba(220, 220, 220, 1)",
    "button-semantic-negative-primary-hover-border-color": "rgba(141, 37, 31, 1)",

    "button-semantic-negative-primary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-negative-primary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-negative-primary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /* Semantic - Negative (Secondary) */
    "button-semantic-negative-secondary-background-color": "rgba(36, 45, 52, 1)",
    "button-semantic-negative-secondary-color": "rgba(235, 64, 55, 1)",
    "button-semantic-negative-secondary-border-color": "rgba(235, 64, 55, 1)",

    "button-semantic-negative-secondary-hover-background-color": "rgba(23, 29, 33, 1)",
    "button-semantic-negative-secondary-hover-color": "rgba(141, 37, 31, 1)",
    "button-semantic-negative-secondary-hover-border-color": "rgba(141, 37, 31, 1)",

    "button-semantic-negative-secondary-disabled-background-color": "rgba(193, 193, 193, 1)",
    "button-semantic-negative-secondary-disabled-color": "rgba(0, 0, 0, 1)",
    "button-semantic-negative-secondary-disabled-border-color": "rgba(193, 193, 193, 1)",

    /********************/
    /* Portal-X Chips   */
    /********************/

    "chip-default-background-color": "rgba(0, 159, 255, 1)",
    "chip-default-color": "rgba(255, 255, 255, 1)",

    "chip-positive-background-color": "rgba(70, 209, 77, 1)",
    "chip-positive-color": "rgba(255, 255, 255, 1)",

    "chip-negative-background-color": "rgba(251, 21, 8, 1)",
    "chip-negative-color": "rgba(255, 255, 255, 1)",

    "chip-warning-background-color": "rgba(253, 173, 11, 1)",
    "chip-warning-color": "rgba(255, 255, 255, 1)",

    /********************/
    /* Portal-X Labels  */
    /********************/

    "label-default-color": "rgba(0, 159, 255, 1)",

    "label-positive-color": "rgba(70, 209, 77, 1)",

    "label-negative-color": "rgba(251, 21, 8, 1)",

    "label-warning-color": "rgba(253, 173, 11, 1)",

    /********************/
    /* Portal-X Tooltip */
    /********************/
    "tooltip-background-color": "rgba(252, 251, 251, 1)",

    "tooltip-color": "rgba(21, 20, 20, 1)",

    "tooltip-border-color": "rgba(21, 20, 20, 1)",

    /******************/
    /* Portal-X Table */
    /******************/

    "table-row-odd-background-color": "initial",
    "table-row-odd-color": "initial",

    "table-row-even-background-color": "initial",
    "table-row-even-color": "initial",

    "table-row-hover-background-color": "rgba(102, 89, 128, 1)",
    "table-row-hover-color": "initial",

    "table-row-selected-background-color": "rgba(102, 89, 128, 1)",
    "table-row-selected-color": "initial",

    "table-cell-modified-background-color": "rgba(250, 201, 101, 1)",
    "table-cell-modified-color": "initial",

    /*********************/
    /* Portal-X Info Box */
    /*********************/

    "infobox-info-background-color": "rgba(60, 72, 81, 1)",
    "infobox-info-color": "rgba(0, 159, 255, 1)",
    "infobox-info-border-color": "rgba(60, 72, 81, 1)",

    "infobox-warning-background-color": "rgba(60, 72, 81, 1)",
    "infobox-warning-color": "rgba(254, 134, 0, 1)",
    "infobox-warning-border-color": "rgba(60, 72, 81, 1)",

    "infobox-error-background-color": "rgba(60, 72, 81, 1)",
    "infobox-error-color": "rgba(251, 21, 8, 1)",
    "infobox-error-border-color": "rgba(60, 72, 81, 1)",

    /***************************/
    /* Portal-X Message Banner */
    /***************************/

    "system-message-banner-info-background-color": "rgba(225, 244, 255, 1)",
    "system-message-banner-info-color": "rgba(0, 159, 255, 1)",
    "system-message-banner-info-border-color": "rgba(60, 72, 81, 1)",

    "system-message-banner-warning-background-color": "rgba(255, 231, 213, 1)",
    "system-message-banner-warning-color": "rgba(254, 134, 0, 1)",
    "system-message-banner-warning-border-color": "rgba(60, 72, 81, 1)",

    "system-message-banner-error-background-color": "rgba(255, 241, 241, 1)",
    "system-message-banner-error-color": "rgba(251, 21, 8, 1)",
    "system-message-banner-error-border-color": "rgba(60, 72, 81, 1)",

    /*******************/
    /* Portal-X Monaco */
    /*******************/
    "monaco-font-family": 'Menlo, Monaco, "Courier New", monospace',
    "monaco-font-size": "0.875em",
    "monaco-line-height": "1.125",

    /******************/
    /* Portal-X Jodit */
    /******************/
    "jodit-editor-toolbar-background-color": "initial",
    "jodit-editor-toolbar-border-color": "initial",
    "jodit-editor-toolbar-color": "initial",

    "jodit-editor-workplace-background-color": "initial",
    "jodit-editor-workplace-border-color": "initial",
    "jodit-editor-workplace-color": "initial",

    "jodit-editor-statusbar-background-color": "initial",
    "jodit-editor-statusbar-border-color": "initial",
    "jodit-editor-statusbar-color": "initial",

    /******************************/
    /* Portal-X Cloud Code Editor */
    /******************************/

    "cloud-code-editor-general-header-background-color": "rgba(18, 19, 20, 1)",
    "cloud-code-editor-general-header-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-general-content-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-general-content-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-file-tab-default-background-color": "rgba(168, 168, 168, 1)",
    "cloud-code-editor-file-tab-default-color": "rgba(0, 0, 0, 1)",

    "cloud-code-editor-file-tab-active-background-color": "rgba(47, 50, 52, 1)",
    "cloud-code-editor-file-tab-active-color": "rgba(157, 157, 157, 1)",

    "cloud-code-editor-banners-viewing-old-version-background-color": "rgba(225, 244, 23, 1)",
    "cloud-code-editor-banners-viewing-old-version-border-color": "rgba(225, 244, 23, 1)",
    "cloud-code-editor-banners-viewing-old-version-color": "rgba(83, 83, 83, 1)",

    "cloud-code-editor-banners-editing-old-version-background-color": "rgba(252, 79, 9, 1)",
    "cloud-code-editor-banners-editing-old-version-border-color": "rgba(252, 79, 9, 1)",
    "cloud-code-editor-banners-editing-old-version-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-no-longer-exists-background-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-no-longer-exists-border-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-no-longer-exists-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-in-progress-background-color": "rgba(18, 123, 246, 1)",
    "cloud-code-editor-banners-save-in-progress-border-color": "rgba(18, 123, 246, 1)",
    "cloud-code-editor-banners-save-in-progress-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-failed-background-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-save-failed-border-color": "rgba(251, 21, 8, 1)",
    "cloud-code-editor-banners-save-failed-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-banners-save-successful-background-color": "rgba(26, 194, 98, 1)",
    "cloud-code-editor-banners-save-successful-border-color": "rgba(26, 194, 98, 1)",
    "cloud-code-editor-banners-save-successful-color": "rgba(255, 255, 255, 1)",

    "cloud-code-editor-request-log-header-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-request-log-header-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-request-log-header-color": "rgba(127, 73, 230, 1)",

    "cloud-code-editor-request-log-content-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-request-log-content-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-request-log-content-color": "rgba(127, 73, 230, 1)",

    "cloud-code-editor-response-log-header-success-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-header-success-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-header-success-color": "rgba(26, 194, 98, 1)",

    "cloud-code-editor-response-log-content-success-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-content-success-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-content-success-color": "rgba(26, 194, 98, 1)",

    "cloud-code-editor-response-log-header-failure-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-header-failure-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-header-failure-color": "rgba(251, 21, 8, 1)",

    "cloud-code-editor-response-log-content-failure-background-color": "rgba(34, 36, 38, 1)",
    "cloud-code-editor-response-log-content-failure-border-color": "rgba(55, 58, 61, 1)",
    "cloud-code-editor-response-log-content-failure-color": "rgba(251, 21, 8, 1)",
};

export const other = {
    monaco: '{\n  "base": "vs",\n  "inherit": true,\n  "rules": [],\n  "colors": {}\n}',
    fontUrl: null,
    carouselUrl: null,
};
