// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import { SearchResult } from "../../engine/types";

// Import components.
import { Typography } from "@material-ui/core";
import IconButton from "components/common/button/IconButton";

// Import icons.
import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";

interface OWN_PROPS {
    results: SearchResult[] | null;

    onClose: () => void;
    onToggleOptions: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class Header extends React.PureComponent<PROPS> {
    render() {
        const { classes, results } = this.props;

        return (
            <div className={classes.root}>
                <Typography style={{ flex: "1 1 auto", fontWeight: "bold", overflow: "hidden" }} noWrap>
                    {results && results.length === 1 && <Trans>Search Results ({results?.length} item found):</Trans>}
                    {results && (results.length === 0 || results.length > 1) && <Trans>Search Results ({results?.length} items found):</Trans>}
                </Typography>

                <IconButton id={"options"} onClick={this.props.onToggleOptions}>
                    <ListIcon />
                </IconButton>

                <IconButton id={"close"} onClick={this.props.onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",

            padding: "0.5em 0.75em",
        },
    });

export default withStyles(styles)(Header);
