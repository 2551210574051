// Import libraries.
import { AbstractQueryGenerator } from "..";

// Import types.
import { Query, QueryOptions, SearchConfiguration } from "../../types";

// Import utilities.
import * as Patterns from "utils/Patterns";
import { parseQuery } from "../../utils";

// The name of the generator.
const GENERATOR_NAME = "SMART";

// Define some regex patterns used to "guess" potential hints.
const APP_ID_REGEX: RegExp = /^\d{5,5}$/i;
const ACCOUNT_NUMBER_REGEX: RegExp = /^\d{7,7}$/i;
const UUID_REGEX: RegExp = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;

/**
 * This generator attempts to "guess" the nature of the query being performed (for those queries that are missing any kind of "hint").
 *
 * This generator will analyse the raw query string using a series of regular expresssions and try to infer possible hints.
 *
 * It will then return those inferred hints (as a collection of Query instances) so that they can be executed by the search engine.
 */
class SmartGenerator extends AbstractQueryGenerator {
    private options: QueryOptions | null = null;

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.options = args.options || null;
        }
    };

    generateQueries = async (queryString: string, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        console.debug("Generating Query", GENERATOR_NAME, queryString, this.options);

        // Define a collection to hold the results.
        const queryResults: Query[] = [];

        const query = parseQuery(queryString);

        if (typeof query === "string") {
            if (APP_ID_REGEX.test(queryString)) {
                queryResults.push({ hint: "appid", searchTerm: queryString });
            }
            if (ACCOUNT_NUMBER_REGEX.test(queryString)) {
                queryResults.push({ hint: "accountnumber", searchTerm: queryString });
            }
            if (Patterns.EMAIL.test(queryString)) {
                queryResults.push({ hint: "email", searchTerm: queryString });
            }
            if (UUID_REGEX.test(queryString)) {
                queryResults.push({ hint: "companyid", searchTerm: queryString });
                queryResults.push({ hint: "playerid", searchTerm: queryString });
            }

            // If no hints were generated, then add a simple "name" hint.
            if (queryResults.length === 0) {
                queryResults.push({ hint: "name", searchTerm: queryString });
            }
        }

        console.debug("Generator Results", GENERATOR_NAME, queryResults);

        return queryResults;
    };
}

export default SmartGenerator;
