// Import libraries.
import React, { ReactNode } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import types.
import { MenuItemDefinition } from "./types";

// Import components.
import MenuItem from "@material-ui/core/MenuItem";
import CustomMenu from "./index";

// Import icons.
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

interface OWN_PROPS {
    id: string;
    label?: ReactNode | null;
    icon?: ReactNode | null;
    iconPlacement?: "left" | "right";
    disabled?: boolean;
    selected?: boolean;
    menuItems: MenuItemDefinition[];
    anchorOrigin?: any;
    transformOrigin?: any;
    minWidth?: number | string;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    anchorElement: any;
}

class SubMenuItem extends React.PureComponent<PROPS, STATE> {
    state: STATE = {
        anchorElement: null,
    };

    setAnchorElement = (anchorElement: any) => {
        this.setState({ anchorElement: anchorElement });
    };

    handleItemClick = (event: any) => {
        const { anchorElement } = this.state;

        if (!anchorElement) {
            this.setAnchorElement(event.currentTarget);
        }
    };

    handleSubMenuClose = () => {
        this.setState({ anchorElement: null });
    };

    render() {
        const { classes, id, label, icon, iconPlacement, disabled, selected, menuItems, anchorOrigin, transformOrigin, minWidth } = this.props;
        const { anchorElement } = this.state;

        return (
            <>
                <MenuItem
                    id={id}
                    key={id}
                    className={classnames({ [classes.root]: true, [classes.selected]: selected })}
                    style={{ flex: "1 1 auto", minWidth: minWidth ? (typeof minWidth === "number" ? minWidth + "px" : minWidth) : "unset", height: "auto" }}
                    disabled={disabled}
                    onClick={this.handleItemClick}
                >
                    {(iconPlacement == null || iconPlacement === "left") && <ArrowLeftIcon />}
                    {icon && typeof icon === "string" && icon.trim().length > 0 && (iconPlacement == null || iconPlacement === "left") && (
                        <img src={icon} alt="" width={32} height={32} style={{ marginRight: "5px", borderWidth: "1px", borderStyle: "solid", borderRadius: "0.25em" }}></img>
                    )}
                    {icon && typeof icon !== "string" && (iconPlacement == null || iconPlacement === "left") && <span style={{ marginRight: "5px" }}>{icon}</span>}

                    <span>{label}</span>

                    {icon && typeof icon !== "string" && iconPlacement === "right" && <span style={{ marginLeft: "5px" }}>{icon}</span>}
                    {icon && typeof icon === "string" && icon.trim().length > 0 && iconPlacement === "right" && (
                        <img src={icon} alt="" width={32} height={32} style={{ marginRight: "5px", borderWidth: "1px", borderStyle: "solid", borderRadius: "0.25em" }}></img>
                    )}
                    {iconPlacement === "right" && <ArrowRightIcon />}
                </MenuItem>

                <CustomMenu id={id} open={Boolean(anchorElement)} menuItems={menuItems} onClose={this.handleSubMenuClose} anchorElement={anchorElement} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} />
            </>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            alignItems: "center",
        },
        selected: {
            backgroundColor: "var(--popup-menu-active-background-, inherit",
            color: "var(--popup-menu-active-color, inherit)",
        },
    });

export default withStyles(styles)(SubMenuItem);
