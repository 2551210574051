// Import libraries.
import React, { useEffect, useRef } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import types.
import { ActionDefinition, ColumnDefinition, RowData } from "../../types";

// Import components.
import { Collapse } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";

// Import utilities.
import { DEFAULT_DETAILS_ROW_MAX_HEIGHT } from "../../utils";

interface OWN_PROPS {
    id: string;

    rowIdx: number;

    className?: string;

    minHeight?: string | number;
    height?: string | number;
    maxHeight?: string | number;

    contentWidth: number;
    scrollLeft: number;

    definitions: ColumnDefinition[];

    row: RowData;

    primaryKey: string;

    selectMode?: "single" | "multi"; // The selection mode behaviour ("single" or "multi" mode, applies to the current page. Changing the page clears the selections).

    actions?: ActionDefinition[] | ((row: RowData) => React.ReactNode);

    swapSelectAndActionColumns?: boolean; // Determines whether the "select" and "actions" columns are swapped (such that actions is on the left and the select is on the right).

    open: boolean;

    renderContent?: ((row: RowData) => React.ReactNode) | null;

    onCellHeightCalculated: ((rowIdx: number, colIdx: number, height: number) => void) | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const RowDetails = (props: PROPS) => {
    const { classes, id, rowIdx, className, minHeight, height, maxHeight, contentWidth, scrollLeft, definitions, row, primaryKey, selectMode, actions, swapSelectAndActionColumns, open, renderContent, onCellHeightCalculated } = props;

    const innerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (onCellHeightCalculated && innerRef.current) {
            onCellHeightCalculated(rowIdx, 0, open ? innerRef.current.offsetHeight : 0);
        }
    }, [rowIdx, height, open, onCellHeightCalculated]);

    return (
        <TableRow id={id} data-name={row[primaryKey]} data-index={rowIdx} className={classnames(classes.root, className)} hover tabIndex={-1}>
            {!swapSelectAndActionColumns && (
                <>
                    {selectMode && (
                        <TableCell id={id + "-select"} className={classnames(classes.stickyColumnLeft, "MuiTableCell-stickyHeader")} padding="checkbox">
                            <div className={classes.wrapper} style={{ height: open ? height : 0, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",

                                        overflow: "hidden",
                                    }}
                                ></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            {swapSelectAndActionColumns && (
                <>
                    {actions && (typeof actions === "function" || actions.length > 0) && (
                        <TableCell id={id + "-actions"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")}>
                            <div className={classes.wrapper} style={{ height: open ? height : 0, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        overflow: "hidden",
                                    }}
                                ></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            <TableCell
                id={id}
                data-name={id}
                data-index={0}
                style={{
                    padding: 0,

                    cursor: "default",
                }}
                colSpan={definitions.length}
            >
                <div className={classes.wrapper} style={{ minHeight: 0, height: open ? height : 0, maxHeight: height }}>
                    <Collapse in={open} timeout={0} style={{ position: "relative", zIndex: 3, width: "100%", backgroundColor: "inherit", color: "inherit", borderColor: "inherit" }}>
                        <div
                            ref={innerRef}
                            className={classes.floater}
                            style={{
                                position: "absolute",
                                left: scrollLeft,

                                width: contentWidth,

                                minHeight: minHeight != null ? minHeight : 0,
                                maxHeight: maxHeight != null ? maxHeight : DEFAULT_DETAILS_ROW_MAX_HEIGHT,

                                backgroundColor: "inherit",
                                color: "inherit",
                                borderColor: "inherit",
                            }}
                        >
                            {open && renderContent ? renderContent(row) : null}
                        </div>
                    </Collapse>
                </div>
            </TableCell>

            {!swapSelectAndActionColumns && (
                <>
                    {actions && (typeof actions === "function" || actions.length > 0) && (
                        <TableCell id={id + "-actions"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")}>
                            <div className={classes.wrapper} style={{ height: open ? height : 0, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        overflow: "hidden",
                                    }}
                                ></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}

            {swapSelectAndActionColumns && (
                <>
                    {selectMode && (
                        <TableCell id={id + "-select"} className={classnames(classes.stickyColumnRight, "MuiTableCell-stickyHeader")} padding="checkbox">
                            <div className={classes.wrapper} style={{ height: open ? height : 0, maxHeight: height }}>
                                <div
                                    style={{
                                        flex: "1 1 auto",
                                        alignSelf: "center",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",

                                        overflow: "hidden",
                                    }}
                                ></div>
                            </div>
                        </TableCell>
                    )}
                </>
            )}
        </TableRow>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",
        },
        wrapper: {
            position: "relative",

            minHeight: 0,

            width: "100%",

            display: "flex",
            alignItems: "flex-start",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        floater: {
            display: "flex",

            overflow: "hidden",
        },
        stickyColumnLeft: {
            position: "sticky",
            left: 0,

            padding: 0,

            cursor: "default",

            zIndex: 4,
        },
        stickyColumnRight: {
            position: "sticky",
            right: 0,

            padding: 0,

            cursor: "default",

            zIndex: 4,
        },
    });

export default withStyles(styles)(RowDetails);
