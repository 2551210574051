// Import libraries.
import React, { CSSProperties, ForwardedRef } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import components.
import { IconButton as MuiIconButton } from "@material-ui/core";

interface OWN_PROPS {
    id: string;
    color?: string;
    className?: string;
    style?: CSSProperties;
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    tabIndex?: number;

    [x: string]: any;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const IconButton = React.forwardRef((props: PROPS, ref: ForwardedRef<any>) => {
    const { classes, id, color, className, style, children, disabled, onClick, ...otherProps } = props;

    return (
        <MuiIconButton
            id={"icon-button-" + id}
            ref={ref}
            component={"label"}
            color={"inherit"}
            className={classnames({
                [classes.root]: true,
                [classes.customColor]: color != null,
                [className ? className : ""]: true,
            })}
            style={style}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (onClick && !disabled) {
                    event.preventDefault();
                    event.stopPropagation();

                    if ((event as any).code != null) {
                        const eventAsKeyboardEvent = event as unknown as React.KeyboardEvent;

                        const modifierPresent = eventAsKeyboardEvent.ctrlKey || eventAsKeyboardEvent.altKey || eventAsKeyboardEvent.shiftKey || eventAsKeyboardEvent.metaKey;

                        // Triggered as a result of keyboard event.
                        if (!modifierPresent && ["Space", "Enter"].includes(eventAsKeyboardEvent.code)) {
                            onClick(event);
                        }
                    }

                    if ((event as any).button != null) {
                        // Triggered as a result of mouse event.
                        onClick(event);
                    }
                }
            }}
            {...otherProps}
        >
            {children}
        </MuiIconButton>
    );
});

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            width: "1.5em",
            height: "1.5em",
            margin: "0.125em",
            padding: "0.125em",
            fontSize: "inherit",
            pointerEvents: "all",
            backgroundColor: "transparent",
            color: "inherit",
            borderColor: "transparent",
            "&.Mui-disabled": {
                pointerEvents: "all !important",
                color: "currentColor",
                opacity: 0.5,
                "&:hover": {
                    cursor: "not-allowed",
                },
            },
            "& > .MuiIconButton-label": {
                width: "100%",
                height: "100%",
                "& > *": {
                    width: "100%",
                    height: "100%",
                },
            },
            "&:hover > .MuiTouchRipple-root": {
                backgroundColor: "currentColor",
                opacity: 0.2,
            },
        },

        customColor: (props: OWN_PROPS) => ({
            color: props.color,
        }),
    });

export default withStyles(styles)(IconButton);
