export default interface PlayerSummary {
    playerId: string;

    isTester: boolean;

    abTestingId: number | null;

    name: string | null;
    email: string | null;

    pictureUrl: string | null;

    timezoneOffset: number | null;
    languageCode: string | null;
    countryCode: string | null;

    credentials: PlayerCredential[];

    platforms: string[];

    deviceTokens: DeviceToken[];

    vcPurchased: number | null;
    vcClaimed: number | null;
    vcSpent: number | null;
    refundCount: number | null;

    loginCount: number | null;

    previousLogin: Date | null;
    lastLogin: Date | null;

    createdAt: Date | null;
    updatedAt: Date | null;
}

export const processPlayerSummary = (data: any): PlayerSummary => {
    return {
        playerId: data.playerId || "",

        isTester: data.isTester === true,

        abTestingId: data.ab_testing_id != null ? Number.parseInt(data.ab_testing_id) : null,

        name: data.name || data.playerName || null,
        email: data.email || data.emailAddress || null,

        pictureUrl: data.pictureUrl || null,

        timezoneOffset: data.timeZoneOffset != null ? Number.parseFloat(data.timeZoneOffset) : null,
        languageCode: data.languageCode || null,
        countryCode: data.countryCode || null,

        credentials: Array.isArray(data.credentials) ? data.credentials.map(processPlayerCredential) : [],

        platforms: Array.isArray(data.platforms) ? (data.platforms as string[]) : [],

        deviceTokens: Array.isArray(data.deviceTokens) ? data.deviceTokens.map(processDeviceToken) : [],

        vcPurchased: data.vcPurchased != null ? Number.parseInt(data.vcPurchased) : null,
        vcClaimed: data.vcClaimed != null ? Number.parseInt(data.vcClaimed) : null,
        vcSpent: data.amountSpent != null ? Number.parseInt(data.amountSpent) : null,
        refundCount: data.refundCount != null ? Number.parseInt(data.refundCount) : null,

        loginCount: data.loginCount != null ? Number.parseInt(data.loginCount) : null,

        previousLogin: data.previousLogin != null ? new Date(data.previousLogin) : null,
        lastLogin: data.lastLogin != null ? new Date(data.lastLogin) : null,

        createdAt: data.createdAt != null ? new Date(data.createdAt) : null,
        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : null,
    };
};

export interface PlayerCredential {
    authenticationType: string;
    authSubType: string | null;
    deviceInfoList: {
        deviceType: string;
        deviceId: string;
    }[];
    externalId: string;
}

export const processPlayerCredential = (data: any): PlayerCredential => {
    return {
        authenticationType: data.authenticationType || "",
        authSubType: data.authSubType || null,
        deviceInfoList: Array.isArray(data.deviceInfo)
            ? (data.deviceInfo as any[]).map((item) => {
                  return {
                      deviceType: item.deviceType || "",
                      deviceId: item.deviceId || "",
                  };
              })
            : [],
        externalId: data.externalId,
    };
};

export interface DeviceToken {
    deviceType: string;
    deviceToken: string;
    lastRegisteredAt: Date;
}

export const processDeviceToken = (data: any): DeviceToken => {
    return {
        deviceType: data.deviceType || "",
        deviceToken: data.deviceToken || "",
        lastRegisteredAt: new Date(data.lastRegisteredAt),
    };
};
