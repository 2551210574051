// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, createStyles, withStyles, WithStyles, Tooltip } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import { MenuItemDefinition } from "components/common/menu/types";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import IconButton from "components/common/button/IconButton";
import CustomMenu from "components/common/menu";

// Import icons.
import ChevronUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InfoIcon from "@material-ui/icons/Info";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// Import redux actions.
import { SET_FAVORITES } from "store/actions/favorites";
import { SET_RECENTLY_VIEWED } from "store/actions/recentlyViewed";

// Import utilities
import PortalRouteUtils from "utils/PortalRoutes";
import FavoriteUtils from "utils/Favorites";
import RecentlyViewedUtils from "utils/RecentlyViewed";
import CookieConsentUtils, { CookieConsentLevel } from "utils/CookieConsent";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    currentCompanyAlias: TeamInfo | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    favorites: string[];
    recentlyViewed: string[];
}
interface DISPATCH_PROPS {
    setFavorites: (favorites: string[]) => void;
    setRecentlyViewed: (recentlyViewed: string[]) => void;
}
interface OWN_PROPS {
    definitions: PortalRouteDefinition[];
    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";
    infoOpen: boolean;
    onToggleBannerOpen: (mode: "team" | "app" | "player", open?: boolean, closeIfSame?: boolean) => void;
    onToggleInfoOpen: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        currentCompanyAlias: state.currentCompanyAlias,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        favorites: state.favorites,
        recentlyViewed: state.recentlyViewed,
    };
};

const mapDispatchToProps = (dispatch: Function): DISPATCH_PROPS => {
    return {
        setFavorites: (favorites: string[]) => dispatch(SET_FAVORITES(favorites)),
        setRecentlyViewed: (recentlyViewed: string[]) => dispatch(SET_RECENTLY_VIEWED(recentlyViewed)),
    };
};

interface STATE {
    anchorE1: any;
    collapseBreadcrumbs: boolean;
}

class BreadcrumbBar extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorE1: null,
        collapseBreadcrumbs: false,
    };

    private breadcrumbSpanRef = React.createRef<HTMLDivElement>();
    private breadcrumbsRef = React.createRef<HTMLElement>();

    componentDidMount() {
        this.updateRecentlyViewed();
        this.handleBreadcrumbsResize();
    }

    componentDidUpdate(prevProps: PROPS) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.updateRecentlyViewed();
            this.handleBreadcrumbsResize();
        }
        window.addEventListener("resize", this.handleBreadcrumbsResize);
    }

    handleBreadcrumbsResize = () => {
        if (this.breadcrumbSpanRef.current && this.breadcrumbsRef.current) {
            const breadcrumbSpanWidth = this.breadcrumbSpanRef.current.offsetWidth;
            const breadcrumbsWidth = this.breadcrumbsRef.current.offsetWidth;

            if (breadcrumbsWidth >= breadcrumbSpanWidth) this.setState({ collapseBreadcrumbs: true });
            else this.setState({ collapseBreadcrumbs: false });
        }
    };

    openBreadcrumbsMenu = (event: any) => this.setState({ anchorE1: event.currentTarget });

    closeBreadcrumbsMenu = () => this.setState({ anchorE1: null });

    updateRecentlyViewed = () => {
        const { location, definitions, currentUser, recentlyViewed } = this.props;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        if (currentUser) {
            let updatedRecentlyViewed = [...recentlyViewed];

            if (updatedRecentlyViewed.includes(currentPath)) {
                updatedRecentlyViewed.splice(updatedRecentlyViewed.indexOf(currentPath), 1);
                updatedRecentlyViewed.push(currentPath);
            } else {
                if (updatedRecentlyViewed.length === 10) {
                    updatedRecentlyViewed = updatedRecentlyViewed.slice(1);
                }

                updatedRecentlyViewed.push(currentPath);
            }

            RecentlyViewedUtils.setRecentlyViewed(currentUser.profileId, updatedRecentlyViewed);

            this.props.setRecentlyViewed(updatedRecentlyViewed);
        }
    };

    toggleFavorite = () => {
        const { location, definitions, currentUser, favorites } = this.props;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        if (currentUser) {
            const updatedFavorites = [...favorites];

            if (updatedFavorites.includes(currentPath)) {
                updatedFavorites.splice(updatedFavorites.indexOf(currentPath), 1);
            } else {
                updatedFavorites.push(currentPath);
            }

            FavoriteUtils.setFavorites(currentUser.profileId, updatedFavorites);

            this.props.setFavorites(updatedFavorites);
        }
    };

    render() {
        const { i18n, classes, location, session, currentCompanyAlias, availableCompanies, availableApps, definitions, bannerOpen, bannerMode, infoOpen, favorites } = this.props;
        const { anchorE1, collapseBreadcrumbs } = this.state;

        const isSuper = session.isSuper;

        const currentCompany = availableCompanies.find((item) => item.companyId === session.companyId);
        const currentApp = availableApps.find((item) => item.appId === session.appId);

        const currentPlayerId = session.playerId;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        const currentPathIsSuper = currentPath.startsWith("/super");
        const currentPathIsTeam = currentPath.startsWith("/team");
        const currentPathIsApp = currentPath.startsWith("/app");

        const currentPathIsUserBrowser = currentPath.startsWith("/app/user/user-browser");

        const currentRoutesInPath = PortalRouteUtils.getPortalRouteDefinitionsInPath(definitions, currentPath);

        const isFavorite = favorites.includes(currentPath);
        const favoriteEnabled = CookieConsentUtils.isLevelPermitted(CookieConsentLevel.FUNCTIONALITY);

        const breadcrumbItems: MenuItemDefinition[] = [
            ...(isSuper
                ? [
                      {
                          id: "super",
                          label: <Trans>Super</Trans>,
                          onClick: () => {
                              this.props.history.push("/super/dashboard");
                              this.closeBreadcrumbsMenu();
                          },
                      },
                  ]
                : []),

            ...(isSuper && !currentPathIsSuper && currentCompanyAlias
                ? [
                      {
                          id: "teams",
                          label: <Trans>Teams</Trans>,
                          onClick: () => {
                              this.props.history.push("/super/manage/teams");
                              this.closeBreadcrumbsMenu();
                          },
                      },
                      {
                          id: "team-banner",
                          label: currentCompanyAlias.companyName,
                          onClick: () => {
                              this.props.onToggleBannerOpen("team", true, true);
                              this.closeBreadcrumbsMenu();
                          },
                      },
                  ]
                : []),

            ...(isSuper && currentPathIsApp && currentApp
                ? [
                      {
                          id: "apps",
                          label: <Trans>Apps</Trans>,
                          onClick: () => {
                              this.props.history.push("/team/manage/apps");
                              this.closeBreadcrumbsMenu();
                          },
                      },
                      {
                          id: "app-banner",
                          label: currentApp.appName,
                          onClick: () => {
                              this.props.onToggleBannerOpen("app", true, true);
                              this.closeBreadcrumbsMenu();
                          },
                      },
                  ]
                : []),

            ...(!isSuper && currentCompany
                ? [
                      {
                          id: "team-banner",
                          label: currentCompany.companyName,
                          onClick: () => {
                              this.props.onToggleBannerOpen("team", true, true);
                              this.closeBreadcrumbsMenu();
                          },
                      },
                  ]
                : []),

            ...(!isSuper && currentPathIsApp && currentApp
                ? [
                      {
                          id: "apps",
                          label: <Trans>Apps</Trans>,
                          onClick: () => {
                              this.props.history.push("/team/manage/apps");
                              this.closeBreadcrumbsMenu();
                          },
                      },
                      {
                          id: "app-banner",
                          label: currentApp.appName,
                          onClick: () => {
                              this.props.onToggleBannerOpen("app", true, true);
                              this.closeBreadcrumbsMenu();
                          },
                      },
                  ]
                : []),
        ];

        if (breadcrumbItems.length === 0) {
            console.error("Unable to determine root breadcrumbs", {
                isSuper: isSuper,
                currentPath: currentPath,
                currentPathIsSuper: currentPathIsSuper,
                currentPathIsTeam: currentPathIsTeam,
                currentPathIsApp: currentPathIsApp,
                currentRoutesInPath: currentRoutesInPath,
                currentCompany: currentCompany,
                currentApp: currentApp,
                availableCompanies: availableCompanies,
                availableApps: availableApps,
            });
        }

        currentRoutesInPath.forEach((item: PortalRouteDefinition, idx: number) => {
            if (idx !== 0) {
                const routeIdx = currentRoutesInPath.indexOf(item);
                const ancestorRoutes = currentRoutesInPath.slice(0, routeIdx);

                const path = "/" + ancestorRoutes.map((item) => PortalRouteUtils.sanitizeRoutePath(item.path)).join("/") + "/" + PortalRouteUtils.sanitizeRoutePath(item.path);

                const pathIsUser = path === "/app/user";

                breadcrumbItems.push({
                    id: `${idx}`,
                    label: item.label(i18n),
                    onClick: () => {
                        this.props.history.push(path);
                        this.closeBreadcrumbsMenu();
                    },
                });

                if (currentPlayerId && pathIsUser && !currentPathIsUserBrowser)
                    breadcrumbItems.push({
                        id: `${idx}-profile-id`,
                        label: currentPlayerId,
                        onClick: () => {
                            this.props.onToggleBannerOpen("player", true, true);
                            this.closeBreadcrumbsMenu();
                        },
                    });
            } else return null;
        });

        return (
            <div id={"breadcrumb-bar"} className={classes.root}>
                <span ref={this.breadcrumbSpanRef} style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <Breadcrumbs
                        ref={this.breadcrumbsRef}
                        aria-label="breadcrumb"
                        style={{ flex: "1 0 auto", maxHeight: collapseBreadcrumbs ? 0 : undefined, width: "fit-content", alignItems: "center" }}
                        className={classes.breadcrumbs}
                        maxItems={10}
                        separator={">"}
                    >
                        {breadcrumbItems.map((item: MenuItemDefinition, idx: number) => {
                            if (idx !== breadcrumbItems.length - 1) {
                                return (
                                    <Link
                                        key={idx}
                                        rel="noreferrer"
                                        onClick={() => {
                                            if (item.onClick) item.onClick();
                                        }}
                                    >
                                        {item.label}
                                    </Link>
                                );
                            } else return <Typography key={idx}>{item.label}</Typography>;
                        })}
                        {favoriteEnabled && (
                            <Tooltip className={classes.favoriteStartIcon} arrow title={isFavorite ? <Trans>Remove from favorites</Trans> : <Trans>Add to favorites</Trans>}>
                                <IconButton id="toggle-favorite" onClick={this.toggleFavorite} style={{ marginLeft: "0.625em" }}>
                                    {isFavorite ? <StarIcon /> : <StarOutlineIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Breadcrumbs>

                    <Breadcrumbs aria-label="collapsed-breadcrumb" style={{ maxHeight: !collapseBreadcrumbs ? 0 : undefined }} className={classes.breadcrumbs} maxItems={10} separator={">"}>
                        <Link
                            rel="noreferrer"
                            onClick={() => {
                                if (breadcrumbItems[0].onClick) breadcrumbItems[0].onClick();
                            }}
                        >
                            {breadcrumbItems[0].label}
                        </Link>
                        {breadcrumbItems.length > 2 && (
                            <IconButton id={"more-actions"} onClick={(e) => this.openBreadcrumbsMenu(e)}>
                                <MoreHorizIcon />
                            </IconButton>
                        )}
                        <Typography>{breadcrumbItems[breadcrumbItems.length - 1].label}</Typography>;
                        {favoriteEnabled && (
                            <Tooltip className={classes.favoriteStartIcon} arrow title={isFavorite ? <Trans>Remove from favorites</Trans> : <Trans>Add to favorites</Trans>}>
                                <IconButton id="toggle-favorite" onClick={this.toggleFavorite} style={{ marginLeft: "0.625em" }}>
                                    {isFavorite ? <StarIcon /> : <StarOutlineIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Breadcrumbs>
                </span>

                {collapseBreadcrumbs && (
                    <CustomMenu
                        anchorElement={anchorE1}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(anchorE1)}
                        onClose={this.closeBreadcrumbsMenu}
                        menuItems={breadcrumbItems.filter((_item: MenuItemDefinition, idx: number) => idx !== 0 && idx !== breadcrumbItems.length - 1)}
                    />
                )}

                <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                    {favoriteEnabled && (
                        <Tooltip className={classes.favoriteEndIcon} arrow title={isFavorite ? <Trans>Remove from favorites</Trans> : <Trans>Add to favorites</Trans>}>
                            <IconButton id="toggle-favorite" onClick={this.toggleFavorite}>
                                {isFavorite ? <StarIcon /> : <StarOutlineIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip arrow title={infoOpen ? <Trans>Hide screen information</Trans> : <Trans>Show screen information</Trans>}>
                        <IconButton id="toggle-screen-info" onClick={this.props.onToggleInfoOpen}>
                            {infoOpen ? <InfoIcon /> : <InfoOutlineIcon />}
                        </IconButton>
                    </Tooltip>

                    {!location.pathname.startsWith("/super") && location.pathname !== "/team/setup/team-info" && (
                        <Tooltip arrow title={bannerOpen ? <Trans>Hide team/app/profile banner</Trans> : <Trans>Show team/app/profile banner</Trans>}>
                            <IconButton id={"toggle-banner"} onClick={() => this.props.onToggleBannerOpen(bannerMode, !bannerOpen)}>
                                {bannerOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </span>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: "2.125rem",
            width: "100%",
            padding: "0.2125rem 0.625rem",
            overflow: "hidden",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",
            borderTopStyle: "solid",
            borderTopWidth: "0.0625rem",
            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625rem",
        },
        breadcrumbs: {
            color: "inherit",
            overflow: "hidden",
            "& .MuiBreadcrumbs-ol": {
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
                "& .MuiBreadcrumbs-separator": {
                    margin: "0 .3125em",
                },
                "& .MuiBreadcrumbs-li *": {
                    color: "inherit",
                },
                "& > *:nth-last-child(2)": {
                    display: "none",
                },
            },
        },

        favoriteStartIcon: {
            "@media (min-width: 420px)": {
                display: "flex",
            },
            "@media (max-width: 420px)": {
                display: "none",
            },
        },
        favoriteEndIcon: {
            "@media (min-width: 420px)": {
                display: "none",
            },
            "@media (max-width: 420px)": {
                display: "flex",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withStyles(styles)(BreadcrumbBar))));
