import { ApplicationInformationAction } from "store/actions/app";

import ApplicationInformation from "types/common/ApplicationInformation";
import AccessMode from "types/enums/AccessMode";

const initialState: ApplicationInformation = {
    name: null,
    prefix: null,
    version: null,
    build: null,
    disabled: false,
    status: null,
    platformVersion: null,
    loadingInitialState: false,
    loadingLoginState: false,
    loadingBasicState: false,
    loginConfiguration: {
        emailPassword: false,
        emailPasswordAdminAllowed: false,
        availableExternalAuthenticationProviders: [],
    },
    twoFactorConfiguration: {
        enabled: false,
        authyAvailable: false,
        verifyAvailable: false,
    },
    intercomConfiguration: {
        enabled: false,
        appId: null,
    },
    billingConfiguration: {
        enabled: false,
    },
    serverStatusConfiguration: {
        enabled: false,
    },
    serverStatus: null,
    accessMode: AccessMode.DISABLED,

    systemMessage: {
        message: {
            en: null,
            fr: null,
        },
        urgencyLevel: 0,
        isDismissed: false,
    },

    maxFileUploadSizeInBytes: -1,
};

const reducer = (state: ApplicationInformation = initialState, action: ApplicationInformationAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_APPLICATION_INFORMATION":
            newState = Object.assign({}, action.payload);

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
