import { combineReducers } from "redux";

import availableThemesReducer from "./availableThemes";
import activeThemeReducer from "./activeTheme";

const reducer = combineReducers({
    availableThemes: availableThemesReducer,
    activeTheme: activeThemeReducer,
});

export default reducer;
