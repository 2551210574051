// Import libraries.
import React, { ReactNode, FunctionComponent, JSXElementConstructor, ReactElement } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import components.
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import Typography from "@material-ui/core/Typography";

export interface TabConfig {
    id?: string | null;
    icon?: string | ReactElement<any, string | JSXElementConstructor<any>>;
    label: ReactNode;
    component: ReactNode;
    disabled?: boolean;
}

// Define the properties accepted by this component.
interface OWN_PROPS {
    className?: string | null;
    style?: React.CSSProperties;
    orientation?: "vertical" | "horizontal";
    wrapped?: boolean;
    scrollButtons?: "auto" | "desktop" | "on" | "off";
    configs: TabConfig[];
    value?: number | null;
    onTabChanged?: (index: number, id?: string | null) => void;
}
interface PROPS extends OWN_PROPS {}

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

// Styling for this component.
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "1 1 auto",
        display: "flex",
        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",
        overflow: "hidden",
    },
    tabsVertical: {
        flex: "0 0 auto",
        borderRightStyle: "solid",
        borderRightWidth: "1px",
    },
    tabsHorizontal: {
        flex: "0 0 auto",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
    },
    vertical: {
        flexDirection: "row",
    },
    horizontal: {
        flexDirection: "column",
    },
}));

const CustomTabs: FunctionComponent<PROPS> = (props) => {
    const { className, style, orientation, wrapped, scrollButtons, configs, onTabChanged } = props;

    // We are a CONTROLLED component when the parent is supplying the "value" property (they SHOULD also be supplying the "onTabChanged" property as well).
    // Otherwise we are an UNCONTROLLED component (meaning we track the active tab index via intenral state).
    const controlledValue = props.value;
    const controlled = controlledValue != null;

    const classes = useStyles();
    const [value, setValue] = React.useState(controlled ? controlledValue : 0);

    React.useEffect(() => {
        if (controlledValue != null) {
            setValue(controlledValue);
        }
    }, [controlledValue]);

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        if (controlled) {
            // If we are CONTROLLED, then we just emit the new active tab index to the parent (who will control the active tab index via the "value" property).
            if (onTabChanged) {
                const tabConfig = configs[newValue];
                const tabId = tabConfig ? tabConfig.id : null;

                onTabChanged(newValue, tabId);
            }
        } else {
            // Otherwise we are UNCONTROLLED, then we track the state of the active tab index ourselves (via internal state).
            setValue(newValue);
        }
    };

    const indexValid = value != null && value < configs.length;

    return (
        <div id="tabs-wrapper" className={classnames({ [classes.root]: true, [classes.vertical]: orientation === "vertical", [classes.horizontal]: orientation !== "vertical", [className ? className : ""]: true })} style={style}>
            <Tabs
                orientation={orientation ? orientation : "vertical"}
                scrollButtons={scrollButtons}
                variant="scrollable"
                value={indexValid ? value : false}
                onChange={handleChange}
                aria-label="tabs example"
                className={classnames({ [classes.tabsVertical]: orientation === "vertical", [classes.tabsHorizontal]: orientation !== "vertical" })}
            >
                {configs.map((config: TabConfig, idx: number) => (
                    <Tab key={idx} label={config.label} icon={config.icon} wrapped={wrapped === true} disabled={config.disabled} {...a11yProps(idx)} />
                ))}
            </Tabs>

            {configs.map((config: TabConfig, idx: number) => {
                return (
                    <TabPanel key={idx} value={value} index={idx}>
                        {config.component && config.component}
                        {!config.component && (
                            <Typography style={{ margin: "auto" }}>
                                <Trans>Missing Tab Content</Trans>
                            </Typography>
                        )}
                    </TabPanel>
                );
            })}
        </div>
    );
};

export default CustomTabs;
