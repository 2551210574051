// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles, Typography } from "@material-ui/core";
import classnames from "classnames";

interface OWN_PROPS {
    label: React.ReactNode;
    bordered?: boolean;
    textAlign?: "center" | "left" | "right";
    children?: React.ReactNode;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class Title extends React.PureComponent<PROPS> {
    render() {
        const { label, classes, children, bordered, textAlign } = this.props;

        if (!label) return null;

        return (
            <div className={classnames({ [classes.root]: true, [classes.bordered]: bordered })}>
                <Typography style={{ flex: "1 1 auto", textAlign: textAlign, fontWeight: "bold" }} noWrap>
                    {label}
                </Typography>

                {children && <div style={{ flex: "0 0 auto", display: "flex" }}>{children}</div>}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            minHeight: "2em",

            fontSize: "1.2em",
            fontWeight: "bold",

            paddingLeft: "0.3125em",
            paddingRight: "0.3125em",

            overflow: "hidden",
            borderColor: "inherit",
        },
        bordered: {
            borderBottomWidth: "0.0625em",
            borderBottomStyle: "solid",
        },
    });

export default withStyles(styles)(Title);
