// Import libraries.
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { en, fr } from "make-plural/plurals";
import moment from "moment-timezone";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";

// Import the default localization catalog.
import defaultCatalog from "../assets/locales/en/messages";

// Initial (i.e. default) language.
const DEFAULT_LANGUAGE: SupportedLanguage = SupportedLanguage.ENGLISH;

/**
 * Localization utility functions.
 */
export default abstract class LocalizationUtils {
    public static initializeLocalization(i18n: I18n) {
        let plurals = null;

        switch (DEFAULT_LANGUAGE) {
            case SupportedLanguage.ENGLISH:
                plurals = en;

                break;
            case SupportedLanguage.FRENCH:
                plurals = fr;

                break;
            default:
            // Do nothing.
        }

        // Initially we always set the active localization language to english.
        // This is prior to us actually having any idea about what the user's preferred language is.

        i18n.loadLocaleData(DEFAULT_LANGUAGE, { plurals: plurals || undefined });
        i18n.load(DEFAULT_LANGUAGE, defaultCatalog.messages);
        i18n.activate(DEFAULT_LANGUAGE);
    }

    public static async setActiveLanguage(i18n: I18n, language: SupportedLanguage | null) {
        if (language != null) {
            console.log("Setting Active Language", LocalizationUtils.formatLanguage(i18n, language));

            let plurals = null;
            let catalog: { messages: any } | null = null;

            // Load the appropriate Plural configuration for the language.
            switch (language) {
                case SupportedLanguage.ENGLISH:
                    plurals = en;

                    break;
                case SupportedLanguage.FRENCH:
                    plurals = fr;

                    break;
                default:
                // Do nothing.
            }

            // Load the appropriate catalog for the language.
            catalog = await import(
                /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
                `../assets/locales/${language}/messages`
            );

            // If we have a valid catalog, proceed with updating the i18n library.
            if (catalog != null) {
                // Load the plurals configuration for the language (if applicable).
                if (plurals != null) {
                    i18n.loadLocaleData(language, { plurals });
                }

                // Load the catalog for the language.
                i18n.load(language, catalog.messages);

                // Set the active language.
                i18n.activate(language);

                // Set the active language for moment (used when formatting dates/timestamps).
                moment.locale(language);
            } else {
                console.warn("Attempted to set unsupported language", language);
            }
        }
    }

    public static formatLanguage(i18n: I18n, language: SupportedLanguage | null) {
        if (language != null) {
            switch (language) {
                case SupportedLanguage.ENGLISH:
                    return i18n ? i18n._(t`English`) : "English";
                case SupportedLanguage.FRENCH:
                    return i18n ? i18n._(t`French`) : "French";
                default:
                    return null;
            }
        }

        return null;
    }
}
