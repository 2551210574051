// Import libraries.
import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import components.
import { Chip, Divider, Link, Typography } from "@material-ui/core";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";
import InfoBox from "components/common/screen/InfoBox";

// Import utilities.
import * as Patterns from "utils/Patterns";
import Http from "utils/networking/Http";

interface OWN_PROPS {
    onComplete: (email: string | null) => void;
    onCancel: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    email: string;

    recoveryStatus: "pending" | "complete" | "error" | null;
    error: string | null;
}

class Recover extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        email: "",

        recoveryStatus: null,
        error: null,
    };
    private confirmButtonRef = React.createRef<HTMLElement>();

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "email":
                this.setState({ email: value.trim() });
                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["email"].includes(name) && key === "Enter") {
            this.confirmButtonRef.current?.focus();

            this.onRecover();
        }
    };

    onRecover = () => {
        const { bcFormValidator, i18n } = this.props;
        const { email } = this.state;

        if (!bcFormValidator.validate()) return;

        this.setState({ recoveryStatus: "pending", error: null }, async () => {
            const recoverResponse = await Http.POST("passwordRecovery", { email: email }, undefined, Http.JSON_HEADERS);

            if (Http.isStatusOk(recoverResponse)) {
                if (recoverResponse.data.error) {
                    this.setState({ recoveryStatus: "error", error: recoverResponse.data.error });
                } else {
                    this.setState({ recoveryStatus: "complete", error: null });
                }
            } else {
                const error = Http.buildError(recoverResponse);

                this.setState({ recoveryStatus: "error", error: error.errorMessage || i18n._(t`Could not recover account.`) });
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes } = this.props;
        const { email, recoveryStatus, error } = this.state;

        return (
            <div id={"recover"} className={classes.root}>
                {recoveryStatus !== "complete" && (
                    <>
                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Recover Account</Trans>
                        </Typography>

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"text"}
                            name={"email"}
                            value={email}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={recoveryStatus != null && ["pending", "complete"].includes(recoveryStatus)}
                            autoFocus={true}
                            options={
                                {
                                    placeholder: i18n._(t`Email Address`),
                                    pattern: Patterns.EMAIL,
                                    patternMessage: <Trans>Must be a valid email address</Trans>,
                                    autoComplete: "email",
                                    showPasswordManagerExtensions: true,
                                } as TextFieldOptions
                            }
                        />

                        <Button ref={this.confirmButtonRef} className={classes.button} id={"recover"} type={"primary"} onClick={this.onRecover} disabled={recoveryStatus != null && ["pending", "complete"].includes(recoveryStatus)}>
                            {recoveryStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Send Email</Trans>}
                        </Button>

                        {recoveryStatus === "error" && <Chip className={classes.chip} label={error} />}

                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "0.3125rem", marginRight: "0.3125rem" }} />

                        <span className={classes.login}>
                            <Typography>
                                <Trans>Remembered your password?</Trans>
                            </Typography>

                            <Link data-id={"login"} onClick={this.props.onCancel} noWrap>
                                <Trans>Log in</Trans>
                            </Link>
                        </span>
                    </>
                )}

                {recoveryStatus === "complete" && (
                    <>
                        <InfoBox
                            style={{ marginBottom: "1rem" }}
                            content={
                                <span style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                                    <Typography>
                                        <Trans>You should receive an email containing instructions on how to complete the account recovery process at the following address in a few minutes.</Trans>
                                    </Typography>

                                    <Link data-id={"recover-account"} data-email={email} style={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center" }} href={"mailto:" + email}>
                                        {email}
                                    </Link>

                                    <Typography>
                                        <Trans>Please check your email and click the included link to set a new password.</Trans>
                                    </Typography>
                                </span>
                            }
                        />

                        <Button className={classes.button} id={"finish"} type={"primary"} onClick={() => this.props.onComplete(email)}>
                            <Trans>Log in</Trans>
                        </Button>
                    </>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        login: {
            flex: "0 0 auto",
            alignSelf: "center",

            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",

            margin: "0.3125rem",

            fontSize: "12px",

            "& .MuiTypography-root": {
                fontSize: "12px",
            },

            "& > *:last-child": {
                marginLeft: "0.3125rem",
            },
        },
    });

export default withFormValidator()(withI18n()(withStyles(styles)(Recover)));
