import { DirectS2SAction } from "store/actions/directS2S";

import DirectS2S, { DirectS2SLog, DirectS2SSelection, DirectS2SSession } from "types/common/DirectS2S";

import CloneUtils from "utils/Clone";

const initialState: DirectS2S = {
    sessions: [],

    logs: [],

    isBusy: false,

    isRealDispatcher: false,

    isSessionBased: true,

    isAsynchronous: true,

    selections: [],
};

const reducer = (state: DirectS2S = initialState, action: DirectS2SAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_DIRECT_S2S_SESSION":
            const sessionToSet = newState.sessions.find((item) => item.appId === (action.payload as DirectS2SSession).appId) || null;

            if (sessionToSet) {
                const sessionToSetIdx = newState.sessions.indexOf(sessionToSet);

                newState = CloneUtils.clone(state) as DirectS2S;

                newState.sessions[sessionToSetIdx] = CloneUtils.clone(action.payload);
            } else {
                newState = CloneUtils.clone(state) as DirectS2S;

                newState.sessions.push(CloneUtils.clone(action.payload));
            }

            break;
        case "CLEAR_DIRECT_S2S_SESSION":
            const sessionToClear = newState.sessions.find((item) => item.appId === (action.payload as DirectS2SSession).appId) || null;

            if (sessionToClear) {
                const sessionToClearIdx = newState.sessions.indexOf(sessionToClear);

                newState = CloneUtils.clone(state) as DirectS2S;

                newState.sessions.splice(sessionToClearIdx, 1);
            }

            break;
        case "APPEND_DIRECT_S2S_LOG":
            if (action.payload) {
                newState = CloneUtils.clone(state) as DirectS2S;

                newState.logs.push(CloneUtils.clone(action.payload));
            }

            break;
        case "UPDATE_DIRECT_S2S_LOG":
            if (action.payload) {
                const log = action.payload as DirectS2SLog;

                const target = newState.logs.find((item) => item.appId === log.appId && item.timestamp.getTime() === log.timestamp.getTime()) || null;
                const idx = target ? newState.logs.indexOf(target) : -1;

                if (idx > -1) {
                    newState = CloneUtils.clone(state) as DirectS2S;

                    newState.logs[idx] = log;
                }
            }

            break;
        case "CLEAR_DIRECT_S2S_LOGS":
            newState = CloneUtils.clone(state) as DirectS2S;

            newState.logs = newState.logs.filter((item) => !item.response);

            break;
        case "SET_DIRECT_S2S_BUSY":
            newState = CloneUtils.clone(state) as DirectS2S;

            newState.isBusy = action.payload as boolean;

            break;
        case "SET_DIRECT_S2S_IS_REAL_DISPATCHER":
            newState = CloneUtils.clone(state) as DirectS2S;

            newState.isRealDispatcher = action.payload as boolean;

            break;
        case "SET_DIRECT_S2S_IS_SESSION_BASED":
            newState = CloneUtils.clone(state) as DirectS2S;

            newState.isSessionBased = action.payload as boolean;

            break;
        case "SET_DIRECT_S2S_IS_ASYNCHRONOUS":
            newState = CloneUtils.clone(state) as DirectS2S;

            newState.isAsynchronous = action.payload as boolean;

            break;
        case "SET_DIRECT_S2S_SELECTION":
            if (action.payload) {
                const newSelection = action.payload as DirectS2SSelection;

                const existingSelection = newState.selections.find((item) => item.appId === newSelection.appId) || null;

                if (existingSelection) {
                    const idx = newState.selections.indexOf(existingSelection);

                    newState = CloneUtils.clone(state) as DirectS2S;

                    newState.selections[idx] = CloneUtils.clone(newSelection) as DirectS2SSelection;
                } else {
                    newState = CloneUtils.clone(state) as DirectS2S;

                    newState.selections.push(CloneUtils.clone(newSelection) as DirectS2SSelection);
                }
            }

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
