// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import moment from "moment-timezone";
import countryList from "react-select-country-list";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import { SelectFieldOptions } from "components/common/form/fields/SelectField";
import { ComboboxFieldOptions } from "components/common/form/fields/ComboboxField";
import { LabelledOption } from "types/common/LabelledOption";

// Import components.
import FieldWrapper from "components/common/form/FieldWrapper";

// Import utilities.
import LocalizationUtils from "utils/Localization";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from "components/common/table2/utils";

interface OWN_PROPS {
    language: SupportedLanguage | null;
    country: string | null;
    timezone: string | null;
    themeMode: string | null;
    pageSize: number | null;
    isCurrentSessionUser: boolean;
    onChange: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

class Settings extends React.PureComponent<PROPS> {
    generateLanguageOptions() {
        const { i18n } = this.props;

        const results: LabelledOption[] = [];

        Object.keys(SupportedLanguage).forEach((item) => {
            const language = SupportedLanguage[item as keyof typeof SupportedLanguage];

            results.push({ value: language, label: LocalizationUtils.formatLanguage(i18n, language) });
        });

        return results;
    }

    generateCountryOptions() {
        const results: LabelledOption[] = [];

        countryList()
            .getData()
            .forEach((item) => {
                results.push({ value: item.value, label: item.label });
            });

        return results;
    }

    generateTimezoneOptions() {
        const results: LabelledOption[] = [];

        moment.tz.names().forEach((item) => {
            results.push({ value: item, label: item });
        });

        return results;
    }

    generateThemeModeOptions() {
        const results: LabelledOption[] = [];

        results.push({ value: "light", label: <Trans>Light</Trans> });
        results.push({ value: "dark", label: <Trans>Dark</Trans> });

        return results;
    }

    generatePageSizeOptions() {
        const results: LabelledOption[] = [];

        DEFAULT_PAGE_SIZE_OPTIONS.forEach((pageSize) => {
            results.push({ value: pageSize, label: "" + pageSize });
        });

        return results;
    }

    render() {
        const { i18n, classes, language, country, timezone, themeMode, pageSize, isCurrentSessionUser } = this.props;

        return (
            <div className={classes.root}>
                <FieldWrapper
                    type={"select"}
                    name={"language"}
                    value={language}
                    label={<Trans>Language</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.props.onChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            options: this.generateLanguageOptions(),
                            placeholder: i18n._(t`Users Preferred Language...`),
                        } as SelectFieldOptions
                    }
                />

                <FieldWrapper
                    type={"combobox"}
                    name={"country"}
                    value={country}
                    label={<Trans>Country</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.props.onChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            options: this.generateCountryOptions(),
                            placeholder: i18n._(t`User's Preferred Country Code...`),
                            virtualized: true,
                        } as ComboboxFieldOptions
                    }
                />

                <FieldWrapper
                    type={"combobox"}
                    name={"timezone"}
                    value={timezone}
                    label={<Trans>Timezone / Region</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.props.onChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            options: this.generateTimezoneOptions(),
                            placeholder: i18n._(t`Users Preferred Timezone / Region...`),
                            virtualized: true,
                        } as ComboboxFieldOptions
                    }
                />

                <FieldWrapper
                    type={"select"}
                    name={"themeMode"}
                    value={themeMode}
                    label={<Trans>Theme Mode</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.props.onChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            options: this.generateThemeModeOptions(),
                            placeholder: i18n._(t`Users Preferred Theme Mode...`),
                        } as SelectFieldOptions
                    }
                />

                <FieldWrapper
                    type={"select"}
                    name={"pageSize"}
                    value={pageSize || DEFAULT_PAGE_SIZE}
                    label={<Trans>Page Size</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.props.onChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            options: this.generatePageSizeOptions(),
                            placeholder: i18n._(t`Users Preferred Page Size...`),
                        } as SelectFieldOptions
                    }
                />
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
    });

export default withI18n()(withStyles(styles)(Settings));
