// Import libraries.
import { select, put, all, takeLatest, call } from "redux-saga/effects";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";

// Import types.
import { CookieConsentLevel } from "utils/CookieConsent";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session, { sessionFromString } from "types/common/Session";
import AppInfo, { processAppInfo } from "types/models/AppInfo";
import { processPlayerSummary } from "types/models/PlayerSummaryInfo";

// Import redux actions.
import { SET_APPLICATION_INFORMATION } from "store/actions/app";
import { SET_SESSION } from "store/actions/session";
import { SET_AVAILABLE_APPS } from "store/actions/availableApps";
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";
import { CLOUD_CODE_EDITOR_RESET } from "store/actions/cloudCodeEditor";

// Import redux sagas.
import { saveSession } from "./sessionSagas";
import { populateAvailablePrivileges } from "./privilegeSagas";
import { populateScreenSettings } from "./screenSettingSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import LocalStorageUtils from "utils/LocalStorage";

interface PopulateAvailableApps {
    type: "app.populateAvailableApps";
}

interface SetAppId {
    type: "app.setAppId";
    payload: {
        appId: string | null;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

interface SetPlayerId {
    type: "app.setPlayerId";
    payload: {
        playerId: string | null;
        path?: string;
        state?: any;
        history?: {
            location: Location;
            push: (params: { pathname: string; state?: any } | string) => void;
            replace: (params: { pathname: string; state?: any } | string) => void;
        };
    };
}

interface ToggleLiveLock {
    type: "app.toggleLiveLock";
    payload: {
        appName?: string | null;
        isLiveLocked?: boolean | null;
    };
}

interface DismissSystemMessage {
    type: "app.dismissSystemMessage";
}

// Attempts to populate the available apps that are accessible by the current user.
export function* populateAvailableApps(_action?: PopulateAvailableApps) {
    // Get the current session.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    try {
        console.log("Populating Available Apps...");

        if ((session.isSuper && session.companyIdAlias) || (!session.isSuper && session.companyId)) {
            const response: HttpResponse = yield Http.GET("admin/serveradmin/team-apps-read");
            if (Http.isStatusOk(response) && Array.isArray(response.data)) {
                const newAvailableApps = response.data.map((item: any) => processAppInfo(item));

                // Sort newly available apps by name.
                newAvailableApps.sort((a, b) => a.appName.localeCompare(b.appName));

                yield put(SET_AVAILABLE_APPS(newAvailableApps));

                // Get the original and new target apps (used to check if the app has changed it's live state).
                const originalApp = availableApps.find((item) => item.appId === session.appId) || null;
                const newApp = newAvailableApps.find((item) => item.appId === session.appId) || null;

                // Verify the currently selected app and "Live Lock" state.
                if (originalApp && newApp && originalApp.isLive !== newApp.isLive) {
                    yield call(toggleLiveLock, { type: "app.toggleLiveLock", payload: { appName: newApp.appName, isLiveLocked: true } });
                } else {
                    if (!originalApp && newApp) {
                        // Get the original session from the local storage (if the same app is selected then we take the live lock state that was previously saved).
                        const oldSession = sessionFromString(LocalStorageUtils.getItem(LocalStorageUtils.PORTAL_SESSION));

                        if (oldSession.appId === newApp.appId) {
                            yield call(toggleLiveLock, { type: "app.toggleLiveLock", payload: { appName: newApp.appName, isLiveLocked: oldSession.isLiveLocked === true } });
                        } else {
                            yield call(toggleLiveLock, { type: "app.toggleLiveLock", payload: { appName: newApp.appName, isLiveLocked: true } });
                        }
                    } else {
                        if (originalApp && !newApp) {
                            session.appId = null;
                            session.playerId = null;
                            session.isLiveLocked = false;

                            // Update the session in the redux store.
                            yield put(SET_SESSION(CloneUtils.clone(session)));
                            yield call(saveSession);
                        }
                    }
                }

                return true;
            }
        }
    } catch (error: any) {
        console.error("populateAvailableApps - ERROR", error);
    }

    yield put(SET_AVAILABLE_APPS([]));

    return false;
}

// Attempts to set the current user's app id.
export function* setAppId(action: SetAppId) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current user's profile id.
    const getCurrentUserProfileId = (state: PortalState): string | null => (state.currentUser ? state.currentUser.profileId : null);
    const currentUserProfileId: string | null = yield select(getCurrentUserProfileId);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === action.payload.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    try {
        console.log("Selecting App", action.payload.appId);

        if (!applicationInformation.loadingBasicState) {
            // Indicate that we are now loading basic state.
            applicationInformation.loadingBasicState = true;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Attempt to set the current app id on the server.
        const response: HttpResponse = yield Http.POST("admin/development/setgame", { gameId: action.payload.appId }, null, Http.JSON_HEADERS);
        if (Http.isStatusOk(response)) {
            // Set various fields on the current session.
            session.appId = targetApp.appId;
            session.playerId = null;
            session.isLiveLocked = targetApp.isLive === true;

            // Update the session in the redux store.
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);

            // Reset the Cloud Code Editor state.
            yield put(CLOUD_CODE_EDITOR_RESET());

            // Load the available privileges and screen settings.
            yield all([call(populateAvailablePrivileges), call(populateScreenSettings)]);

            // Update the "lastgame_<profileId>" in local storage.
            if (session.isSuper) {
                LocalStorageUtils.setItem(
                    "lastgame_" + currentUserProfileId + "_" + session.companyIdAlias,
                    JSON.stringify({
                        gameId: session.appId,
                    }),
                    CookieConsentLevel.FUNCTIONALITY
                );
            } else {
                LocalStorageUtils.setItem(
                    "lastgame_" + currentUserProfileId + "_" + session.companyId,
                    JSON.stringify({
                        gameId: session.appId,
                    }),
                    CookieConsentLevel.FUNCTIONALITY
                );
            }

            return true;
        }
    } catch (error: any) {
        console.error("setAppId - ERROR", error);
    } finally {
        console.log("Done Selecting App!");

        if (applicationInformation.loadingBasicState) {
            // Indicate that we are no longer loading basic state.
            applicationInformation.loadingBasicState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // If the optional path and history are both present, push the requested path onto the history.
        if (action.payload.history && action.payload.path) {
            if (action.payload.history.location.pathname !== action.payload.path) {
                action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            } else {
                action.payload.history.replace(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
            }
        }
    }

    // Set various fields on the current session.
    delete session.appId;
    delete session.playerId;
    delete session.isLiveLocked;

    // Update the session in the redux store.
    yield put(SET_SESSION(CloneUtils.clone(session)));
    yield call(saveSession);

    // Reset the Cloud Code Editor state.
    yield put(CLOUD_CODE_EDITOR_RESET());

    // Clear the available privileges and screen settings.
    yield put(SET_AVAILABLE_PRIVILEGES([]));
    yield put(SET_SCREEN_SETTINGS([]));

    return false;
}

// Attempts to set the current user's player id (for user montioring).
export function* setPlayerId(action: SetPlayerId) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    let playerFound = false;

    try {
        console.log("Selecting Player", action.payload.playerId);

        if (action.payload.playerId) {
            const response: HttpResponse = yield Http.GET("admin/monitoring/playerSummaryByPlayerId", { playerId: action.payload.playerId }, Http.JSON_HEADERS);

            if (Http.isStatusOk(response) && response.data && Object.keys(response.data).length > 0) {
                playerFound = true;

                // Set the currently selected player id.
                session.playerId = action.payload.playerId;
                session.playerSummary = processPlayerSummary(response.data);

                yield put(SET_SESSION(session));
                yield call(saveSession);
            } else {
                toast.error(<Trans>User not found: {action.payload.playerId}</Trans>);

                // Set the currently selected player id.
                session.playerId = null;

                yield put(SET_SESSION(session));
                yield call(saveSession);
            }
        } else {
            // Set the currently selected player id.
            session.playerId = null;

            yield put(SET_SESSION(session));
            yield call(saveSession);
        }

        return true;
    } catch (error: any) {
        console.error("setPlayerId - ERROR", error);
    } finally {
        // If the optional path and history are both present, push the requested path onto the history.
        if (playerFound && action.payload.history && action.payload.path) {
            if (action.payload.history.location.pathname !== action.payload.path) {
                window.setTimeout(() => {
                    if (action.payload.history && action.payload.path) {
                        action.payload.history.push(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
                    }
                }, 0);
            } else {
                window.setTimeout(() => {
                    if (action.payload.history && action.payload.path) {
                        action.payload.history.replace(action.payload.state != null ? { pathname: action.payload.path, state: action.payload.state } : action.payload.path);
                    }
                }, 0);
            }
        }
    }

    return false;
}

// Attempts to toggle the session's "Live Lock" state.
export function* toggleLiveLock(action: ToggleLiveLock) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    // Get the current available apps.
    const getAvailableApps = (state: PortalState): AppInfo[] => [...state.availableApps];
    const availableApps: AppInfo[] = yield select(getAvailableApps);

    // Get the target app.
    const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

    // If the target app is not available, then just return (nothing to do).
    if (!targetApp) return false;

    try {
        console.log("Toggling Live Lock", targetApp.appId, targetApp.appName);

        if (targetApp.isLive) {
            if (action.payload.isLiveLocked != null) {
                // If the "isLiveLock" parameter was passed then force the live lock state.
                if (action.payload.isLiveLocked) {
                    // Attempt to "protect" (i.e. disable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/protect-application-privileges", undefined, undefined, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = true;
                    }
                } else {
                    // Attempt to "unprotect" (i.e. enable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/unprotect-app-privileges", undefined, { appName: action.payload.appName }, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = false;
                    }
                }
            } else {
                // Otherwise we just toggle the live lock state based on the current state of the session.
                if (session.isLiveLocked) {
                    // Attempt to "unprotect" (i.e. enable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/unprotect-app-privileges", undefined, { appName: action.payload.appName }, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = false;
                    }
                } else {
                    // Attempt to "protect" (i.e. disable for editing) the app (this results in our privileges being updated).
                    const response: HttpResponse = yield Http.POST("admin/development/protect-application-privileges", undefined, undefined, Http.JSON_HEADERS);
                    if (Http.isStatusOk(response)) {
                        session.isLiveLocked = true;
                    }
                }
            }
        } else {
            session.isLiveLocked = false;
        }

        yield put(SET_SESSION(session));
        yield call(saveSession);

        // Load the available privileges and screen settings.
        yield all([call(populateAvailablePrivileges), call(populateScreenSettings)]);

        return true;
    } catch (error: any) {
        console.error("toggleLiveLock - ERROR", error);
    }

    return false;
}

export function* dismissSystemMessage(_action?: DismissSystemMessage) {
    // Get the current application information.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    applicationInformation.systemMessage.isDismissed = true;
    yield put(SET_APPLICATION_INFORMATION(applicationInformation));
}

export const appGeneratorMap = {
    "app.setAppId": setAppId,
    "app.setPlayerId": setPlayerId,
    "app.populateAvailableApps": populateAvailableApps,
    "app.toggleLiveLock": toggleLiveLock,
    "app.dismissSystemMessage": dismissSystemMessage,
};

export default function* root() {
    yield all([
        takeLatest("app.populateAvailableApps", populateAvailableApps),
        takeLatest("app.setAppId", setAppId),
        takeLatest("app.setPlayerId", setPlayerId),
        takeLatest("app.toggleLiveLock", toggleLiveLock),
        takeLatest("app.dismissSystemMessage", dismissSystemMessage),
    ]);
}
