import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";

/**
 * Clipboard utility functions.
 */
export default abstract class ClipboardUtils {
    public static prepareForCopy(value: any) {
        if (value != null) {
            if (typeof value === "string") {
                try {
                    return JSON.stringify(JSON.parse(value), undefined, 2);
                } catch (error) {
                    return "" + value;
                }
            } else {
                if (typeof value === "object") {
                    return JSON.stringify(value, undefined, 2);
                } else {
                    return "" + value;
                }
            }
        } else {
            return "";
        }
    }

    public static writeText(value: any) {
        navigator.clipboard
            .writeText(ClipboardUtils.prepareForCopy(value))
            .then(() => {
                toast.success(<Trans>Copied to clipboard!</Trans>);
            })
            .catch(() => {
                toast.error(<Trans>Copy to clipboard Failed!</Trans>);
            });
    }

    public static writeTextAsync(p: Promise<any>) {
        const item = new ClipboardItem({
            "text/plain": p.then((result) => {
                if (!result) {
                    return Promise.resolve(new Blob([ClipboardUtils.prepareForCopy("")], { type: "text/plain" }));
                }

                return Promise.resolve(new Blob([ClipboardUtils.prepareForCopy(result)], { type: "text/plain" }));
            }),
        });

        return navigator.clipboard
            .write([item])
            .then(() => {
                toast.success(<Trans>Copied to clipboard!</Trans>);
            })
            .catch(() => {
                toast.error(<Trans>Copy to clipboard Failed!</Trans>);
            });
    }
}
