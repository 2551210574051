// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import types.
import { MenuItemDefinition } from "./types";

// Import components.
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenuItem from "./SubMenuItem";

interface OWN_PROPS {
    container?: React.ReactInstance | (() => React.ReactInstance | null) | null;
    id?: string;
    open: boolean;
    onClose: () => void;
    menuItems: MenuItemDefinition[];
    anchorElement: any;
    anchorOrigin: any;
    transformOrigin: any;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class CustomMenu extends React.PureComponent<PROPS> {
    renderMenuItems = () => {
        const { classes, menuItems } = this.props;

        return menuItems.map((menuItem, idx) => {
            if (!menuItem) return null;

            const id = menuItem.id;
            const label = menuItem.label;
            const icon = menuItem.icon;
            const iconPlacement = menuItem.iconPlacement;
            const hidden = typeof menuItem.hidden === "function" ? menuItem.hidden() : menuItem.hidden === true;
            const disabled = typeof menuItem.disabled === "function" ? menuItem.disabled() : menuItem.disabled === true;
            const selected = typeof menuItem.selected === "function" ? menuItem.selected() : menuItem.selected === true;
            const subMenuItems = menuItem.subMenuItems;
            const anchorOrigin = menuItem.anchorOrigin;
            const transformOrigin = menuItem.transformOrigin;
            const minWidth = menuItem.minWidth;

            if (hidden) {
                return null;
            } else if (subMenuItems) {
                return <SubMenuItem id={id} key={idx} label={label} icon={icon} iconPlacement={iconPlacement} disabled={disabled} selected={selected} menuItems={subMenuItems} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} />;
            } else {
                return (
                    <MenuItem
                        id={id}
                        key={idx}
                        className={classnames({ [classes.menuItem]: true, [classes.selected]: selected })}
                        style={{ flex: "1 1 auto", minWidth: minWidth ? (typeof minWidth === "number" ? minWidth + "px" : minWidth) : "unset", height: "auto" }}
                        disabled={disabled}
                        onClick={menuItem.onClick}
                    >
                        {icon && typeof icon === "string" && icon.trim().length > 0 && (iconPlacement == null || iconPlacement === "left") && (
                            <img src={icon} alt="" width={32} height={32} style={{ marginRight: "5px", borderWidth: "1px", borderStyle: "solid", borderRadius: "0.25em" }}></img>
                        )}
                        {icon && typeof icon !== "string" && (iconPlacement == null || iconPlacement === "left") && <span style={{ marginRight: "5px" }}>{icon}</span>}

                        <span>{label}</span>

                        {icon && typeof icon !== "string" && iconPlacement === "right" && <span style={{ marginLeft: "5px" }}>{icon}</span>}
                        {icon && typeof icon === "string" && icon.trim().length > 0 && iconPlacement === "right" && (
                            <img src={icon} alt="" width={32} height={32} style={{ marginRight: "5px", borderWidth: "1px", borderStyle: "solid", borderRadius: "0.25em" }}></img>
                        )}
                    </MenuItem>
                );
            }
        });
    };

    render() {
        const { classes, container, id, open, anchorElement, anchorOrigin, transformOrigin } = this.props;

        return (
            <Menu className={classes.root} id={id} open={open} onClose={this.props.onClose} anchorEl={anchorElement} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} getContentAnchorEl={null} container={container}>
                {this.renderMenuItems()}
            </Menu>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",
        },
        menuItem: {
            alignItems: "center",

            fontSize: "inherit",
        },
        selected: {
            backgroundColor: "var(--popup-menu-active-background-color, inherit)",
            color: "var(--popup-menu-active-color, inherit)",
        },
    });

export default withStyles(styles)(CustomMenu);
