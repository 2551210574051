// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { withStyles, createStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import { Typography, Link, Divider } from "@material-ui/core";
import IconButton from "components/common/button/IconButton";

// Import icons.
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import FlyoutFilledIcon from "components/icons/FlyoutFilled";

// Import redux actions.
import { SET_FAVORITES } from "store/actions/favorites";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";
import FavoriteUtils from "utils/Favorites";

// Import screen data assets.
import { getScreenDataForId } from "assets/data/screens";
import { withI18n, withI18nProps } from "@lingui/react";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    availablePrivileges: PortalPrivilege[];
    favorites: string[];
    recentlyViewed: string[];
}
interface DISPATCH_PROPS {
    setFavorites: (favorites: string[]) => void;
}
interface OWN_PROPS {
    context: "super" | "team" | "app";
    definitions: PortalRouteDefinition[];
    path: string;
    onNavigateToPath: (path: string) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        availablePrivileges: state.availablePrivileges,
        favorites: state.favorites,
        recentlyViewed: state.recentlyViewed,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setFavorites: (favorites: string[]) => dispatch(SET_FAVORITES(favorites)),
    };
};

interface STATE {}

class NavigatorRouteFlyout extends React.PureComponent<PROPS, STATE> {
    toggleFavorite = (path: string) => {
        const { currentUser, favorites } = this.props;

        if (currentUser) {
            const updatedFavorites = [...favorites];

            if (updatedFavorites.includes(path)) {
                updatedFavorites.splice(updatedFavorites.indexOf(path), 1);
            } else {
                updatedFavorites.push(path);
            }

            FavoriteUtils.setFavorites(currentUser.profileId, updatedFavorites);

            this.props.setFavorites(updatedFavorites);
        }
    };

    renderFavorites() {
        const { i18n, classes, context, definitions, favorites } = this.props;

        let filtered = [];

        switch (context) {
            case "super":
                filtered = favorites.filter((item) => item.startsWith("/super"));

                break;
            case "team":
                filtered = favorites.filter((item) => item.startsWith("/team"));

                break;
            case "app":
                filtered = favorites.filter((item) => item.startsWith("/app"));

                break;
        }

        filtered.sort((a, b) => {
            return a.localeCompare(b);
        });

        return (
            <div className={classes.section}>
                <span className={classes.title}>
                    <FlyoutFilledIcon
                        style={{
                            width: "1.5em",
                            height: "1.5em",

                            opacity: 0.3,

                            marginRight: "0.625em",
                        }}
                    />

                    <Typography className={classes.title} style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                        {context === "app" ? <Trans>App Favorites</Trans> : context === "super" ? <Trans>Super Favorites</Trans> : <Trans>Team Favorites</Trans>}
                    </Typography>
                </span>

                <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
                    {filtered.length > 0 &&
                        filtered.map((path, idx) => {
                            const definition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, path);

                            if (!definition) return null;

                            const screenData = getScreenDataForId(path);

                            const basePath = PortalRouteUtils.sanitizeRoutePath(path).substring(0, path.lastIndexOf("/"));

                            const screenTitle = screenData && screenData.title ? screenData.title(i18n) : null;
                            const definitionLabel = definition.label(i18n);

                            return this.renderLink("favorite", basePath, definition, idx, screenTitle || definitionLabel);
                        })}

                    {filtered.length === 0 && (
                        <>
                            <Typography style={{ fontWeight: "bold" }}>
                                <Trans>You can choose your favorite pages and they will appear here.</Trans>
                            </Typography>

                            <Typography style={{ marginTop: "0.625em" }}>
                                <Trans>
                                    Add favorites by clicking the ☆ icon next to the name of the page in the <b>Recently Visited</b> list, near the top right of any page, or in any pop-out menu.
                                </Trans>
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        );
    }

    renderRecentlyViewed() {
        const { i18n, classes, context, definitions, recentlyViewed } = this.props;

        let filtered = [];

        switch (context) {
            case "super":
                filtered = recentlyViewed.filter((item) => item.startsWith("/super"));

                break;
            case "team":
                filtered = recentlyViewed.filter((item) => item.startsWith("/team"));

                break;
            case "app":
                filtered = recentlyViewed.filter((item) => item.startsWith("/app"));

                break;
        }

        filtered.reverse();

        return (
            <div className={classes.section}>
                <Typography className={classes.title} style={{ fontWeight: "bold", fontSize: "1.5em" }}>
                    <Trans>Recently Viewed</Trans>
                </Typography>

                <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column" }}>
                    {filtered.length > 0 &&
                        filtered.map((path, idx) => {
                            const definition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, path);

                            if (!definition) return null;

                            const screenData = getScreenDataForId(path);

                            const basePath = PortalRouteUtils.sanitizeRoutePath(path).substring(0, path.lastIndexOf("/"));

                            const screenTitle = screenData && screenData.title ? screenData.title(i18n) : null;
                            const definitionLabel = definition.label(i18n);

                            return this.renderLink("recent", basePath, definition, idx, screenTitle || definitionLabel);
                        })}

                    {filtered.length === 0 && (
                        <Typography>
                            <Trans>No recently viewed.</Trans>
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    renderNavSummary() {
        const { i18n, classes, path, definitions } = this.props;

        const definition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, path);

        const definitionsWithChildren = definition?.routes?.filter((item) => item.routes && item.routes.length > 0) || [];
        const definitionsWithoutChildren = definition?.routes?.filter((item) => !item.routes || item.routes.length === 0) || [];

        return (
            <div className={classes.section}>
                {definition?.label && (
                    <span className={classes.title}>
                        <FlyoutFilledIcon
                            style={{
                                width: "1.5em",
                                height: "1.5em",

                                opacity: 0.3,

                                marginRight: "0.625em",
                            }}
                        />

                        <Typography style={{ fontWeight: "bold", fontSize: "1.5em" }}>{definition.label(i18n)}</Typography>
                    </span>
                )}

                {definitionsWithoutChildren.length > 0 && this.renderLinkCollection("summary", path, definitionsWithoutChildren, -1)}

                <div className={path === "/app/design" ? classes.dynamicSectionContent_nav_summary_design : classes.dynamicSectionContent_nav_summary_default}>
                    {definitionsWithChildren.length > 0 &&
                        definitionsWithChildren.map((definition, idx) => this.renderLinkCollectionWithLabel("summary", path + PortalRouteUtils.PATH_SEP + definition.path, definition.routes || [], idx, definition.label(i18n)))}
                </div>
            </div>
        );
    }

    renderLinkCollectionWithLabel = (type: string, basePath: string, definitions: PortalRouteDefinition[], idx: number, label: string) => {
        const { classes, session, currentUser, availableCompanies, availableApps, availablePrivileges } = this.props;

        const accessibleDefinitions = definitions.filter((item) => {
            const targetPath = PortalRouteUtils.sanitizeRoutePath(basePath + PortalRouteUtils.PATH_SEP + item.path);

            return PortalRouteUtils.hasAccess(this.props.definitions, targetPath, session, currentUser, availableCompanies, availableApps, availablePrivileges);
        });

        if (accessibleDefinitions.length === 0) return null;

        return (
            <div key={idx} className={classes.linkCollectionWithLabel}>
                {label && (
                    <Typography className={classes.subTitle} style={{ marginLeft: "2.125em" }}>
                        {label}
                    </Typography>
                )}

                <div>{accessibleDefinitions.map((item, idx) => this.renderLink(type, basePath, item, idx))}</div>
            </div>
        );
    };

    renderLinkCollection = (type: string, basePath: string, definitions: PortalRouteDefinition[], idx: number) => {
        const { classes, session, currentUser, availableCompanies, availableApps, availablePrivileges } = this.props;

        const accessibleDefinitions = definitions.filter((item) => {
            const targetPath = PortalRouteUtils.sanitizeRoutePath(basePath + PortalRouteUtils.PATH_SEP + item.path);

            return PortalRouteUtils.hasAccess(this.props.definitions, targetPath, session, currentUser, availableCompanies, availableApps, availablePrivileges);
        });

        if (accessibleDefinitions.length === 0) return null;

        return (
            <div key={idx} className={classes.linkCollection}>
                {accessibleDefinitions.map((item, idx) => this.renderLink(type, basePath, item, idx))}
            </div>
        );
    };

    renderLink = (type: string, basePath: string, definition: PortalRouteDefinition, idx: number, label?: string, split?: boolean) => {
        const { i18n, classes, favorites } = this.props;

        const targetPath = PortalRouteUtils.sanitizeRoutePath(basePath + PortalRouteUtils.PATH_SEP + definition.path);

        const mutliplePartsIdx = label ? label.indexOf(" - ") : -1;
        const firstPart = label && mutliplePartsIdx > -1 ? label.substring(0, mutliplePartsIdx) : null;
        const secondPart = label && mutliplePartsIdx > -1 ? label.substring(mutliplePartsIdx + 3, label.length) : null;

        return (
            <span key={idx} className={!favorites.includes(targetPath) ? classes.hiddenIconUntilHover : undefined} style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                <IconButton id={"toggle-favorite-" + targetPath} style={{ width: "1.5em", height: "1.5em", marginRight: "0.625em" }} onClick={() => this.toggleFavorite(targetPath)}>
                    {favorites.includes(targetPath) && <StarIcon />}
                    {!favorites.includes(targetPath) && <StarOutlineIcon />}
                </IconButton>

                {label && mutliplePartsIdx > -1 && (
                    <Link data-id={targetPath} data-type={type} target="_blank" onClick={() => this.props.onNavigateToPath(targetPath)}>
                        {firstPart} - <b>{secondPart}</b>
                    </Link>
                )}
                {label && mutliplePartsIdx === -1 && (
                    <Link data-id={targetPath} data-type={type} target="_blank" onClick={() => this.props.onNavigateToPath(targetPath)}>
                        <b>{label}</b>
                    </Link>
                )}
                {!label && (
                    <Link data-id={targetPath} data-type={type} target="_blank" onClick={() => this.props.onNavigateToPath(targetPath)}>
                        {definition.label(i18n)}
                    </Link>
                )}
            </span>
        );
    };

    render() {
        const { classes, path } = this.props;

        const isFavorites = ["/super/favorites", "/team/favorites", "/app/favorites"].includes(path);

        return (
            <div className={classes.root}>
                {isFavorites && (
                    <div className={classes.dynamicSectionContent_favorites}>
                        {this.renderFavorites()}

                        <Divider orientation="vertical" style={{ height: "auto", marginLeft: "0.625em", marginRight: "0.625em" }} />
                        <Divider orientation="horizontal" style={{ width: "auto", marginTop: "0.625em", marginBottom: "0.625em" }} />

                        {this.renderRecentlyViewed()}
                    </div>
                )}

                {!isFavorites && this.renderNavSummary()}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            alignItems: "stretch",
        },
        section: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        },
        sectionContent: {
            display: "grid",
            gridTemplateColumns: "auto auto auto",
        },
        dynamicSectionContent_favorites: {
            display: "grid",
            gridTemplateColumns: "auto",

            "& > *:nth-child(1)": {
                width: 240,
                maxWidth: 400,
            },
            "& > *:nth-child(2)": {
                display: "none",
            },
            "& > *:nth-child(3)": {
                display: "block",
            },
            "& > *:nth-child(4)": {
                width: 240,
                maxWidth: 400,
            },

            "@media (min-width: 775px)": {
                gridTemplateColumns: "auto 1.3125em auto",

                "& > *:nth-child(1)": {
                    width: "calc(45vw - 10em)",
                },
                "& > *:nth-child(2)": {
                    display: "block",
                },
                "& > *:nth-child(3)": {
                    display: "none",
                },
                "& > *:nth-child(4)": {
                    width: "calc(45vw - 10em)",
                },
            },
        },
        dynamicSectionContent_nav_summary_default: {
            display: "grid",
            gridTemplateColumns: "auto",

            "@media (min-width: 775px)": {
                gridTemplateColumns: "auto auto",
            },
            "@media (min-width: 985px)": {
                gridTemplateColumns: "auto auto auto",
            },
        },
        dynamicSectionContent_nav_summary_design: {
            display: "grid",
            gridTemplateColumns: "auto",

            "@media (min-width: 775px)": {
                gridTemplateColumns: "auto auto",
            },
            "@media (min-width: 985px)": {
                gridTemplateColumns: "auto auto auto",
            },
            "@media (min-width: 1155px)": {
                gridTemplateColumns: "auto auto auto auto",
            },
            "@media (min-width: 1385px)": {
                gridTemplateColumns: "auto auto auto auto auto",
            },
        },
        title: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
        },
        subTitle: {
            flex: "0 0 auto",
            fontWeight: "bold",
        },
        linkCollectionWithLabel: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            padding: "0.625em",

            "& > div": {
                flex: "0 0 auto",
                display: "flex",
                flexDirection: "column",
            },
        },
        linkCollection: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            padding: "0.625em",
        },
        hiddenIconUntilHover: {
            "& > *:nth-child(1)": {
                opacity: 0,
            },
            "&:hover > *:nth-child(1)": {
                opacity: 1,
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(NavigatorRouteFlyout)));
