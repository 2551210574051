// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles, WithWidthProps, withWidth, isWidthDown } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

// Import types.
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import SystemMessageBanner from "../SystemMessageBanner";
import { Popover } from "@material-ui/core";
import Title from "components/common/screen/Title";
import InfoBox from "components/common/screen/InfoBox";
import IconButton from "components/common/button/IconButton";

// Import icons.
import InfoIcon from "@material-ui/icons/InfoOutlined";

// Import screen data collection.
import { getScreenDataForId } from "assets/data/screens";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";

interface OWN_PROPS {
    definitions: PortalRouteDefinition[];
    open: boolean;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps, WithWidthProps {}

interface STATE {
    maxHeight: string | number;
    anchorEl: any;
}

class Info extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        maxHeight: "unset",
        anchorEl: null,
    };
    private rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (this.rootRef.current && this.state.maxHeight === "unset") {
            this.setState({ maxHeight: this.rootRef.current.scrollHeight });
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ maxHeight: "unset" });
        } else {
            if (this.rootRef.current && this.state.maxHeight === "unset") {
                this.setState({ maxHeight: this.rootRef.current.scrollHeight });
            }
        }
    }

    componentWillUnmount(): void {
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const { maxHeight } = this.state;

        if (this.rootRef.current && maxHeight !== "unset" && this.rootRef.current.scrollHeight !== maxHeight) {
            this.setState({ anchorEl: null, maxHeight: this.rootRef.current.scrollHeight });
        }
    };

    openInfoBox = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeInfoBox = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { width, i18n, classes, location, definitions, open } = this.props;
        const { maxHeight, anchorEl } = this.state;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        let screenData = getScreenDataForId(currentPath);

        if (!screenData) {
            screenData = {
                id: currentPath,
                title: (i18n: I18n) => i18n._(t`MISSING SCREEN DATA (${currentPath}) - TODO: Supply appropriate title and infobox content.`),
            };
        }

        // Determine whether the screen info should be shown in the "mobile" view.
        const minimalScreenInfo = width && isWidthDown("sm", width);

        return (
            <div ref={this.rootRef} id={"screen-info"} className={classes.root} style={{ maxHeight: open ? maxHeight : 0 }}>
                {screenData.title && (
                    <Title label={screenData.title(i18n)}>
                        {minimalScreenInfo && screenData.infobox && (
                            <IconButton id="infobox" onClick={this.openInfoBox}>
                                <InfoIcon />
                            </IconButton>
                        )}
                    </Title>
                )}

                <SystemMessageBanner />

                {screenData.infobox && !minimalScreenInfo && <InfoBox text={screenData.infobox.text} learnMore={screenData.infobox.learnMore} />}

                {screenData.infobox && minimalScreenInfo && anchorEl && (
                    <Popover
                        id={"learn-more-pop-over"}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={this.closeInfoBox}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <InfoBox hideBorder={true} style={{ flexDirection: "column" }} text={screenData.infobox.text} learnMore={screenData.infobox.learnMore} />
                    </Popover>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
            overflow: "hidden",

            transition: "max-height 0.2s linear, border-bottom-width 0.2s linear",
        },
    });

export default withRouter(withWidth()(withI18n()(withStyles(styles)(Info))));
