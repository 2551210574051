// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { t } from "@lingui/macro";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import PortalState from "types/store";
import User from "types/common/User";
import ScreenSettings from "types/common/ScreenSettings";
import { ScreenData } from "assets/data/screens";
import { FormatOptions, SearchResult } from "../../../../engine/types";

// Import components.
import { Typography, MenuItem } from "@material-ui/core";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons.
import WebAssetIcon from "@material-ui/icons/WebAsset";

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    data: SearchResult;

    formatOptions?: FormatOptions | null;

    onResultClick: (result: SearchResult) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class ScreenResult extends React.PureComponent<PROPS> {
    render() {
        const { i18n, classes, currentUser, data } = this.props;

        if (!data.data) return null;

        let screenName: any = null;

        if (data.data.systemEnableValue) {
            // The screen result is based on the screen settings.
            screenName = currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? (data.data as ScreenSettings).namexFr || null : (data.data as ScreenSettings).namexEn || null;

            if (screenName) {
                screenName = i18n._(t`APP >`) + " " + screenName;
            }
        } else {
            // The screen result is based on the screen data.
            if (data.data.search) {
                const screenData = data.data as ScreenData;

                if (screenData.search?.title) {
                    screenName = screenData.search.title(i18n);
                }
            }
        }

        if (!screenName) {
            return null;
        }

        return (
            <MenuItem component={"div"} className={classes.result} onClick={() => this.props.onResultClick(data)}>
                <ImageWrapper style={{ width: "2.25em", height: "2.25em", marginRight: "0.625em" }} src={<WebAssetIcon />} />

                <span className={classes.resultInfo}>
                    <Typography style={{ fontWeight: "bold", overflow: "hidden" }} noWrap>
                        {screenName}
                    </Typography>
                </span>
            </MenuItem>
        );
    }
}

const styles = () =>
    createStyles({
        result: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            padding: "0.625em",

            "&:not(:first-of-type)": {
                borderTopStyle: "solid",
                borderTopWidth: "0.0625em",
            },

            "&:hover": {
                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                color: "var(--popup-menu-hover-color, inherit)",
            },
        },
        resultInfo: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(ScreenResult)));
