// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import { Query } from "../../engine/types";

// Import components.
import { Chip, Typography } from "@material-ui/core";

interface OWN_PROPS {
    queries: Query[] | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class Queries extends React.PureComponent<PROPS> {
    render() {
        const { classes, queries } = this.props;

        if (!queries) return null;

        return (
            <div className={classes.root}>
                <Typography style={{ fontWeight: "bold" }} noWrap>
                    <Trans>Applied Hints:</Trans>
                </Typography>

                {queries.map((query, idx) => {
                    return <Chip key={idx} className={classes.chip} label={query.subHint ? query.hint + " (" + query.subHint + ")" : query.hint} />;
                })}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",
            borderBottomColor: "inherit",

            padding: "0.5em 0.75em",
        },
        chip: {
            maxHeight: "1.25em",
            backgroundColor: "var(--chip-default-background-color, inherit)",
            color: "var(--chip-default-color, inherit)",
            margin: "0.3125em",
        },
    });

export default withStyles(styles)(Queries);
