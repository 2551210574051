// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";

// Import types.
import TwoFactorType from "types/enums/TwoFactorType";
import Verify2FAStatus, { formatVerify2FAStatusBackgroundColor, formatVerify2FAStatusColor, formatVerify2FAStatusLabel } from "types/enums/Verify2FAStatus";
import PortalState from "types/store";
import ClientInformation from "types/common/ClientInformation";
import { TextFieldOptions } from "components/common/form/fields/TextField";
import { CheckboxFieldOptions } from "components/common/form/fields/CheckboxField";

// Import components.
import { Chip, Typography } from "@material-ui/core";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";

// Import icons.
import PhoneIcon from "@material-ui/icons/PhoneAndroid";
import SmsIcon from "@material-ui/icons/Sms";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";

// Import services.
import Services from "./services";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import PollerUtils, { PollerStatus } from "utils/Poller";
import { AUTHY_ONETOUCH_POLLER_ID } from "utils/PollerIds";

interface STATE_PROPS {
    clientInformation: ClientInformation;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    type: TwoFactorType | null;
    onComplete: (approved: boolean | null) => void;
    onCancel: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    authToken: string;

    rememberDevice: boolean;
    rememberDeviceExpiry: number;

    oneTouchStatus: Verify2FAStatus | null;
    manualStatus: Verify2FAStatus | null;
}

const mapStateToProps = (state: PortalState) => {
    return {
        clientInformation: state.clientInformation,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

// Implementation of the Authy OneTouch poller.
const authyOneTouchPoller = async (browserClientId: string, rememberDevice: boolean, rememberDeviceExpiry: number, updatePollerTokenStatus: (status: Verify2FAStatus) => void) => {
    // Construct the Authy OneTouch form.
    const authyOneTouchForm = {
        browserId: browserClientId,
        expiryDays: rememberDevice ? "" + rememberDeviceExpiry : "0",
    };

    // Perform the REST call to get the current Authy OneTouch status.
    const response = await Http.GET("tfa/poll-status", authyOneTouchForm);
    if (Http.isStatusOk(response)) {
        // Ensure that our poller is still valid and running (in case it was PAUSED or STOPPED during execution).
        // This is IMPORTANT in order to prevent any undesirable side-effects (such as invocations of the "setState" function or saga's).
        const currentPoller = PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID);
        if (!currentPoller || currentPoller.status === PollerStatus.PAUSED) {
            return;
        }

        // Get the response data.
        const data = response.data;

        // If we have no data, then there is nothing to do.
        if (data == null) return;

        // Process the reported Authy OneTouch status.
        const status = data["oneTouchStatus"];
        switch (status) {
            case "pending":
                updatePollerTokenStatus(Verify2FAStatus.PENDING);
                break;
            case "approved":
                updatePollerTokenStatus(Verify2FAStatus.APPROVED);
                break;
            case "denied":
                updatePollerTokenStatus(Verify2FAStatus.DENIED);
                break;
            case "expired":
                updatePollerTokenStatus(Verify2FAStatus.EXPIRED);
                break;
            default:
                console.log("Unknown Authy OneTouch Status", data["oneTouchStatus"]);
        }
    }
};

const DEFAULT_DEVICE_EXPIRY = 30;

class Verify2FA extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        authToken: "",

        rememberDevice: true,
        rememberDeviceExpiry: DEFAULT_DEVICE_EXPIRY,

        oneTouchStatus: null,
        manualStatus: null,
    };

    componentDidMount() {
        const { type } = this.props;

        if (type === TwoFactorType.AUTHY) {
            this.setState({ oneTouchStatus: Verify2FAStatus.PENDING }, () => {
                // When first mounting, start the Authy OneTouch poller.
                PollerUtils.startPoller(
                    AUTHY_ONETOUCH_POLLER_ID, // Poller ID.
                    0, // Start delay in ms (0 = immediately).
                    0, // Maximum number of iterations (0 = infinite).
                    3000, // Interval between iterations in ms.
                    authyOneTouchPoller, // The actual poller implementation function.
                    [this.props.clientInformation.browserClientId, this.state.rememberDevice, this.state.rememberDeviceExpiry, this.updateOneTouchStatus]
                );
            });
        }
    }

    componentWillUnmount() {
        // When unmounting, stop the Authy OneTouch poller if it is still running (which would happen when manual token verification is performed).
        if (PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID)) {
            PollerUtils.stopPoller(AUTHY_ONETOUCH_POLLER_ID);
        }
    }

    updateOneTouchStatus = (status: Verify2FAStatus) => {
        const currentStatus = this.state.oneTouchStatus;

        // If the status has not changed, then just return.
        if (status === currentStatus) return;

        this.setState({ oneTouchStatus: status }, () => {
            const status = this.state.oneTouchStatus;

            const currentPoller = PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID);

            switch (status) {
                case Verify2FAStatus.APPROVED:
                    // Stop the Authy OneTouch Poller.
                    if (currentPoller) {
                        PollerUtils.stopPoller(AUTHY_ONETOUCH_POLLER_ID);
                    }

                    window.setTimeout(() => {
                        this.props.onComplete(true);
                    }, 1000);

                    break;
                case Verify2FAStatus.DENIED:
                    // Stop the Authy OneTouch Poller.
                    if (currentPoller) {
                        PollerUtils.stopPoller(AUTHY_ONETOUCH_POLLER_ID);
                    }

                    break;
                case Verify2FAStatus.EXPIRED:
                    // Stop the Authy OneTouch Poller.
                    if (currentPoller) {
                        PollerUtils.stopPoller(AUTHY_ONETOUCH_POLLER_ID);
                    }

                    break;
                default:
                // Do nothing.
            }
        });
    };

    handleChange = (name: string, value: any) => {
        const updatedState = CloneUtils.clone(this.state) as STATE;

        switch (name) {
            case "authToken":
                updatedState.manualStatus = null;
                updatedState.authToken = value != null ? value.trim() : "";
                break;
            case "rememberDevice":
                updatedState.rememberDevice = value === true;
                break;
            case "rememberDeviceExpiry":
                updatedState.rememberDeviceExpiry = value || DEFAULT_DEVICE_EXPIRY;
                break;
            default:
            // Do nothing.
        }

        this.setState(updatedState, () => {
            // If either of 'rememberDevice' or 'rememberDeviceExpiry' is changed we need to update the Authy OneTouch poller options.
            if (["rememberDevice", "rememberDeviceExpiry"].includes(name)) {
                if (PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID)) {
                    PollerUtils.updatePoller(
                        AUTHY_ONETOUCH_POLLER_ID, // Poller ID.
                        0, // Maximum number of iterations (0 = infinite).
                        3000, // Interval between iterations in ms.
                        [this.props.clientInformation.browserClientId, this.state.rememberDevice, this.state.rememberDeviceExpiry, this.updateOneTouchStatus]
                    );
                }
            }
        });
    };

    handleKeyPress = (name: string, key: string) => {
        if (["authToken"].includes(name) && key === "Enter") {
            this.onConfirm();
        }
    };

    // isFormValid = (): boolean => {
    //     const { authToken, oneTouchStatus, authTokenStatus } = this.state;

    //     if (oneTouchStatus === Verify2FAStatus.APPROVED) return false;
    //     if (authTokenStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(authTokenStatus)) return false;
    //     if (authToken.trim().length !== 7) return false;

    //     return true;
    // };

    onSendSMS = async () => {
        const { type } = this.props;

        if (type) {
            try {
                await Services.sendSMS(type);
            } catch (error: any) {
                toast.error("[" + error.errorCode + "] - " + error.errorMessage);
            }
        }
    };

    onAbort = () => {
        this.props.onCancel();
    };

    onConfirm = async () => {
        const { bcFormValidator, type } = this.props;

        if (!bcFormValidator.validate()) return;

        // Pause the Authy OneTouch Poller.
        const currentPoller = PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID);
        if (currentPoller && currentPoller.status !== PollerStatus.PAUSED) {
            PollerUtils.pausePoller(AUTHY_ONETOUCH_POLLER_ID);
        }

        this.setState({ manualStatus: Verify2FAStatus.PENDING }, async () => {
            let response: HttpResponse | null = null;

            switch (type) {
                case TwoFactorType.VERIFY:
                    response = await Http.GET("tfa/totp/token/verify", {
                        browserId: this.props.clientInformation.browserClientId || "",
                        token: this.state.authToken,
                        expiryDays: this.state.rememberDevice ? "" + this.state.rememberDeviceExpiry : "0",
                    });

                    break;
                case TwoFactorType.AUTHY:
                    response = await Http.GET("tfa/verify-tfa-token", {
                        browserId: this.props.clientInformation.browserClientId || "",
                        token: this.state.authToken,
                        expiryDays: this.state.rememberDevice ? "" + this.state.rememberDeviceExpiry : "0",
                    });

                    break;
                default:
                // Do nothing.
            }

            if (response) {
                if (Http.isStatusOk(response) && !response.redirected) {
                    this.setState({ manualStatus: Verify2FAStatus.APPROVED }, () => {
                        // Stop the Authy OneTouch Poller.
                        const currentPoller = PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID);
                        if (currentPoller) {
                            PollerUtils.stopPoller(AUTHY_ONETOUCH_POLLER_ID);
                        }

                        window.setTimeout(() => {
                            this.props.onComplete(true);
                        }, 1000);
                    });
                } else {
                    const error = Http.buildError(response);

                    toast.error("[" + error.errorCode + "] - " + error.errorMessage);

                    this.setState({ manualStatus: Verify2FAStatus.INVALID_TOKEN }, () => {
                        // Resume the Authy OneTouch Poller.
                        const currentPoller = PollerUtils.getPoller(AUTHY_ONETOUCH_POLLER_ID);
                        if (currentPoller) {
                            PollerUtils.resumePoller(AUTHY_ONETOUCH_POLLER_ID);
                        }
                    });
                }
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes, type } = this.props;
        const { authToken, rememberDevice, rememberDeviceExpiry, oneTouchStatus, manualStatus } = this.state;

        return (
            <div id={"verify-2fa"} className={classes.root}>
                {oneTouchStatus !== Verify2FAStatus.APPROVED && manualStatus !== Verify2FAStatus.APPROVED && (
                    <>
                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Additional Authentication Required</Trans>
                        </Typography>

                        <span className={classes.twoFactorIconWrapper}>
                            <span className={classes.twoFactorIcon}>
                                <PhoneIcon />
                                <span>
                                    <SmsIcon />
                                </span>
                            </span>
                        </span>

                        {type === TwoFactorType.VERIFY && (
                            <span className={classes.sendSmsWrapper}>
                                <Typography>
                                    <Trans>Get a code from the authenticator app attached to your account.</Trans>
                                </Typography>
                            </span>
                        )}

                        {type === TwoFactorType.AUTHY && (
                            <span className={classes.sendSmsWrapper}>
                                <Typography>
                                    <Trans>Get a authy code from the phone number attached to your account.</Trans>
                                </Typography>

                                <Button className={classes.button} id={"send-sms"} type={"primary"} onClick={this.onSendSMS} disabled={manualStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(manualStatus)}>
                                    <Trans>Send SMS</Trans>
                                </Button>
                            </span>
                        )}

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"text"}
                            name={"authToken"}
                            value={authToken}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={(manualStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(manualStatus)) || !type}
                            autoFocus={true}
                            options={
                                {
                                    placeholder: type === TwoFactorType.VERIFY ? i18n._(t`Verify Code (6 digit password)`) : type === TwoFactorType.AUTHY ? i18n._(t`Authy Code (7 digit password)`) : i18n._(t`Code`),
                                    minLength: type === TwoFactorType.VERIFY ? 6 : type === TwoFactorType.AUTHY ? 7 : 0,
                                    maxLength: type === TwoFactorType.VERIFY ? 6 : type === TwoFactorType.AUTHY ? 7 : 0,
                                    pattern: type === TwoFactorType.VERIFY ? /\d{6,6}/ : type === TwoFactorType.AUTHY ? /\d{7,7}/ : undefined,
                                    patternMessage: i18n._(t`Token must only consist of numbers.`),
                                } as TextFieldOptions
                            }
                        />

                        <span className={classes.rememberDeviceWrapper}>
                            <FieldWrapper
                                formValidator={bcFormValidator}
                                className={classes.field}
                                type={"checkbox"}
                                name={"rememberDevice"}
                                value={rememberDevice}
                                label={<Trans>Trust this device for {rememberDeviceExpiry} days</Trans>}
                                labelPosition={"right"}
                                controlStyle={{ flex: "0 0 auto" }}
                                labelStyle={{ flex: "0 0 auto" }}
                                onChange={this.handleChange}
                                disabled={(manualStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(manualStatus)) || !type}
                                options={
                                    {
                                        clickableLabel: true,
                                    } as CheckboxFieldOptions
                                }
                            />

                            {type === TwoFactorType.AUTHY && (
                                <span style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "center", borderStyle: "solid", borderWidth: "1px", borderColor: "inherit", borderRadius: "0.25em", overflow: "hidden" }}>
                                    <Typography style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
                                        <Trans>Authy OneTouch</Trans>
                                    </Typography>

                                    <Chip
                                        style={{
                                            alignSelf: "stretch",
                                            height: "1rem",
                                            fontSize: "0.75rem",
                                            fontWeight: "bold",
                                            borderRadius: 0,
                                            backgroundColor: formatVerify2FAStatusBackgroundColor(oneTouchStatus),
                                            color: formatVerify2FAStatusColor(oneTouchStatus),
                                        }}
                                        label={formatVerify2FAStatusLabel(oneTouchStatus)}
                                    />
                                </span>
                            )}
                        </span>

                        <Button className={classes.button} id={"verify"} type={"primary"} onClick={this.onConfirm} disabled={(manualStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(manualStatus)) || !type}>
                            {manualStatus === Verify2FAStatus.PENDING ? <Trans>Please Wait...</Trans> : <Trans>Verify</Trans>}
                        </Button>

                        {manualStatus === Verify2FAStatus.INVALID_TOKEN && <Chip className={classes.chip} label={<Trans>Manually supplied token is invalid</Trans>} />}

                        <Button className={classes.button} id={"cancel"} type={"semantic-negative-primary"} onClick={this.onAbort} disabled={manualStatus != null && [Verify2FAStatus.PENDING, Verify2FAStatus.APPROVED].includes(manualStatus)}>
                            <Trans>Abort</Trans>
                        </Button>
                    </>
                )}

                {(oneTouchStatus === Verify2FAStatus.APPROVED || manualStatus === Verify2FAStatus.APPROVED) && (
                    <div className={classes.verificationComplete}>
                        <SuccessIcon />

                        <Typography>
                            <Trans>2FA Verified</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        twoFactorIconWrapper: {
            flex: "0 0 auto",
            alignSelf: "center",

            width: "7rem",
            height: "10rem",

            overflow: "hidden",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },

        twoFactorIcon: {
            flex: "0 0 auto",

            width: "9rem",
            height: "12rem",

            position: "relative",

            overflow: "hidden",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "inherit",
            borderColor: "inherit",

            "& > *:first-child": {
                position: "absolute",

                width: "100%",
                height: "100%",
            },

            "& > *:last-child": {
                position: "absolute",

                top: "35%",
                right: "10%",

                width: "30%",
                height: "21.5%",

                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",

                "& > *": {
                    width: "100%",
                    height: "100%",

                    color: "var(--label-positive-color, inherit)",
                },
            },
        },

        sendSmsWrapper: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            "& > *:first-child": {
                flex: "1 1 auto",

                fontSize: "0.75rem",

                overflow: "hidden",

                marginRight: "0.3125rem",
            },

            "& > *:last-child": {
                flex: "0 0 auto",

                minWidth: "30%",

                margin: 0,
            },
        },

        rememberDeviceWrapper: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            "& > *:first-child": {
                flex: "1 1 auto",

                justifyContent: "flex-end",

                fontSize: "0.75rem",

                overflow: "hidden",

                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
                marginRight: "0.3125rem",
            },

            "& > *:last-child": {
                flex: "0 0 auto",

                minWidth: "30%",

                margin: 0,
            },
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        verificationComplete: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",

            overflow: "hidden",

            "& > *": {
                marginLeft: "auto",
                marginRight: "auto",

                "&.MuiSvgIcon-root": {
                    width: "6rem",
                    height: "6rem",

                    color: "var(--label-positive-color, inherit)",
                },

                "&.MuiTypography-root": {
                    marginTop: "2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                },
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withFormValidator()(withI18n()(withStyles(styles)(Verify2FA))));
