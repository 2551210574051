import { Context, Query } from "./types";

// Statically supported hints (explicit hints).
export const ACCOUNT_NUMBER_HINTS = ["accountnumber"];
export const TEAM_ID_HINTS = ["companyid", "teamid", "id"];
export const TEAM_NAME_HINTS = ["companyname", "teamname", "name"];
export const APP_ID_HINTS = ["appid", "gameid", "id"];
export const APP_NAME_HINTS = ["appname", "gamename", "name"];
export const PLAYER_ID_HINTS = ["playerid", "profileid", "id"];
export const PLAYER_EMAIL_HINTS = ["emailaddress", "contactemail", "email"];
export const PAGE_KEY_HINTS = ["pagekey", "key"];
export const PAGE_NAME_HINTS = ["pagename", "screenname", "name"];

// All statically supported hints.
const STATIC_HINTS = [...ACCOUNT_NUMBER_HINTS, ...TEAM_ID_HINTS, ...APP_ID_HINTS, ...APP_NAME_HINTS, ...PLAYER_ID_HINTS, ...PLAYER_EMAIL_HINTS, ...PAGE_KEY_HINTS, ...PAGE_NAME_HINTS];

// RegExp describing the acceptable query format.
//
// Specific "targeted" format is based on '<hint> : <searchTerm>'.
//     - Where the 'hint' indicates the scope of search (this is the "sepcific" form of searching).
//     - For example a 'hint' of "app" or "appId" indicates the user is looking for app/game oriented results (typically linking to the desgin, monitoring or reporting sections).
//     - For example a 'hint' of "player" or "playerId" indicates the user is looking for player oriented results (typically linking to the user monitoring sections).
//
// Generic "smart" format is more or less free-form (so long as it does not match a "targeted" search as described above).
//     - The search engine will basically attempt to "guess" applicable targets based on how it is configured.
//     - The intent is to define custom handlers that can be injected into the search pipe-line, where each handler gets an opportunity
//       to process the query and suggest results (if applicable). The results from each handler would then be aggregated into the final
//       search resutls that get shown to the user.
//
// Examples:
//     - Team ID:               'teamId: 1234-5678-0000'
//     - App ID:                'appId: 12345'
//     - Player ID:             'playerId: 1234-5678-0000'
//     - Player Email:          'email: test@test.com'
//     - Page Key:              'pageKey: DESIGN_APPIDS'
//     - Player External ID:    'externalid-<authType>: 1234'
//
const FIELD_QUERY_REGEX = /^([a-z0-9_-]+)\s*:\s*(.*)$/i;

/**
 * Parses the supplied query string into a Query instance (or returns the original string if it is not parseable, this will be treated as a "smart" search).
 *
 * An additional caveat is that if the 'treatUnknownHintsAsNull' parameter is TRUE and the parsed query produces an unknown hint then null will returned.
 * This is used to prevent the "smart" generator from generating queries and instead allows other generators the opportunity to generate dynamic hints.
 *
 * For example: the "external auth" generator.
 *  - Sample: "externalid-<auth_type>: <search_term>"
 *
 * @param queryString
 * @returns Query
 */
export const parseQuery = (queryString: string, treatUnknownHintsAsNull?: boolean): Query | string | null => {
    const matches = FIELD_QUERY_REGEX.exec(queryString);

    if (matches && matches.length === 3) {
        const hint = matches[1].trim();
        let searchTerm = matches[2].trim();

        if ((searchTerm.startsWith('"') && searchTerm.endsWith('"')) || (searchTerm.startsWith("'") && searchTerm.endsWith("'"))) {
            searchTerm = searchTerm.substring(1, searchTerm.length - 1);
        }

        if (STATIC_HINTS.includes(hint.toLowerCase()) || !treatUnknownHintsAsNull) {
            return {
                hint: hint,
                searchTerm: searchTerm,
            };
        }

        return null;
    } else {
        return queryString;
    }
};

/**
 * Determines the current navigation context based on the supplied pathname.
 *
 * Possible contexts are: SUPER, TEAM or APP.
 *
 * If one of the above three contexts cannot be determined, then null is returned.
 *
 * @param pathname
 * @returns SUPER, TEAM, APP or null if no context could be determined
 */
export const determineNavigationContext = (pathname?: string | null): Context | null => {
    if (!pathname) return null;

    return pathname.startsWith("/super") ? Context.SUPER : pathname.startsWith("/team") ? Context.TEAM : pathname.startsWith("/app") ? Context.APP : null;
};
