import React, { Suspense } from "react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt, faStar } from "@fortawesome/free-solid-svg-icons";

import LoadingProgress from "components/common/widgets/LoadingProgress";

const Dashboard = React.lazy(() => import("components/screens/super/Dashboard"));
const Apps = React.lazy(() => import("components/screens/super/manage/Apps"));
const Teams = React.lazy(() => import("components/screens/super/manage/Teams"));
const Users = React.lazy(() => import("components/screens/super/manage/Users"));
const Branding = React.lazy(() => import("components/screens/common/Branding"));
const Config = React.lazy(() => import("components/screens/super/configure/Config"));
const WebRequestDebugger = React.lazy(() => import("components/screens/super/develop/WebRequestDebugger"));
const Libraries = React.lazy(() => import("components/screens/super/configure/Libraries"));
const Screens = React.lazy(() => import("components/common/Screens"));
const Properties = React.lazy(() => import("components/common/Properties"));
const Caches = React.lazy(() => import("components/screens/super/configure/Caches"));
const ErrorLogs = React.lazy(() => import("components/screens/common/ErrorLogs"));
const AuditLogs = React.lazy(() => import("components/screens/common/AuditLogs"));
const UnreadableRequests = React.lazy(() => import("components/screens/common/UnreadableRequests"));
const FormField = React.lazy(() => import("components/screens/debug/FormField"));
const ButtonDemo = React.lazy(() => import("components/screens/debug/ButtonDemo"));
const TableDemo = React.lazy(() => import("components/screens/debug/TableDemo"));
const TableDemo2 = React.lazy(() => import("components/screens/debug/TableDemo2"));
const MarkdownEditorDemo = React.lazy(() => import("components/screens/debug/MarkdownEditorDemo"));
const CodeEditorDemo = React.lazy(() => import("components/screens/debug/CodeEditorDemo"));
const Template = React.lazy(() => import("components/screens/debug/Template"));

const routes: PortalRouteDefinition[] = [
    {
        path: "super",
        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
            return session.isSuper === true;
        },
        label: (i18n: I18n) => i18n._(t`Super`),
        icon: null,
        exact: true,
        redirect: "dashboard",
        routes: [
            {
                path: "dashboard",
                label: (i18n: I18n) => i18n._(t`Dashboard`),
                icon: <FontAwesomeIcon icon={faTachometerAlt} size={"2x"} />,
                exact: true,
                component: (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <Dashboard />
                    </Suspense>
                ),
            },
            {
                path: "favorites",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return false;
                },
                label: (i18n: I18n) => i18n._(t`Favorites`),
                icon: <FontAwesomeIcon icon={faStar} size={"2x"} />,
                exact: true,
                routes: [],
            },
            {
                path: "manage",
                label: (i18n: I18n) => i18n._(t`Manage`),
                icon: null,
                exact: true,
                redirect: "apps",
                routes: [
                    {
                        path: "apps",
                        label: (i18n: I18n) => i18n._(t`Apps`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Apps />
                            </Suspense>
                        ),
                    },
                    {
                        path: "teams",
                        label: (i18n: I18n) => i18n._(t`Teams`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Teams />
                            </Suspense>
                        ),
                    },
                    {
                        path: "users",
                        label: (i18n: I18n) => i18n._(t`Users`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Users />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "configure",
                label: (i18n: I18n) => i18n._(t`Configure`),
                icon: null,
                exact: true,
                redirect: "branding",
                routes: [
                    {
                        path: "branding",
                        label: (i18n: I18n) => i18n._(t`Branding`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Branding context={"super"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "caches",
                        label: (i18n: I18n) => i18n._(t`Caches`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Caches />
                            </Suspense>
                        ),
                    },
                    {
                        path: "config",
                        label: (i18n: I18n) => i18n._(t`Config`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Config />
                            </Suspense>
                        ),
                    },
                    {
                        path: "libraries",
                        label: (i18n: I18n) => i18n._(t`Libraries`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Libraries />
                            </Suspense>
                        ),
                    },
                    {
                        path: "properties",
                        label: (i18n: I18n) => i18n._(t`Properties`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Properties context={"super"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "screens",
                        label: (i18n: I18n) => i18n._(t`Screens`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Screens context={"super"} />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "develop",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return session.developerMode === true;
                },
                label: (i18n: I18n) => i18n._(t`Develop`),
                icon: null,
                exact: true,
                redirect: "Web-request-debugger",
                routes: [
                    {
                        path: "Web-request-debugger",
                        label: (i18n: I18n) => i18n._(t`Web Request Debugger`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <WebRequestDebugger />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "logs",
                label: (i18n: I18n) => i18n._(t`Logs`),
                icon: null,
                exact: true,
                redirect: "audit-log",
                routes: [
                    {
                        path: "audit-log",
                        label: (i18n: I18n) => i18n._(t`Audit Log`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <AuditLogs context={"super"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "errors",
                        label: (i18n: I18n) => i18n._(t`Errors`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <ErrorLogs bcHasWriteAccess={true} context={"super"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "unreadable-requests",
                        label: (i18n: I18n) => i18n._(t`Unreadable Requests`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UnreadableRequests bcHasWriteAccess={true} context={"super"} />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "debug",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return process.env.NODE_ENV !== "production";
                },
                label: (i18n: I18n) => i18n._(t`Debug`),
                icon: null,
                exact: true,
                redirect: "form",
                routes: [
                    {
                        path: "form",
                        label: (i18n: I18n) => i18n._(t`Form Fields`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <FormField />
                            </Suspense>
                        ),
                    },
                    {
                        path: "button",
                        label: (i18n: I18n) => i18n._(t`Button Demo`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <ButtonDemo />
                            </Suspense>
                        ),
                    },
                    {
                        path: "table",
                        label: (i18n: I18n) => i18n._(t`Table Demo`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <TableDemo />
                            </Suspense>
                        ),
                    },
                    {
                        path: "table2",
                        label: (i18n: I18n) => i18n._(t`Table Demo 2`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <TableDemo2 />
                            </Suspense>
                        ),
                    },
                    {
                        path: "markdown",
                        label: (i18n: I18n) => i18n._(t`Markdown Editor Demo`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <MarkdownEditorDemo />
                            </Suspense>
                        ),
                    },
                    {
                        path: "code",
                        label: (i18n: I18n) => i18n._(t`Code Editor Demo`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <CodeEditorDemo />
                            </Suspense>
                        ),
                    },
                    {
                        path: "template",
                        label: (i18n: I18n) => i18n._(t`Screen Template`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Template />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
];

export default routes;
