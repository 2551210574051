// Import libraries.
import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import { FormatOptions, SearchResult } from "../../../../engine/types";
import { formatAppStatusLabel, formatAppStatusColor } from "types/enums/AppStatus";
import { formatPublishStateLabel, formatPublishStateColor } from "types/enums/PublishState";

// Import components.
import { Typography, MenuItem } from "@material-ui/core";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons.
import NoAppImage from "components/icons/NoAppImage";

// Import utilities.
import StringUtils from "utils/String";

// Import formatters.
import HighlightFormatter from "utils/formatters/Highlight";

interface OWN_PROPS {
    data: SearchResult;

    formatOptions?: FormatOptions | null;

    onResultClick: (result: SearchResult) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class AppResult extends React.PureComponent<PROPS> {
    render() {
        const { classes, data, formatOptions } = this.props;

        const app = data.data;

        return (
            <MenuItem component={"div"} className={classes.result} onClick={() => this.props.onResultClick(data)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ImageWrapper
                        style={{ width: "2.25em", height: "2.25em", marginRight: "0.625em" }}
                        src={!StringUtils.isNullOrEmpty(app?.imageUrl) ? "files/portal/g/" + app?.appId + app?.imageUrl : null}
                        fallback={<NoAppImage />}
                        disableCache={app?.updatedAt || null}
                    />

                    <span className={classes.resultInfo}>
                        <Typography style={{ overflow: "hidden", whiteSpace: "break-spaces", wordBreak: "break-all" }} noWrap>
                            <b>{HighlightFormatter.highlight(`${app?.appName} (${app?.appId}) `, data.searchTerm, formatOptions?.highlight)}</b> by {app?.companyName}
                        </Typography>

                        <span style={{ display: "flex", flexDirection: "row", columnGap: "0.5em", alignItems: "center", fontSize: "0.875em", overflow: "hidden" }}>
                            <Typography style={{ fontWeight: "bold", color: formatPublishStateColor(app?.publishState) }} noWrap>
                                <Trans>
                                    {formatPublishStateLabel(app?.publishState)}({app?.billing_plan_name})
                                </Trans>
                            </Typography>
                            {" - "}
                            <Typography style={{ fontWeight: "bold", color: formatAppStatusColor(app?.app_status) }} noWrap>
                                <Trans>{formatAppStatusLabel(app?.app_status)}</Trans>
                            </Typography>
                        </span>
                    </span>
                </div>
            </MenuItem>
        );
    }
}

const styles = () =>
    createStyles({
        result: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            padding: "0.625em",

            "&:not(:first-of-type)": {
                borderTopStyle: "solid",
                borderTopWidth: "0.0625em",
            },

            "&:hover": {
                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                color: "var(--popup-menu-hover-color, inherit)",
            },
        },
        resultInfo: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",
        },
    });

export default withStyles(styles)(AppResult);
