// Import libraries.
import React, { CSSProperties, ForwardedRef } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import components.
import { Button as MuiButton } from "@material-ui/core";

interface OWN_PROPS {
    id: string;
    type?: "neutral" | "primary" | "secondary" | "semantic-positive-primary" | "semantic-positive-secondary" | "semantic-negative-primary" | "semantic-negative-secondary"; // Defaults to "neutral"
    className?: string;
    style?: CSSProperties;
    startIcon?: React.ReactNode;
    children?: React.ReactNode;
    endIcon?: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    tabIndex?: number;

    [x: string]: any;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const Button = React.forwardRef((props: PROPS, ref: ForwardedRef<any>) => {
    const { classes, id, type, className, style, endIcon, startIcon, children, disabled, onClick, ...otherProps } = props;

    return (
        <MuiButton
            id={"button-" + id}
            ref={ref}
            component={"label"}
            className={classnames({
                [classes.root]: true,
                [type == null ? classes.primary : classes[type]]: true,
                [className ? className : ""]: true,
                [classes.noChildren]: children == null,
            })}
            style={style}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={"outlined"}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (onClick && !disabled) {
                    event.preventDefault();
                    event.stopPropagation();

                    if ((event as any).code != null) {
                        const eventAsKeyboardEvent = event as unknown as React.KeyboardEvent;

                        const modifierPresent = eventAsKeyboardEvent.ctrlKey || eventAsKeyboardEvent.altKey || eventAsKeyboardEvent.shiftKey || eventAsKeyboardEvent.metaKey;

                        // Triggered as a result of keyboard event.
                        if (!modifierPresent && ["Space", "Enter"].includes(eventAsKeyboardEvent.code)) {
                            onClick(event);
                        }
                    }

                    if ((event as any).button != null) {
                        // Triggered as a result of mouse event.
                        onClick(event);
                    }
                }
            }}
            {...otherProps}
        >
            {children}
        </MuiButton>
    );
});

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            minWidth: "unset",
            margin: "0.125em",
            pointerEvents: "all",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
            "&.Mui-disabled": {
                pointerEvents: "all !important",
                "&:hover": {
                    cursor: "not-allowed",
                },
            },
            "& .MuiButton-label": {
                width: "auto",
                justifyContent: "unset",
                marginLeft: "auto",
                marginRight: "auto",
                "& .MuiButton-startIcon": {
                    marginLeft: 0,
                    "& > svg": {
                        fontSize: "inherit",
                    },
                },
                "& .MuiButton-endIcon": {
                    marginRight: 0,
                    "& > svg": {
                        fontSize: "inherit",
                    },
                },
            },
        },
        noChildren: {
            paddingLeft: "0.375em",
            paddingRight: "0.375em",
            "& > .MuiButton-label": {
                minWidth: "1.5em",
                minHeight: "1.5em",
            },
            "& > .MuiButton-label > .MuiButton-startIcon, & > .MuiButton-label > .MuiButton-endIcon": {
                marginLeft: 0,
                marginRight: 0,
            },
        },

        neutral: {
            backgroundColor: "var(--button-neutral-background-color)",
            color: "var(--button-neutral-color)",
            borderColor: "var(--button-neutral-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-neutral-hover-background-color)",
                color: "var(--button-neutral-hover-color)",
                borderColor: "var(--button-neutral-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-neutral-disabled-background-color)",
                color: "var(--button-neutral-disabled-color)",
                borderColor: "var(--button-neutral-disabled-border-color)",
            },
        },
        primary: {
            backgroundColor: "var(--button-primary-background-color)",
            color: "var(--button-primary-color)",
            borderColor: "var(--button-primary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-primary-hover-background-color)",
                color: "var(--button-primary-hover-color)",
                borderColor: "var(--button-primary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-primary-disabled-background-color)",
                color: "var(--button-primary-disabled-color)",
                borderColor: "var(--button-primary-disabled-border-color)",
            },
        },
        secondary: {
            backgroundColor: "var(--button-secondary-background-color)",
            color: "var(--button-secondary-color)",
            borderColor: "var(--button-secondary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-secondary-hover-background-color)",
                color: "var(--button-secondary-hover-color)",
                borderColor: "var(--button-secondary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-secondary-disabled-background-color)",
                color: "var(--button-secondary-disabled-color)",
                borderColor: "var(--button-secondary-disabled-border-color)",
            },
        },
        "semantic-positive-primary": {
            backgroundColor: "var(--button-semantic-positive-primary-background-color)",
            color: "var(--button-semantic-positive-primary-color)",
            borderColor: "var(--button-semantic-positive-primary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-semantic-positive-primary-hover-background-color)",
                color: "var(--button-semantic-positive-primary-hover-color)",
                borderColor: "var(--button-semantic-positive-primary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-semantic-positive-primary-disabled-background-color)",
                color: "var(--button-semantic-positive-primary-disabled-color)",
                borderColor: "var(--button-semantic-positive-primary-disabled-border-color)",
            },
        },
        "semantic-positive-secondary": {
            backgroundColor: "var(--button-semantic-positive-secondary-background-color)",
            color: "var(--button-semantic-positive-secondary-color)",
            borderColor: "var(--button-semantic-positive-secondary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-semantic-positive-secondary-hover-background-color)",
                color: "var(--button-semantic-positive-secondary-hover-color)",
                borderColor: "var(--button-semantic-positive-secondary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-semantic-positive-secondary-disabled-background-color)",
                color: "var(--button-semantic-positive-secondary-disabled-color)",
                borderColor: "var(--button-semantic-positive-secondary-disabled-border-color)",
            },
        },
        "semantic-negative-primary": {
            backgroundColor: "var(--button-semantic-negative-primary-background-color)",
            color: "var(--button-semantic-negative-primary-color)",
            borderColor: "var(--button-semantic-negative-primary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-semantic-negative-primary-hover-background-color)",
                color: "var(--button-semantic-negative-primary-hover-color)",
                borderColor: "var(--button-semantic-negative-primary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-semantic-negative-primary-disabled-background-color)",
                color: "var(--button-semantic-negative-primary-disabled-color)",
                borderColor: "var(--button-semantic-negative-primary-disabled-border-color)",
            },
        },
        "semantic-negative-secondary": {
            backgroundColor: "var(--button-semantic-negative-secondary-background-color)",
            color: "var(--button-semantic-negative-secondary-color)",
            borderColor: "var(--button-semantic-negative-secondary-border-color)",
            "&.MuiButton-outlined:not(.Mui-disabled):hover": {
                backgroundColor: "var(--button-semantic-negative-secondary-hover-background-color)",
                color: "var(--button-semantic-negative-secondary-hover-color)",
                borderColor: "var(--button-semantic-negative-secondary-hover-border-color)",
            },
            "&.MuiButton-outlined.Mui-disabled": {
                backgroundColor: "var(--button-semantic-negative-secondary-disabled-background-color)",
                color: "var(--button-semantic-negative-secondary-disabled-color)",
                borderColor: "var(--button-semantic-negative-secondary-disabled-border-color)",
            },
        },
    });

export const generateButtonStyle = (normal: { background: string; text: string; border: string }, hover: { background: string; text: string; border: string }, disabled: { background: string; text: string; border: string }) => {
    return {
        backgroundColor: normal.background,
        color: normal.text,
        borderColor: normal.border,
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: hover.background,
            color: hover.text,
            borderColor: hover.border,
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: disabled.background,
            color: disabled.text,
            borderColor: disabled.border,
        },
    };
};

export default withStyles(styles)(Button);
