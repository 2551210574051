import { SessionAction } from "store/actions/session";

import Session from "types/common/Session";

const initialState: Session = {
    authType: null,
    email: null,
    isAuthenticated: false,
    portalContext: "default",
    themeMode: "light",
    sessionLength: null,
    sessionTimeout: null,
    sessionKeepAlive: null,
};

const reducer = (state: Session = initialState, action: SessionAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_SESSION":
            newState = Object.assign({}, action.payload);

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
