// Import libraries.
import React, { FunctionComponent } from "react";
import { Theme, makeStyles } from "@material-ui/core";

// Import components.
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// Define the properties accepted by this component.
interface OWN_PROPS {
    children?: React.ReactNode;
    index: any;
    value: any;
}
interface PROPS extends OWN_PROPS {}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "1 1 auto",
        display: "flex",
        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",
        overflow: "hidden",
        "&[hidden]": {
            display: "none",
        },
    },
    inner: {
        flex: "1 1 auto",
        display: "flex",
        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",
        overflow: "auto",
        "& > *": {
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
    },
}));

const TabPanel: FunctionComponent<PROPS> = (props) => {
    const { children, value, index, ...other } = props;

    const classes = useStyles();

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other} className={classes.root}>
            {value === index && <Box className={classes.inner}>{children}</Box>}
        </Typography>
    );
};

export default TabPanel;
