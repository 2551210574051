import Http, { HttpResponse } from "utils/networking/Http";

import User, { processUser } from "types/common/User";
import ApiKeyInfo, { processApiKeyInfo } from "types/models/ApiKeyInfo";
import CredentialInfo, { processCredentialInfo } from "types/models/CredentialInfo";
import TwoFactorType from "types/enums/TwoFactorType";

const getUser = async (email: string, isSuperOverride?: boolean): Promise<User | null> => {
    const params: any = { email, isSuperOverride: isSuperOverride === true };

    const response: HttpResponse = await Http.GET("admin/useradmin/readuser", params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processUser(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const deleteUser = async (profileId: string): Promise<void> => {
    const params: any = {
        profileId,
    };

    const response: HttpResponse = await Http.GET("admin/useradmin/user-delete", params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const unlinkUserFromTeamAsSuperUser = async (profileId: string, teamId: string): Promise<void> => {
    const params: any = {
        profileId,
        teamId,
    };

    const response: HttpResponse = await Http.GET("admin/useradmin/user-unlink-by-super", params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const unlinkUserFromTeamAsTeamAdminUser = async (profileId: string): Promise<void> => {
    const params: any = {
        profileId,
    };

    const response: HttpResponse = await Http.GET("admin/useradmin/user-unlink", params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const updateUserDetails = async (profileId: string, email: string | null, fullName: string | null, language: string | null, country: string | null, timezone: string | null, themeMode: string | null): Promise<void> => {
    const params: any = {
        profileId,
    };

    // We only update those fields whose values are actually supplied (i.e. not null).
    if (email != null) params.email = email;
    if (fullName != null) params.fullName = fullName;
    if (language != null) params.language = language;
    if (country != null) params.country = country;
    if (timezone != null) params.timezone = timezone;
    if (themeMode != null) params.themeMode = themeMode;

    const response: HttpResponse = await Http.POST("admin/useradmin/update-user-details", params, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const updateUserCustomData = async (profileId: string, customData: { [index: string]: any } | null): Promise<void> => {
    const params: any = {
        profileId,
        customData,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/update-user-custom-data", undefined, params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const updateUserPassword = async (profileId: string, currentPassword: string, newPassword: string): Promise<void> => {
    const params: any = {
        profileId,
        currentPassword,
        newPassword,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/update-user-password", undefined, params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const resetUserPassword = async (email: string): Promise<void> => {
    const params: any = {
        email,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/reset-password", params, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const updateUserAccess = async (profileId: string, isTeamAdministrator: boolean, hasApiAccess: boolean, isSuperOverride?: boolean): Promise<void> => {
    const params: any = {
        profileId,
        isTeamAdministrator,
        hasApiAccess,
        isSuperOverride,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/update-user-access", params, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const linkTwoFactorAuthentication = async (type: TwoFactorType, email: string, countryCode: string | null, phoneNumber: string | null): Promise<string | null> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.VERIFY:
            response = await Http.POST(
                "admin/useradmin/totp/factor/create",
                {
                    email,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        case TwoFactorType.AUTHY:
            response = await Http.POST(
                "admin/useradmin/link-tfa-phone",
                {
                    email,
                    countryCode,
                    phoneNumber,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve(response.data.uri || null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const unlinkTwoFactorAuthentication = async (type: TwoFactorType, email: string, sendNotice: boolean): Promise<void> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.VERIFY:
            response = await Http.POST(
                "admin/useradmin/totp/factor/delete",
                {
                    email,
                    sendNotice,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        case TwoFactorType.AUTHY:
            response = await Http.POST(
                "admin/useradmin/unlink-tfa-phone",
                {
                    email,
                    sendNotice,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const sendTwoFactorAuthenticationSMS = async (type: TwoFactorType, email: string): Promise<void> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.AUTHY:
            response = await Http.POST(
                "admin/useradmin/send-tfa-sms",
                {
                    email,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const verifyTwoFactorAuthentication = async (type: TwoFactorType, email: string, token: string): Promise<void> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.VERIFY:
            response = await Http.GET(
                "admin/useradmin/totp/factor/verify",
                {
                    email,
                    token,
                },
                Http.JSON_HEADERS
            );
            break;
        case TwoFactorType.AUTHY:
            response = await Http.GET(
                "admin/useradmin/verify-tfa-token",
                {
                    email,
                    token,
                },
                Http.JSON_HEADERS
            );
            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const clearTwoFactorAuthenticationDevices = async (type: TwoFactorType, email: string): Promise<void> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.AUTHY:
        case TwoFactorType.VERIFY:
            response = await Http.POST(
                "admin/useradmin/reset-tfa-machines",
                {
                    email,
                },
                undefined,
                Http.JSON_HEADERS
            );

            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getApiKey = async (profileId: string, apiKeyId: string, isSuperOverride?: boolean): Promise<ApiKeyInfo | null> => {
    const params: any = {
        profileId,
        apiKeyId,
        isSuperOverride,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/get-api-key", null, params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processApiKeyInfo(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const createApiKey = async (profileId: string, description: string, scope: "superadmin" | "team" | "app" | "user", expiry: "1" | "2" | "3", isSuperOverride?: boolean): Promise<ApiKeyInfo[]> => {
    const params: any = {
        profileId,
        isSuperOverride,
        name: description,
        scope,
        expiry,
        teams: [],
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/add-api-key", null, params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(Array.isArray(response.data.apiKeys) ? response.data.apiKeys.map(processApiKeyInfo) : []);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const deleteApiKey = async (profileId: string, apiKeyId: string, isSuperOverride?: boolean): Promise<ApiKeyInfo[]> => {
    const params: any = {
        profileId,
        apiKeyId,
        isSuperOverride,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/delete-api-key", null, params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(Array.isArray(response.data.apiKeys) ? response.data.apiKeys.map(processApiKeyInfo) : []);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getCredential = async (email: string): Promise<CredentialInfo | null> => {
    const params: any = {
        emailAddress: email,
    };

    const response: HttpResponse = await Http.GET("admin/serveradmin/getTeamMemberCredentialInfoForEmailAddress", params, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processCredentialInfo(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getUser: getUser,
    deleteUser: deleteUser,
    unlinkUserFromTeamAsSuperUser: unlinkUserFromTeamAsSuperUser,
    unlinkUserFromTeamAsTeamAdminUser: unlinkUserFromTeamAsTeamAdminUser,
    updateUserDetails: updateUserDetails,
    updateUserCustomData: updateUserCustomData,
    updateUserPassword: updateUserPassword,
    resetUserPassword: resetUserPassword,
    updateUserAccess: updateUserAccess,
    linkTwoFactorAuthentication: linkTwoFactorAuthentication,
    unlinkTwoFactorAuthentication: unlinkTwoFactorAuthentication,
    sendTwoFactorAuthenticationSMS: sendTwoFactorAuthenticationSMS,
    verifyTwoFactorAuthentication: verifyTwoFactorAuthentication,
    clearTwoFactorAuthenticationDevices: clearTwoFactorAuthenticationDevices,
    getCredential: getCredential,
    getApiKey: getApiKey,
    createApiKey: createApiKey,
    deleteApiKey: deleteApiKey,
};

export default Services;
