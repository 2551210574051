// Import libraries.
import PortalComponent from "framework/PortalComponent";
import { connect } from "react-redux";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import AppInfo from "types/models/AppInfo";
import DialogMode from "types/enums/DialogMode";

// Import components.
import Typography from "@material-ui/core/Typography";
import Button from "components/common/button/Button";
import VerifyAppNameDialog from "components/common/dialog/VerifyAppNameDialog";

// Import icons.
import LockedIcon from "@material-ui/icons/Lock";
import UnlockedIcon from "@material-ui/icons/LockOpen";

interface STATE_PROPS {
    session: Session;
    availableApps: AppInfo[];
}
interface DISPATCH_PROPS {
    toggleLiveLock: (appName?: string | null) => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableApps: state.availableApps,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleLiveLock: (appName?: string | null) => dispatch({ type: "app.toggleLiveLock", payload: { appName } }),
    };
};

interface STATE {
    dialogMode: DialogMode.READ | null;
}

class LiveLock extends PortalComponent<PROPS> {
    state: Readonly<STATE> = {
        dialogMode: null,
    };

    onClick = (event: React.SyntheticEvent<HTMLElement>) => {
        const { session } = this.props;

        if (!session.isLiveLocked) {
            this.props.toggleLiveLock(null);
        } else {
            this.setState({ dialogMode: DialogMode.READ });
        }

        if (event) {
            event.currentTarget.blur();
        }
    };

    handleClose = (submitted?: boolean, appName?: string | null) => {
        this.setState({ dialogMode: null }, () => {
            if (submitted) this.props.toggleLiveLock(appName);
        });
    };

    render() {
        const { location, classes, session, availableApps } = this.props;
        const { dialogMode } = this.state;

        const isAppPath = location.pathname.startsWith("/app");

        const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

        if (!isAppPath || !targetApp || !targetApp.isLive) return null;

        return (
            <div className={classes.root}>
                <Button
                    id={"toggle-live-lock"}
                    type={session.isLiveLocked ? "semantic-positive-primary" : "semantic-negative-primary"}
                    startIcon={session.isLiveLocked ? <LockedIcon style={{ width: "1.5rem", height: "1.5rem" }} /> : <UnlockedIcon style={{ width: "1.5rem", height: "1.5rem" }} />}
                    endIcon={session.isLiveLocked ? <LockedIcon style={{ width: "1.5rem", height: "1.5rem" }} /> : <UnlockedIcon style={{ width: "1.5rem", height: "1.5rem" }} />}
                    onClick={this.onClick}
                >
                    {session.isLiveLocked && (
                        <Typography>
                            <Trans>LOCKED</Trans>
                        </Typography>
                    )}
                    {!session.isLiveLocked && (
                        <Typography>
                            <Trans>UNLOCKED</Trans>
                        </Typography>
                    )}
                </Button>

                {dialogMode === DialogMode.READ && (
                    <VerifyAppNameDialog
                        details={
                            <Trans>
                                Unlock the app <strong>{targetApp.appName}</strong> for editing?
                            </Trans>
                        }
                        appName={targetApp.appName}
                        onClose={this.handleClose}
                    />
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",

            "& > Muibutton-root": {
                width: "100%",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(LiveLock)));
