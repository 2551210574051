import React, { CSSProperties } from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";

import BrokenImage from "components/icons/BrokenImage";
import LoadingProgress from "../widgets/LoadingProgress";

import { encodeQueryParameters } from "utils/networking/Http";

interface PROPS extends WithStyles<typeof styles> {
    style?: CSSProperties;
    src?: React.ReactNode;
    fallback?: React.ReactNode;
    alt?: string | null;
    isLoading?: boolean;
    disableCache?: string | number | boolean | Date | null;
}

interface STATE {
    isSrcError: boolean;
    isFallbackError: boolean;

    timestamp: Date;
}

class ImageWrapper extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        isSrcError: false,
        isFallbackError: false,

        timestamp: new Date(),
    };

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.src !== this.props.src) {
            this.setState({ isSrcError: false, isFallbackError: false, timestamp: new Date() });
        }
    }

    onSrcError = () => {
        this.setState({ isSrcError: true });
    };

    onFallbackError = () => {
        this.setState({ isFallbackError: true });
    };

    render() {
        const { classes, style, src, fallback, alt, isLoading, disableCache } = this.props;
        const { isSrcError, isFallbackError, timestamp } = this.state;

        let image = null;

        if ((!src || (typeof src === "string" && src.trim().length === 0)) && (!fallback || (typeof fallback === "string" && fallback.trim().length === 0))) {
            image = null;
        } else {
            if (isSrcError || isFallbackError) {
                image = <BrokenImage />;
            } else {
                if (!src && fallback) {
                    if (typeof fallback === "string") {
                        const queryParameters =
                            disableCache !== undefined
                                ? (fallback.includes("?") ? "&" : "?") +
                                  encodeQueryParameters({ nocdn: disableCache != null && typeof disableCache === "object" ? (disableCache as Date).getTime() : disableCache != null ? disableCache : timestamp.getTime() })
                                : "";

                        image = <img key={fallback} width={"100%"} height={"100%"} src={fallback + queryParameters} alt={alt || "image-fallback"} onError={() => this.onFallbackError()} />;
                    } else {
                        image = fallback;
                    }
                } else {
                    if (typeof src === "string") {
                        const queryParameters =
                            disableCache !== undefined
                                ? (src.includes("?") ? "&" : "?") +
                                  encodeQueryParameters({ nocdn: disableCache != null && typeof disableCache === "object" ? (disableCache as Date).getTime() : disableCache != null ? disableCache : timestamp.getTime() })
                                : "";

                        image = <img key={src} width={"100%"} height={"100%"} src={src + queryParameters} alt={alt || "image-source"} onError={() => this.onSrcError()} />;
                    } else {
                        image = src;
                    }
                }
            }
        }

        let customStyle = Object.assign(
            {
                width: "4em",
                height: "4em",
                borderStyle: "none",
                borderColor: "inherit",
                borderRadius: "0.25em",
                overflow: "hidden",
            },
            style
        );

        if (isSrcError || isFallbackError) {
            customStyle.width = "auto";
            customStyle.height = "inherit";
            customStyle.maxWidth = style?.width;
            customStyle.maxHeight = style?.height;
        }

        return (
            <span className={classes.root} style={customStyle} data-src={typeof src === "string" ? src : undefined} data-fallback={typeof fallback === "string" ? fallback : undefined}>
                {isLoading ? <LoadingProgress hideLabel={true} /> : image}
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            maxWidth: "100%",
            maxHeight: "100%",

            flex: "0 0 auto",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            "& > *": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
            },
        },
    });

export default withStyles(styles)(ImageWrapper);
