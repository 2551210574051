// Import libraries.
import React from "react";
import { Theme, withStyles, createStyles, WithStyles } from "@material-ui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import { PasswordFieldOptions } from "components/common/form/fields/PasswordField";

// Import components.
import FieldWrapper from "components/common/form/FieldWrapper";

// Import utilities.
import StringUtils from "utils/String";

// Define the properties accepted by this component.
interface OWN_PROPS {
    currentPassword: string | null;
    newPassword: string | null;
    confirmPassword: string | null;
    onChange: (currentPassword: string | null, newPassword: string | null, confirmPassword: string | null) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

class Password extends React.PureComponent<PROPS> {
    handleChange = (name: string, value: any) => {
        const { currentPassword, newPassword, confirmPassword, onChange } = this.props;

        switch (name) {
            case "currentPassword":
                onChange(value, newPassword, confirmPassword);
                break;
            case "newPassword":
                onChange(currentPassword, value, confirmPassword);
                break;
            case "confirmPassword":
                onChange(currentPassword, newPassword, value);
                break;
            default:
            // Unknown field.
        }
    };

    validateNewPassword = () => {
        const { currentPassword, newPassword } = this.props;

        if (currentPassword && currentPassword.trim().length > 0 && newPassword && newPassword.trim().length > 0 && currentPassword === newPassword) {
            return <Trans>New password cannot be the same as current password</Trans>;
        }
    };

    validateConfirmPassword = () => {
        const { newPassword, confirmPassword } = this.props;

        if (newPassword !== confirmPassword) {
            return <Trans>Passwords must match</Trans>;
        }
    };

    render() {
        const { i18n, classes, currentPassword, newPassword, confirmPassword } = this.props;

        return (
            <div className={classes.root}>
                <FieldWrapper
                    type={"password"}
                    name={"currentPassword"}
                    value={currentPassword ? currentPassword : ""}
                    label={<Trans>Current Password</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.handleChange}
                    required={!StringUtils.isNullOrEmpty(newPassword) || !StringUtils.isNullOrEmpty(confirmPassword)}
                    options={
                        {
                            placeholder: i18n._(t`Current Password...`),
                        } as PasswordFieldOptions
                    }
                />

                <FieldWrapper
                    type={"password"}
                    name={"newPassword"}
                    value={newPassword ? newPassword : ""}
                    label={<Trans>New Password</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.handleChange}
                    validate={this.validateNewPassword}
                    required={!StringUtils.isNullOrEmpty(currentPassword) || !StringUtils.isNullOrEmpty(confirmPassword)}
                    options={
                        {
                            placeholder: i18n._(t`New Password...`),
                        } as PasswordFieldOptions
                    }
                />

                <FieldWrapper
                    type={"password"}
                    name={"confirmPassword"}
                    value={confirmPassword ? confirmPassword : ""}
                    label={<Trans>Confirm Password</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.handleChange}
                    validate={this.validateConfirmPassword}
                    required={!StringUtils.isNullOrEmpty(currentPassword) || !StringUtils.isNullOrEmpty(newPassword)}
                    options={
                        {
                            placeholder: i18n._(t`Confirm Password...`),
                        } as PasswordFieldOptions
                    }
                />
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
    });

export default withI18n()(withStyles(styles)(Password));
