import React from "react";
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

/**
 * Represents data associated with a particular Screen.
 *
 * Typically these refer to "primary" screens (i.e. the screen that is associated with a particular PortalRoute).
 *
 * However, we should be able to define additional ScreenData for other things (so long as the ID does not refer to a route path).
 * This would allow data to be assiociated with Dialog content for example or specific sections within a "primary" Screen" (a Card or Table).
 *
 * Eventually, I believe this could also be used for nested routers. I haven't played with nested routers very much but the concept would be
 * the same as the "primary" router (the only caveat being that the id's or path's should NOT overlap, meaning route paths defined by different
 * routers would be expected to be mutually exclusive).
 *
 * - THE ABOVE NESTED-ROUTER SCENARIO WILL REQUIRE MORE THOUGHT AND EXPERIMENTATION (this would be considered advanced usage).
 *     - One thought would be to add a "tag" or "category" to the ScreenData to help organize and find the correct ScreenData.
 *
 * Represented Data:
 *   - id: The unique identifier for the data (typically refers to a route path).
 *   - title: The title of the screen.
 *   - infoBox: Contains data related to the infobox component shown on each Screen.
 *     - text: The actual text that is displayed within the infobox.
 *     - learnMore: The URL used for the "Learn More" button (optional).
 *   - search: Contains data related to the universal search component.
 *     - title: The actual title that is searched against and subsequently displayed in the universal search results.
 */
export interface ScreenData {
    id: string;
    title: (i18n: I18n) => string;
    infobox?: {
        text?: React.ReactNode;
        learnMore?: string | null;
    };
    search?: {
        title?: (i18n: I18n) => string;
    };
    pageKey?: string | null;
}

export const getScreenDataForId = (id: string): ScreenData | null => {
    return allScreens.find((item) => item.id.trim().toLowerCase() === id.trim().toLowerCase()) || null;
};

export const getScreenDataForPageKey = (pageKey: string): ScreenData | null => {
    return allScreens.find((item) => item.pageKey?.trim().toLowerCase() === pageKey.trim().toLowerCase()) || null;
};

export const superScreens: ScreenData[] = [
    {
        id: "/super/dashboard",
        title: (i18n: I18n) => i18n._(t`Super Dashboard`),
        infobox: {
            text: <Trans>View high level system stats and trends.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Dashboard`),
        },
    },
    {
        id: "/super/manage/apps",
        title: (i18n: I18n) => i18n._(t`Manage - Apps`),
        infobox: {
            text: <Trans>Monitor the performance of apps using the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Apps`),
        },
    },
    {
        id: "/super/manage/teams",
        title: (i18n: I18n) => i18n._(t`Manage - Teams`),
        infobox: {
            text: <Trans>View and manage the list of teams. Note that you can delete whole teams here. Be careful!</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Teams`),
        },
    },
    {
        id: "/super/manage/users",
        title: (i18n: I18n) => i18n._(t`Manage - Users`),
        infobox: {
            text: <Trans>View and manage the list of users of the system (i.e. developers / team members). Note that Super Users are managed here as well.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Users`),
        },
    },
    {
        id: "/super/configure/branding",
        title: (i18n: I18n) => i18n._(t`Configure - Branding`),
        infobox: {
            text: <Trans>Customize the global look and feel of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Branding`),
        },
    },
    {
        id: "/super/configure/caches",
        title: (i18n: I18n) => i18n._(t`Configure - Caches`),
        infobox: {
            text: <Trans>Use with caution - the caches increase performance of the API Servers.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Caches`),
        },
    },
    {
        id: "/super/configure/config",
        title: (i18n: I18n) => i18n._(t`Configure - Config`),
        infobox: {
            text: <Trans>Used to view the low-level configuration of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Config`),
        },
    },
    {
        id: "/super/configure/libraries",
        title: (i18n: I18n) => i18n._(t`Configure - Libraries`),
        infobox: {
            text: <Trans>Customize the list of client libraries that are available to download.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Libraries`),
        },
    },
    {
        id: "/super/configure/properties",
        title: (i18n: I18n) => i18n._(t`Configure - Properties`),
        infobox: {
            text: <Trans>Customize the behaviour of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Properties`),
        },
    },
    {
        id: "/super/configure/screens",
        title: (i18n: I18n) => i18n._(t`Configure - Screens`),
        infobox: {
            text: <Trans>Used to enable/disable select screens globally across the system. You can also override screens on an app-by-app basis.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Screens`),
        },
    },
    {
        id: "/super/develop/web-request-debugger",
        title: (i18n: I18n) => i18n._(t`Develop - Web Request Debugger`),
        infobox: {
            text: <Trans>Used to call / debug server requests before the web screens have been created.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Develop > Web Request Debugger > Screens`),
        },
    },
    {
        id: "/super/logs/audit-log",
        title: (i18n: I18n) => i18n._(t`Logs - Audit Log`),
        infobox: {
            text: (
                <Trans>
                    The audit log helps you to keep track of changes to the meta-data of system. <b>Actions that update data are shown in bold.</b>
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Audit Log`),
        },
    },
    {
        id: "/super/logs/errors",
        title: (i18n: I18n) => i18n._(t`Logs - Errors`),
        infobox: {
            text: <Trans>Displays a log of errors and warnings recorded on the system across all applications.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Errors`),
        },
    },
    {
        id: "/super/logs/unreadable-requests",
        title: (i18n: I18n) => i18n._(t`Logs - Unreadable Requests`),
        infobox: {
            text: (
                <Trans>
                    These are requests received that the system is unable to act upon. Usually caused by networking errors (we didn't receive the full request), bad serialization of JSON objects or security issues (bad packet id, secret, etc.).
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Unreadable Requests`),
        },
    },
];

export const teamScreens: ScreenData[] = [
    {
        id: "/team/dashboard",
        title: (i18n: I18n) => i18n._(t`Team Dashboard`),
        infobox: {
            text: <Trans>View high level team stats and trends.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Dashboard`),
        },
    },
    {
        id: "/team/manage/apps",
        title: (i18n: I18n) => i18n._(t`Manage - Apps`),
        infobox: {
            text: <Trans>Manage the list of Applications in your account. This is also where you create new Apps.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Manage > Apps`),
        },
    },
    {
        id: "/team/manage/members",
        title: (i18n: I18n) => i18n._(t`Manage - Members`),
        infobox: {
            text: <Trans>Manage the list of team members in your account.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Manage > Members`),
        },
    },
    {
        id: "/team/security/permissions",
        title: (i18n: I18n) => i18n._(t`Security - Permissions`),
        infobox: {
            text: <Trans>This section allows you to manage the role definitions and assignments on a per-app basis for your team. Note that the system currently supports only pre-defined roles.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Security > Permissions`),
        },
    },
    {
        id: "/team/security/audit-log",
        title: (i18n: I18n) => i18n._(t`Security - Audit Log`),
        infobox: {
            text: (
                <Trans>
                    The audit log helps you to keep track of changes to the meta-data of your apps. <b>Actions that update data are shown in bold.</b>
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Security > Audit Log`),
        },
    },
    {
        id: "/team/setup/team-info",
        title: (i18n: I18n) => i18n._(t`Setup - Team Info`),
        infobox: {
            text: <Trans>Edit the key contact and billing information for your Team account.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Setup > Team Info`),
        },
    },
    {
        id: "/team/setup/branding",
        title: (i18n: I18n) => i18n._(t`Setup - Branding`),
        infobox: {
            text: <Trans>Customize the look and feel of the system for individual teams.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Setup > Branding`),
        },
    },
];

export const appScreens: ScreenData[] = [
    {
        id: "/app/dashboard",
        title: (i18n: I18n) => i18n._(t`App Dashboard`),
        infobox: {
            text: (
                <Trans>
                    Welcome! <br />
                    This is your <strong>App’s Home Page</strong> - it gives you quick links to everything you need to get up and running!
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`APP > Dashboard`),
        },
    },
    {
        id: "/app/design/core-app-info/application-ids",
        title: (i18n: I18n) => i18n._(t`Core App Info - Application IDs`),
        infobox: {
            text: (
                <Trans>
                    Key application identifiers. These identifiers are used when connecting your <b>client</b> to the platform, or for allowing the platform to connect to <b>third party services</b>.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3239903-design-core-app-info-application-ids",
        },
        pageKey: "DESIGN_APPIDS",
    },
    {
        id: "/app/design/core-app-info/advanced-settings",
        title: (i18n: I18n) => i18n._(t`Core App Info - Advanced Settings`),
        infobox: {
            text: <Trans>These settings control key aspects of the platform's behaviour. Be careful!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272441-design-core-app-info-advanced-settings",
        },
        pageKey: "DESIGN_ADVANCED",
    },
    {
        id: "/app/design/core-app-info/auto-delete-users",
        title: (i18n: I18n) => i18n._(t`Core App Info - Auto-Delete Users`),
        infobox: {
            text: (
                <Trans>
                    Dormant users are those that haven't logged into the app in the past twelve months. These users provide little to no value, and can add significant storage costs. The feature allows the developer to automatically delete dormant
                    users to avoid additional storage costs.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/4088633-design-core-app-info-auto-delete-users",
        },
        pageKey: "DESIGN_AUTO_DELETE",
    },
    {
        id: "/app/design/core-app-info/archive-settings",
        title: (i18n: I18n) => i18n._(t`Core App Info - Archive Settings`),
        infobox: {
            text: <Trans>Save a temporary backup of a user account before deleting it for any reason. Account is saved in custom entities collection of your choice. A Plus Plan is required.</Trans>,
            learnMore: null,
        },
        pageKey: "DESIGN_USER_ARCHIVE",
    },
    {
        id: "/app/design/core-app-info/localization",
        title: (i18n: I18n) => i18n._(t`Core App Info - Localization`),
        infobox: {
            text: <Trans>Choose the languages that your application supports here - as well as the default language to use when communicating with your users (should their native language not be supported).</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272451-design-core-app-info-localization",
        },
        pageKey: "DESIGN_LOCALIZATION",
    },
    {
        id: "/app/design/core-app-info/peer-publish",
        title: (i18n: I18n) => i18n._(t`Core App Info - Peer Publish Settings`),
        infobox: {
            text: <Trans>Publishing an app as a Peer Service allows other apps to utilize its features as part of their own implementation.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272446-design-core-app-info-peer-publish",
        },
        pageKey: "DESIGN_PEER_PUBLISH",
    },
    {
        id: "/app/design/core-app-info/platforms",
        title: (i18n: I18n) => i18n._(t`Core App Info - Platforms`),
        infobox: {
            text: (
                <Trans>
                    The platform performs two sets of version checks when your client connects. The first checks the version of the client library being used - and ensures that it is compatible. The second allows the developer to specify a minimum
                    version of their client application itself (ensuring that users are forced to upgrade obsolete versions of your client application).
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3250723-design-core-app-info-platforms",
        },
        pageKey: "DESIGN_PLATFORMS",
    },
    {
        id: "/app/design/core-app-info/security",
        title: (i18n: I18n) => i18n._(t`Core App Info - Security`),
        infobox: {
            text: <Trans>This screen allows devs to customize the security of their apps.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/6060444-braincloud-app-security-settings",
        },
        pageKey: "DESIGN_SECURITY",
    },
    {
        id: "/app/design/admin-tools/cache",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Application Cache`),
        infobox: {
            text: <Trans>App meta-data is cached on the api servers to improve performance. It updates automatically when the app configuration changes. In rare cimcumstances, refreshing the cache manually may fix ununsual errors.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_APP_CACHE",
    },
    {
        id: "/app/design/admin-tools/checkpoints",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Checkpoints`),
        infobox: {
            text: (
                <Trans>
                    Snapshots of your app's meta-data are created each time your app's configuration is updated via a deploy or a config import. You can restore your app's metadata to its previous state by restoring a checkpoint. Use with caution.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_CHECKPOINTS",
    },
    {
        id: "/app/design/admin-tools/configuration-data",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Configuration Data`),
        infobox: {
            text: <Trans>These utilities can be used to export or import the meta-data of your app. Use with caution.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_CONFIG_DATA",
    },
    {
        id: "/app/design/admin-tools/data-deletion",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Data Deletion (Use with Caution!)`),
        infobox: {
            text: <Trans>These utilities are normally used during development to bulk delete accounts. Use with caution.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_DATA_DELETION",
    },
    {
        id: "/app/design/admin-tools/deployment",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Deployment`),
        infobox: {
            text: (
                <Trans>
                    Deployment is the process used to push the configuration of one brainCloud app to another. It is normally used to propagate an app configuration through stages: development to stagings to production, etc. Use with caution.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_DEPLOYMENT",
    },
    {
        id: "/app/design/admin-tools/legacy-files-transfer",
        title: (i18n: I18n) => i18n._(t`Admin Tools - Legacy Files Transfer`),
        infobox: {
            text: <Trans>These features are used to import and export legacy files. Use with caution.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272449-design-core-app-info-admin-tools",
        },
        pageKey: "DESIGN_ADMIN_LEGACY_FILES",
    },
    {
        id: "/app/design/authentication/email-authentication",
        title: (i18n: I18n) => i18n._(t`Authentication - Email`),
        infobox: {
            text: (
                <Trans>
                    The system automates support for email-based user authentication. If enabled, the system will automatically send verification emails to new signups. The developer can choose to automatically reject users who have not verified
                    their email address. This page also allows the email templates to be customized and/or localized.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272452-design-authentication-email-authentication",
        },
        pageKey: "DESIGN_AUTH_EMAIL",
    },
    {
        id: "/app/design/authentication/external",
        title: (i18n: I18n) => i18n._(t`Authentication - External Identity`),
        infobox: {
            text: <Trans>Allows the app to use external directories for authentication. Choose a name for the external identity type, and link it to a Cloud Code script to be run when authenticating.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272462-design-authentication-external",
        },
        pageKey: "DESIGN_AUTH_EXTERNAL",
    },
    {
        id: "/app/design/authentication/passwords",
        title: (i18n: I18n) => i18n._(t`Authentication - Passwords`),
        infobox: {
            text: (
                <Trans>This feature allows your app to enforce minimum password standards. These settings are enforced when passwords are initially created and/or reset. Existing passwords in use are not affected by changes to these settings.</Trans>
            ),
            learnMore: null,
        },
        pageKey: "DESIGN_AUTH_PASSWORDS",
    },
    {
        id: "/app/design/authentication/shared-accounts",
        title: (i18n: I18n) => i18n._(t`Authentication - Shared Accounts`),
        infobox: {
            text: (
                <Trans>
                    Shared Accounts allow the profiles from one app (the parent) to be used as authentication for a second (child) app. This allows developers to share parent accounts across apps. Parent apps must declare that they "can be parents" -
                    and specify a logical name for their parent level (i.e. "Master", etc.). For more information, see Shared Accounts in the API docs.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272464-design-authentication-shared-accounts",
        },
        pageKey: "DESIGN_AUTH_SHARED",
    },
    {
        id: "/app/design/cloud-code/api-explorer",
        title: (i18n: I18n) => i18n._(t`Cloud Code - API Explorer`),
        infobox: {
            text: (
                <Trans>
                    The API explorer provides a quick way to experiment with the Client APIs, and even your own scripts. To begin, start by running the Authenticate method - and then access the rest of the API from there. API calls with a special
                    badge on the Run button are only callable from Cloud Code.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3251954-design-cloud-code-api-explorer",
        },
        pageKey: "DESIGN_API_EXPLORER",
    },
    {
        id: "/app/design/cloud-code/scripts",
        title: (i18n: I18n) => i18n._(t`Cloud Code - Scripts`),
        infobox: {
            text: (
                <Trans>
                    Create and edit JavaScript-based Cloud Code scripts. Scripts can be organized into folders. You can import and export individual scripts, the entire library, or a subset of scripts by folder. Use the Action menu for bulk
                    operations.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/5067537-design-cloud-code-scripts",
        },
        pageKey: "DESIGN_SCRIPT_FOLDERS",
    },
    {
        id: "/app/design/cloud-code/api-blocking",
        title: (i18n: I18n) => i18n._(t`Cloud Code - API Blocking`),
        infobox: {
            text: <Trans>Allows developers to block specified client API calls - making these calls only available via cloud code scripts.</Trans>,
            learnMore: null,
        },
        pageKey: "DESIGN_API_BLOCKING",
    },
    {
        id: "/app/design/cloud-code/api-hooks",
        title: (i18n: I18n) => i18n._(t`Cloud Code - API Hooks`),
        infobox: {
            text: <Trans>This screen allows you to configure custom Cloud Code scripts to run immediately before (pre) or after (post) the core Client API methods.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272482-design-cloud-code-api-hooks",
        },
        pageKey: "DESIGN_API_HOOKS",
    },
    {
        id: "/app/design/cloud-code/web-services",
        title: (i18n: I18n) => i18n._(t`Cloud Code - Web Services Whitelist`),
        infobox: {
            text: <Trans>You must declare *any* external services that your cloud code scripts would like to use. Provide a short service code to identity the service, together with the root web url of the service endpoint.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272483-design-cloud-code-web-services",
        },
        pageKey: "DESIGN_WEB_SERVICES",
    },
    {
        id: "/app/design/cloud-code/s2s-explorer",
        title: (i18n: I18n) => i18n._(t`Cloud Code - S2S Explorer`),
        infobox: {
            text: <Trans>Used to test the S2S API and custom scripts from the perspective of an external server.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272486-design-cloud-code-s2s-explorer",
        },
        pageKey: "DESIGN_S2S_EXPLORER",
    },
    {
        id: "/app/design/cloud-code/web-hooks",
        title: (i18n: I18n) => i18n._(t`Cloud Code - WebHooks`),
        infobox: {
            text: <Trans>Configure webhooks, callable by external servers, that trigger specific Cloud Code scripts.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3978282-use-webhook-with-webhookspy",
        },
        pageKey: "DESIGN_WEB_HOOKS",
    },
    {
        id: "/app/design/cloud-data/custom-entities",
        title: (i18n: I18n) => i18n._(t`Cloud Data - Custom Entity Collections`),
        infobox: {
            text: (
                <Trans>
                    More advanced than both Global and User Entities, Custom Entities provide high performance access to larger, richer datasets. Define custom indexes for faster look-ups. Enable the migration option to automatically migrate entities
                    between apps during deployment.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3754150-custom-entities-a-scalable-and-flexible-app-data-storage-and-querying-solution",
        },
        pageKey: "DESIGN_CUSTOM_ENTITIES",
    },
    {
        id: "/app/design/cloud-data/global-properties",
        title: (i18n: I18n) => i18n._(t`Cloud Data - Global Properties`),
        infobox: {
            text: <Trans>Use Global Properties to configure the key parameters for your app that you want to be able to easily tweak server-side.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272595-design-custom-config-global-properties",
        },
        pageKey: "DESIGN_GLOBAL_PROPERTIES",
    },
    {
        id: "/app/design/cloud-data/global-statistics",
        title: (i18n: I18n) => i18n._(t`Cloud Data - Global Statistics`),
        infobox: {
            text: <Trans>Global [or App] Statistics are similar to Player Stats, except that they are stored centrally across all of an app's users.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272716-design-statistics-rules-global-statistics",
        },
        pageKey: "DESIGN_GLOBAL_STATS",
    },
    {
        id: "/app/design/cloud-data/user-statistics",
        title: (i18n: I18n) => i18n._(t`Cloud Data - User Statistics`),
        infobox: {
            text: (
                <Trans>
                    Player [or User] Statistics are used to track simple statistics that relate to a single user. Player stats are stored on the server in key + value pairs, and are accessed via a custom set of operations for maximum performance and
                    concurrency safety. Player Stats are also the key building blocks of the platform's Gamification features.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272715-design-statistics-rules-user-statistics",
        },
        pageKey: "DESIGN_USER_STATS",
    },
    {
        id: "/app/design/files/global-files",
        title: (i18n: I18n) => i18n._(t`Files - Global Files`),
        infobox: {
            text: (
                <Trans>
                    The new Global Files service provides a flexible repository for organizing files that are globally available to all users of an app. To interact with these files from the Client Libraries or S2S, be sure to use the new Global
                    Files V3 API.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/4261172-design-custom-config-global-files",
        },
        pageKey: "DESIGN_GLOBAL_FILES",
    },
    {
        id: "/app/design/files/legacy-files",
        title: (i18n: I18n) => i18n._(t`Files - Legacy Files`),
        infobox: {
            text: <Trans>Important: The Files service is a legacy service that is being replaced by the new Global Files service. It is recommended that all apps move to the new Global Files service instead.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272840-design-custom-config-files",
        },
        pageKey: "DESIGN_FILES",
    },
    {
        id: "/app/design/gamification/achievements",
        title: (i18n: I18n) => i18n._(t`Gamification - Achievements`),
        infobox: {
            text: <Trans>Reward your players with Achievements. Achievements can be triggered directly from the client via API calls, or can be awarded automatically by the server by configuring Milestones and Level Up rewards.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272611-design-gamification-achievements",
        },
        pageKey: "DESIGN_ACHIEVEMENTS",
    },
    {
        id: "/app/design/gamification/milestones",
        title: (i18n: I18n) => i18n._(t`Gamification - Milestones`),
        infobox: {
            text: (
                <Trans>
                    Milestones are used to automatically trigger player rewards, such as virtual currencies, achievements, etc. Milestones are configured to watch player statistics values, and trigger automatically when specified thresholds are
                    exceeded.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272612-design-gamification-milestones",
        },
        pageKey: "DESIGN_MILESTONES",
    },
    {
        id: "/app/design/gamification/quests",
        title: (i18n: I18n) => i18n._(t`Gamification - Quests`),
        infobox: {
            text: <Trans>Quests are used to represent more complex (compound) milestone conditions. A quest consists of multiple tasks (milestones) that must be complete before the reward is earned</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272614-design-gamification-quests",
        },
        pageKey: "DESIGN_QUESTS",
    },
    {
        id: "/app/design/gamification/statistics-events",
        title: (i18n: I18n) => i18n._(t`Gamification - Statistics Events`),
        infobox: {
            text: (
                <Trans>
                    Stats Events are a powerful design-level abstraction for manipulating Player Statistics. Think of them as macros, which when triggered, cause changes to multiple player stats to be triggered at once. Employing Stats Events in your
                    application allows your coders to work at a higher level, triggering stats events, while your designer is free to determine and tune the individual stats themselves.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272654-design-gamification-statistic-events",
        },
        pageKey: "DESIGN_STATS_EVENTS",
    },
    {
        id: "/app/design/gamification/xp-levels",
        title: (i18n: I18n) => i18n._(t`Gamification - XP Levels`),
        infobox: {
            text: <Trans>Configure the XP Levels and thresholds for your experience - including rewards and achievements for Levelling Up. Also set the overall behaviour of the XP system.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272602-design-gamification-xp-levels",
        },
        pageKey: "DESIGN_XP_LEVELS",
    },
    {
        id: "/app/design/groups/group-types",
        title: (i18n: I18n) => i18n._(t`Groups - Group Types`),
        infobox: {
            text: (
                <Trans>
                    Groups are used to associate collections of users together - useful for representing Organizations in apps, and Clans and Guilds in games. An application can support multiple group types, which are configured here. Once the types
                    have been defined, Groups can then be created dynamically using the Groups API.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272656-design-groups-group-types",
        },
        pageKey: "DESIGN_GROUP_TYPES",
    },
    {
        id: "/app/design/integrations/blockchain",
        title: (i18n: I18n) => i18n._(t`Integrations - Blockchain`),
        infobox: {
            text: <Trans>This section allows you to define blockchain configurations that your app can interact with.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/4464771-design-integrations-blockchain",
        },
        pageKey: "DESIGN_MANAGE_BLOCKCHAINS",
    },
    {
        id: "/app/design/integrations/manage-integrations",
        title: (i18n: I18n) => i18n._(t`Integrations - Manage Integrations`),
        infobox: {
            text: (
                <Trans>
                    The platform has been designed from the very beginning to be a repository and activity stream for information about your app and its community of users. Integrating with third party systems is key to leveraging the value of this
                    data, and unlocking the potential of your apps. This section allows you to configure the third party systems that your apps interact with.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272657-design-integrations-manage-integrations",
        },
        pageKey: "DESIGN_MANAGE_INTEGRATIONS",
    },
    {
        id: "/app/design/integrations/peer-services",
        title: (i18n: I18n) => i18n._(t`Integrations - Peer Services`),
        infobox: {
            text: <Trans>Peers are services that have been developed by 3rd parties, and are made available within the platform ecosystem.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272658-design-integrations-peer-services",
        },
        pageKey: "DESIGN_MANAGE_PEER_SERVICES",
    },
    {
        id: "/app/design/leaderboards/division-sets",
        title: (i18n: I18n) => i18n._(t`Leaderboards - Division Sets`),
        infobox: {
            text: (
                <Trans>
                    Divisions break tournaments into smaller competitions for greater engagement. It is more fun to compete with one hundred players than one million! Division Sets define the rules used to create individual Division Instances.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272671-design-leaderboards-division-sets",
        },
        pageKey: "DESIGN_DIVISION_SETS",
    },
    {
        id: "/app/design/leaderboards/leaderboard-configs",
        title: (i18n: I18n) => i18n._(t`Leaderboards - Leaderboard Config`),
        infobox: {
            text: <Trans>Configure your leaderboards. All leaderboards can be accessed either socially (just friends) or globally (all entries). Leaderboards can be set to reset daily, weekly, monthly, yearly or never.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272666-design-leaderboards-leaderboard-configs",
        },
        pageKey: "DESIGN_LEADERBOARD_CONFIG",
    },
    {
        id: "/app/design/leaderboards/pacers",
        title: (i18n: I18n) => i18n._(t`Leaderboards - Pacers`),
        infobox: {
            text: (
                <Trans>
                    Pacers are pre-set leaderboard scores that allow players without friends to have someone to compete against. You define a global pacer list here, and then configure which pacers should appear in which leaderboards via the
                    individual leaderboard configuration.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272674-design-leaderboards-pacers",
        },
        pageKey: "DESIGN_PACERS",
    },
    {
        id: "/app/design/leaderboards/tournament-templates",
        title: (i18n: I18n) => i18n._(t`Leaderboards - Tournament Templates`),
        infobox: {
            text: (
                <Trans>
                    The Tournament System allows you to easily create global tournaments (based on leaderboards) that reward users prizes (via currency, xp, achievements and more) based on their tournament performance. The tournament rules (entry
                    fee, rank + prize rules, etc.) are defined here. To use, you must then attach the tournament to one of more leaderboards (from the leaderboard config screen).
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3272667-design-leaderboards-tournament-templates",
        },
        pageKey: "DESIGN_TOURNAMENT_CONFIG",
    },
    {
        id: "/app/design/marketplace/item-catalog",
        title: (i18n: I18n) => i18n._(t`Marketplace - Item Catalog`),
        infobox: {
            text: (
                <Trans>
                    Item Management is used to manage a collection of in-app item definitions that users can buy, sell or trade (using a virtual currency). This section allows you to manage the catalog of item definitions associated with your app.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3281673-design-item-management-catalog",
        },
        pageKey: "DESIGN_ITEMMGMT_CATALOG",
    },
    {
        id: "/app/design/marketplace/products",
        title: (i18n: I18n) => i18n._(t`Marketplace - Products`),
        infobox: {
            text: (
                <Trans>
                    Products are items purchasable via IAP (In-App Purchases). Create a separate product for each item (e.g. bundle of currency) that you intend to sell. Every product can (and should) be pre-configured with multiple price points. The
                    active price point can be changed using Promotions.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272677-design-marketplace-products",
        },
        pageKey: "DESIGN_PRODUCTS",
    },
    {
        id: "/app/design/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`Marketplace - Redemption Codes`),
        infobox: {
            text: (
                <Trans>
                    The redemption system allows apps to support an inventory of codes that can be redeemed by users to unlock rewards and/or content in the app. Each code can be used by a single user, only once. There may be multiple code
                    inventories (controlled by the Code Types list). Uploading of code inventory is currently a manual process - contact support for assistance. There are also debug operations to reset codes to their initial state, and/or delete the
                    code inventory.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272678-design-marketplace-redemption-codes",
        },
        pageKey: "DESIGN_REDEMPTION_CODES",
    },
    {
        id: "/app/design/marketplace/virtual-currencies",
        title: (i18n: I18n) => i18n._(t`Marketplace - Virtual Currencies`),
        infobox: {
            text: <Trans>Configure the virtual currencies for your app here. Coins, bars, hearts, rupees - we accept all denominations!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272676-design-marketplace-virtual-currencies",
        },
        pageKey: "DESIGN_VIRTUAL_CURRENCIES",
    },
    {
        id: "/app/design/messaging/chat",
        title: (i18n: I18n) => i18n._(t`Messaging - Configure Chat`),
        infobox: {
            text: (
                <Trans>
                    Chat allows end-users to communicate with each other in realtime. All communication is directed to channels, and pushed to participating users in realtime via RTT. A rolling history of chat messages is maintained per channel.
                    These settings configure the maximum sizes of chat messages and the size of history to be maintained. Pro-tip: set limits as low as possible to minimize usage costs. This feature requires RTT, which can be enabled via Advanced
                    Settings. Profanity filtering requires WebPurify integration, which can be configured on the Manage Integrations page.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272679-design-messaging-chat",
        },
        pageKey: "DESIGN_MESSAGING_CHAT",
    },
    {
        id: "/app/design/messaging/chat-channels",
        title: (i18n: I18n) => i18n._(t`Messaging - Chat Channels`),
        infobox: {
            text: <Trans>Chat supports both global channels and group channels. Global channels are available to all members of an app, and must be configured here. Group channels are automatically provisioned for each group.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272685-design-messaging-chat-channels",
        },
        pageKey: "DESIGN_MESSAGING_CHAT_GLOBAL",
    },
    {
        id: "/app/design/messaging/messaging",
        title: (i18n: I18n) => i18n._(t`Messaging - Configure Messaging`),
        infobox: {
            text: (
                <Trans>
                    Messaging is used for sending directed messages between end-users. The paradigm is similar to email, but stays within your app. Online users are informed of new messages via RTT. A rolling history of messages is maintained per
                    user. These settings configure the maximum sizes of chat messages and the size of history to be maintained. Pro-tip: set limits as low as possible to minimize usage costs.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272687-design-messaging-messaging",
        },
        pageKey: "DESIGN_MESSAGING_MESSAGES",
    },
    {
        id: "/app/design/messaging/presence",
        title: (i18n: I18n) => i18n._(t`Messaging - Presence Config`),
        infobox: {
            text: <Trans>Presence uses RTT to detect and communicate the online status</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272698-design-messaging-presence",
        },
        pageKey: "DESIGN_MESSAGING_PRESENCE",
    },
    {
        id: "/app/design/multiplayer/lobbies",
        title: (i18n: I18n) => i18n._(t`Multiplayer - Configure Lobbies`),
        infobox: {
            text: (
                <Trans>
                    Lobbies are used to gather participants together in preparation for shared multi-user experiences. Lobby settings determine the composition of a lobby, the rules for lobby progression, and the configuration data to send to the
                    experience at launch. Requires RTT, which can be enabled via Advanced Settings.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272699-design-multiplayer-lobbies",
        },
        pageKey: "DESIGN_LOBBIES",
    },
    {
        id: "/app/design/multiplayer/matchmaking",
        title: (i18n: I18n) => i18n._(t`Multiplayer - Matchmaking`),
        infobox: {
            text: (
                <Trans>
                    This page is used to configure the behaviour for Offline Matchmaking. The default Matchmaking algorithm is tuned via the specified parameters - plus you can optionally provide a Cloud Code script to be used to filter potential
                    candidates.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272700-design-multiplayer-matchmaking",
        },
        pageKey: "DESIGN_MATCHMAKING",
    },
    {
        id: "/app/design/notifications/settings",
        title: (i18n: I18n) => i18n._(t`Notifications - Settings`),
        infobox: {
            text: <Trans>Configure your app for iOS and Android push notifications.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272701-design-notifications-settings",
        },
        pageKey: "DESIGN_PUSH_SETTINGS",
    },
    {
        id: "/app/design/notifications/templates",
        title: (i18n: I18n) => i18n._(t`Notifications - Templates`),
        infobox: {
            text: <Trans>Define push notification templates to be used to communicate with your user community.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272703-design-notifications-templates",
        },
        pageKey: "DESIGN_TEMPLATES",
    },
    {
        id: "/app/design/promotions/automated",
        title: (i18n: I18n) => i18n._(t`Promotions - Automated Promotions`),
        infobox: {
            text: <Trans>Auto Promotions are automatically triggered if a user (player) enters a defined Segment. Segments and Auto Promotions are refreshed daily for activation.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272707-design-promotions-automated",
        },
        pageKey: "DESIGN_AUTO_PROMOTIONS",
    },
    {
        id: "/app/design/promotions/scheduled",
        title: (i18n: I18n) => i18n._(t`Promotions - Scheduled Promotions`),
        infobox: {
            text: <Trans>Scheduled Promotions are used to automatically set sale pricing of your products for particular calendar date range.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/3272705-design-promotions-scheduled",
        },
        pageKey: "DESIGN_SCHEDULED_PROMOTIONS",
    },
    {
        id: "/app/design/segmentation/segments",
        title: (i18n: I18n) => i18n._(t`Segmentation - Segments`),
        infobox: {
            text: <Trans>Segments are used to identify subsets of your user (player) population. Segments are useful for filtering Push Notifications and Scheduled Promotions, as well as for triggering Auto Promotions.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272709-design-segmentation-segments",
        },
        pageKey: "DESIGN_SEGMENTS",
    },
    {
        id: "/app/design/servers/my-servers",
        title: (i18n: I18n) => i18n._(t`Servers - My Servers`),
        infobox: {
            text: (
                <Trans>
                    This section is used to configure custom servers that will be <b>hosted</b> by and/or closely interact with your app via the <b>S2S API. Custom Servers</b> are simple external servers that require S2S access. <b>Relay Servers</b>{" "}
                    are hosted specifically for your app and launched from Lobbies. <b>Room Servers</b> are custom servers hosted for your app and launched from Lobbies. You provide the docker image with the server software to run.{" "}
                    <b>Room Server Managers</b> are external servers that can act as a Room Server Manager for Lobby Services.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/3089652-web-services-and-my-servers",
        },
        pageKey: "DESIGN_S2S_CONFIG",
    },
    {
        id: "/app/design/super/screens",
        title: (i18n: I18n) => i18n._(t`Super - Screens`),
        infobox: {
            text: <Trans>Used to enable/disable select screens globally across the system. You can also override screens on an app-by-app basis.</Trans>,
        },
        pageKey: "DESIGN_BRAINCLOUD_SCREENS",
    },
    {
        id: "/app/design/super/property-overrides",
        title: (i18n: I18n) => i18n._(t`Super - System Property Overrides`),
        infobox: {
            text: <Trans>This screen allows Super-Users to override the default System Property value for the selected app.</Trans>,
        },
        pageKey: "DESIGN_BRAINCLOUD_PROPERTIES",
    },
    {
        id: "/app/global/global-data/custom-entities",
        title: (i18n: I18n) => i18n._(t`Global Data - Custom Entities `),
        infobox: {
            text: <Trans>Manage the application's Custom Entities. Click on the name of the collection to manage.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272720-monitoring-global-monitoring-custom-entities",
        },
        pageKey: "MONITOR_CUSTOM_ENTITIES",
    },
    {
        id: "/app/global/global-data/global-entities",
        title: (i18n: I18n) => i18n._(t`Global Data - Global Entities`),
        infobox: {
            text: <Trans>Displays a list of the apps Global Entities, filterable by type.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/4592452-monitoring-global-monitoring-global-entities",
        },
        pageKey: "MONITOR_GLOBAL_ENTITIES",
    },
    {
        id: "/app/global/global-data/global-statistics",
        title: (i18n: I18n) => i18n._(t`Global Data - Global Statistics`),
        infobox: {
            text: <Trans>View (and potentially edit) the current values of global statistics. Be careful - this data is live!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272719-monitoring-global-monitoring-statistics",
        },
        pageKey: "MONITOR_GLOBAL_STATS",
    },
    {
        id: "/app/global/leaderboards/leaderboards",
        title: (i18n: I18n) => i18n._(t`Leaderboards - Leaderboards`),
        infobox: {
            text: <Trans>View and manage your game's leaderboards during development and production. Quickly view players or delete entries using the Action menu.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272757-monitoring-global-monitoring-leaderboards",
        },
        pageKey: "MONITOR_LEADERBOARDS",
    },
    {
        id: "/app/global/logs/job-queue",
        title: (i18n: I18n) => i18n._(t`Logs - Job Queue`),
        infobox: {
            text: <Trans>View the jobs currently enqueued.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272758-monitoring-global-monitoring-job-queue",
        },
        pageKey: "MONITOR_JOB_QUEUE",
    },
    {
        id: "/app/global/logs/recent-errors",
        title: (i18n: I18n) => i18n._(t`Logs - Recent Errors`),
        infobox: {
            text: <Trans>Displays a log of errors and warnings recorded on the server for this application.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272824-monitoring-global-monitoring-recent-errors",
        },
        pageKey: "MONITOR_GLOBAL_LOGS",
    },
    {
        id: "/app/global/logs/server-logs",
        title: (i18n: I18n) => i18n._(t`Logs - Server Logs`),
        infobox: {
            text: <Trans>View the most recent Server-to-Server (S2S) logs. Sessions IDs that are in grey italics were generated via the session-less protocol.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272826-monitoring-global-monitoring-server-logs",
        },
        pageKey: "MONITOR_SERVER_REQUESTS",
    },
    {
        id: "/app/global/logs/unreadable-requests",
        title: (i18n: I18n) => i18n._(t`Logs - Unreadable Requests`),
        infobox: {
            text: (
                <Trans>
                    These are requests sent to the server that cannot be processed. The most common cause for these errors are networking errors (we didn't receive the full request), bad serialization of JSON objects or security issues (bad packet
                    id, secret, etc.).
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272825-monitoring-global-monitoring-unreadable-requests",
        },
        pageKey: "MONITOR_UNREADABLE_REQUESTS",
    },
    {
        id: "/app/global/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`Marketplace - Redemption Codes`),
        infobox: {
            text: <Trans>View the status of individual redemption codes. You may also reset the status of the code. To reset the status of all codes, go to Design | Marketplace | Redemption Codes.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272759-monitoring-global-monitoring-redemption-codes",
        },
        pageKey: "MONITOR_REDEMPTION_CODES",
    },
    {
        id: "/app/global/marketplace/disputes",
        title: (i18n: I18n) => i18n._(t`Marketplace - Disputes`),
        infobox: {
            text: <Trans>TODO</Trans>,
            learnMore: null,
        },
        pageKey: "MONITOR_DISPUTES",
    },
    {
        id: "/app/groups/groups",
        title: (i18n: I18n) => i18n._(t`Groups - Groups`),
        infobox: {
            text: (
                <Trans>
                    Displays all the Groups that have been created for your application. You can filter the list by group type, or search for the name of a particular group. Choose View/Edit from the Action menu to view members and/or the core data
                    of the group. Choose View Group Entities to see a list of entities associated with this group.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272829-monitoring-group-monitoring-groups",
        },
        pageKey: "MONITOR_GROUP_GROUPS",
    },
    {
        id: "/app/groups/group-entities",
        title: (i18n: I18n) => i18n._(t`Groups - Group Entities`),
        infobox: {
            text: <Trans>Displays all the Group Entities for your application. To easily filter for all the entities of a particular group, locate the group in the Groups page above, and then choose View Group Entities from the Action menu.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272830-monitoring-group-monitoring-group-entities",
        },
        pageKey: "MONITOR_GROUP_ENTITIES",
    },
    {
        id: "/app/user/user-browser",
        title: (i18n: I18n) => i18n._(t`User Monitoring - User Browser`),
        infobox: {
            text: (
                <Trans>
                    Search for end-user accounts to view. Recently Active shows users who have most recently logged into the app. New Users are new account registrations. Recently Viewed are accounts that you have viewed in this browser. Flagged
                    accounts are users that are flagged in the app. Browse iterates through all accounts by name - and is most useful for apps with a smaller number of accounts.
                </Trans>
            ),
            learnMore: null,
        },
        pageKey: "MONITOR_USER_BROWSER",
    },
    {
        id: "/app/user/summary/user-summary",
        title: (i18n: I18n) => i18n._(t`User Monitoring - User Summary`),
        infobox: {
            text: <Trans>Key information about the end user is summarized here. You can even flag users (and add notes) to make them easier to look up later.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3281594-monitoring-user-monitoring-user-summary-finding-a-user",
        },
        pageKey: "MONITOR_USER_SUMMARY",
    },
    {
        id: "/app/user/summary/logs",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Logs`),
        infobox: {
            text: <Trans>View any recent client requests and responses. Useful for debugging during development!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272858-monitoring-user-monitoring-logs",
        },
        pageKey: "MONITOR_USER_LOGS",
    },
    {
        id: "/app/user/data/attributes",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Attributes`),
        infobox: {
            text: <Trans>Attributes are tags (key+value pairs) that can be assigned to a user (manually or programmatically) - primarily to be used in defining and filtering segments for Promotions.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272842-monitoring-user-monitoring-attributes",
        },
        pageKey: "MONITOR_USER_ATTRIBUTES",
    },
    {
        id: "/app/user/data/custom-entities",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Custom Entities `),
        infobox: {
            text: <Trans>Manage the Custom Entities that are owned by the selected user. Click on a collection to view / edit individual entities.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/4643182-monitoring-user-monitoring-custom-entities",
        },
        pageKey: "MONITOR_USER_CUSTOM_ENTITIES",
    },
    {
        id: "/app/user/data/statistics",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Statistics`),
        infobox: {
            text: <Trans>View (and potentially edit) the current values of the user's statistics. Be careful - this data is live!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272837-monitoring-user-monitoring-statistics",
        },
        pageKey: "MONITOR_USER_STATS",
    },
    {
        id: "/app/user/data/user-entities",
        title: (i18n: I18n) => i18n._(t`User Monitoring - User Entities`),
        infobox: {
            text: <Trans>View, edit and/or delete the entities associated with this user.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272839-monitoring-user-monitoring-user-entities",
        },
        pageKey: "MONITOR_USER_ENTITIES",
    },
    {
        id: "/app/user/data/user-files",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Files`),
        infobox: {
            text: <Trans>For viewing the files uploaded by the current user.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/4724252-monitoring-user-monitoring-user-files",
        },
        pageKey: "MONITOR_USER_FILES",
    },
    {
        id: "/app/user/gamification/achievements",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Achievements`),
        infobox: {
            text: <Trans>Discover what achievements the user has earned.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272848-monitoring-user-monitoring-achievements",
        },
        pageKey: "MONITOR_ACHIEVEMENTS",
    },
    {
        id: "/app/user/gamification/milestones-and-quests",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Milestones & Quests`),
        infobox: {
            text: <Trans>View the status of the user's milestones and quests.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272847-monitoring-user-monitoring-milestones-and-quests",
        },
        pageKey: "MONITOR_MILESTONES",
    },
    {
        id: "/app/user/marketplace/inventory",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Inventory`),
        infobox: {
            text: <Trans>View the items owned by this user. Supports deleting of items (mostly for testing purposes). Use the API Explorer to edit individual items.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/4712991-monitoring-user-monitoring-inventory",
        },
        pageKey: "MONITOR_VIRTUAL_ITEMS",
    },
    {
        id: "/app/user/marketplace/pricing",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Pricing`),
        infobox: {
            text: (
                <Trans>
                    The Pricing view is useful for understanding and debugging a user's view of product pricing, and how it is affected by Promotions. This screen shows what segments a user is currently in, what promotions currently apply to them
                    (both schedules and auto), and the resulting prices of all available products.
                </Trans>
            ),
            learnMore: "http://help.getbraincloud.com/en/articles/3272852-monitoring-user-monitoring-pricing",
        },
        pageKey: "MONITOR_PRICING",
    },
    {
        id: "/app/user/marketplace/transactions",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Transactions`),
        infobox: {
            text: <Trans>Displays the user's in-app purchase transactions.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272851-monitoring-user-monitoring-transactions",
        },
        pageKey: "MONITOR_USER_PURCHASES",
    },
    {
        id: "/app/user/marketplace/virtual-currency",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Virtual Currency`),
        infobox: {
            text: <Trans>View (and potentially edit) the user's virtual currency balances. Click on the amount in Purchases to add additional currency to their balance. Useful in support situations!</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272850-monitoring-user-monitoring-virtual-currency",
        },
        pageKey: "MONITOR_VIRTUAL_CURRENCY",
    },
    {
        id: "/app/user/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Redemption Codes`),
        infobox: {
            text: <Trans>View the list of codes that have been redeemed by this user.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272849-monitoring-user-monitoring-redemption-codes",
        },
        pageKey: "MONITOR_RECEPTIONS_CODES",
    },
    {
        id: "/app/user/multiplayer/turn-by-turn-mp",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Turn Based Multiplayer`),
        infobox: {
            text: <Trans>View the status of any turn-based matches that the user has in progress.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272853-monitoring-user-monitoring-turn-by-turn-mp",
        },
        pageKey: "MONITOR_TURN_BY_TURN",
    },
    {
        id: "/app/user/multiplayer/one-way-mp",
        title: (i18n: I18n) => i18n._(t`User Monitoring - One Way Multiplayer`),
        infobox: {
            text: <Trans>View the status of recent one-way multiplayer matches involving this user.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272854-monitoring-user-monitoring-one-way-mp",
        },
        pageKey: "MONITOR_ONEWAY",
    },
    {
        id: "/app/user/social/friends",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Friends`),
        infobox: {
            text: <Trans>Displays a list of this user's friends. You can filter by friend source, and click on a profile id to jump to that user.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272843-monitoring-user-monitoring-friends",
        },
        pageKey: "MONITOR_USER_FRIENDS",
    },
    {
        id: "/app/user/social/groups",
        title: (i18n: I18n) => i18n._(t`User Monitoring - Groups`),
        infobox: {
            text: <Trans>Displays the list of groups that this user is a member of. Click on the Group id to view more information about that group.</Trans>,
            learnMore: "http://help.getbraincloud.com/en/articles/3272846-monitoring-user-monitoring-groups",
        },
        pageKey: "MONITOR_USER_GROUPS",
    },
    {
        id: "/app/reports/analytics",
        title: (i18n: I18n) => i18n._(t`Reports - Analytics`),
        infobox: {
            text: <Trans>Dashboard - provides a snapshot of recent activity in your application. These statistics update every ~10 minutes.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/4740813-reports-reporting-analytics",
        },
        pageKey: "REPORT_DASH_ANALYTICS",
    },
    {
        id: "/app/reports/usage/profile",
        title: (i18n: I18n) => i18n._(t`Reports - Profile`),
        infobox: {
            text: <Trans>Developers can review the historical usage of their app from this page.</Trans>,
        },
        pageKey: "REPORT_APP_METRICS",
    },
    {
        id: "/app/reports/usage/api-usage",
        title: (i18n: I18n) => i18n._(t`Reports - API Usage`),
        infobox: {
            text: (
                <Trans>
                    These analytics are useful for understanding the API usage of your application, and how that is likely to affect billing. The per API stats at the bottom of the page give an understanding of the performance your app is seeing per
                    API call. These statistics update every ~10 minutes.
                </Trans>
            ),
        },
        pageKey: "REPORT_API_USAGE",
    },
    {
        id: "/app/reports/usage/api-errors",
        title: (i18n: I18n) => i18n._(t`Reports - API Errors`),
        infobox: {
            text: <Trans>This page shows a breakdown of error statistics per API call. Clicking on an API will bring you to a filtered list of recent errors. These statistics update every ~10 minutes.</Trans>,
        },
        pageKey: "REPORT_ERROR_ANALYTICS",
    },
    {
        id: "/app/reports/usage/data-usage",
        title: (i18n: I18n) => i18n._(t`Reports - Data Usage`),
        pageKey: "REPORT_DATA_USAGE",
    },
    {
        id: "/app/reports/usage/script-usage",
        title: (i18n: I18n) => i18n._(t`Reports - Script Usage`),
        infobox: {
            text: <Trans>This page is used to assess the overall performance and API usage of cloud code scripts. It is very useful when tracking optimization progress. Click on a script for more information.</Trans>,
        },
        pageKey: "REPORT_SCRIPT_USAGE",
    },
    {
        id: "/app/reports/exports/export-users",
        title: (i18n: I18n) => i18n._(t`Reports - Export Users`),
        infobox: {
            text: <Trans>Download your raw user data to do custom analytics, import into mailing lists, etc.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/4798477-reports-reporting-export-users",
        },
        pageKey: "REPORT_EXPORT_USERS",
    },
];

export const allScreens: ScreenData[] = [...superScreens, ...teamScreens, ...appScreens];
