const Flyout = (props: any) => {
    return (
        <svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 18 18" {...props}>
            <path
                style={{ fill: "currentcolor", fillOpacity: 1, fillRule: "evenodd" }}
                d="M0,2 L1,2 L1,16 L0,16 L0,2 Z M15,2 C16.6568542,2 18,3.34314575 18,5 L18,13 C18,14.6568542 16.6568542,16 15,16 L6,16 C4.34314575,16 3,14.6568542 3,13 L3,5 C3,3.34314575 4.34314575,2 6,2 L15,2 Z M15.1875,3 L5.8125,3 C4.7079305,3 3.8125,3.8954305 3.8125,5 L3.8125,5 L3.8125,13 C3.8125,14.1045695 4.7079305,15 5.8125,15 L5.8125,15 L15.1875,15 C16.2920695,15 17.1875,14.1045695 17.1875,13 L17.1875,13 L17.1875,5 C17.1875,3.8954305 16.2920695,3 15.1875,3 L15.1875,3 Z"
            />
        </svg>
    );
};

export default Flyout;
