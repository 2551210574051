// Import libraries.
import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import QueryString from "query-string";

// Import types.
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import components.
import { Chip, Typography } from "@material-ui/core";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";

// Import icons.
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";

// Import utilities.
import Http from "utils/networking/Http";
import StringUtils from "utils/String";

interface OWN_PROPS {
    onComplete: (email: string | null, password: string | null) => void;
    onCancel: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    email: string;
    recoveryToken: string;
    password: string;
    confirmPassword: string;

    resetPasswordStatus: "pending" | "complete" | "error" | null;
    error: string | null;
}

const PASSWORD_LENGTH_MIN = 6;

class ResetPassword extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        email: "",
        recoveryToken: "",
        password: "",
        confirmPassword: "",

        resetPasswordStatus: null,
        error: null,
    };
    private confirmButtonRef = React.createRef<HTMLElement>();

    componentDidMount() {
        // Extract any URI parameters that may have been supplied.
        let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
        if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
        const queryParams = queryString ? QueryString.parse(queryString) : {};

        if (queryParams.email && !Array.isArray(queryParams.email) && !StringUtils.isNullOrEmpty(queryParams.email) && queryParams.recoveryToken && !Array.isArray(queryParams.recoveryToken) && !StringUtils.isNullOrEmpty(queryParams.recoveryToken)) {
            // If the email and recoveryToken arguments are both present then proceed.
            this.setState({ email: queryParams.email, recoveryToken: queryParams.recoveryToken });
        } else {
            // Otheriwse immediately trigger the onComplete handler (returns the user to the standard login).
            this.props.onComplete(null, null);
        }
    }

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "password":
                this.setState({ password: value });
                break;
            case "confirmPassword":
                this.setState({ confirmPassword: value });
                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["password", "confirmPassword"].includes(name) && key === "Enter") {
            this.confirmButtonRef.current?.focus();

            this.onResetPassword();
        }
    };

    onResetPassword = async () => {
        const { bcFormValidator, i18n } = this.props;
        const { email, recoveryToken, password, confirmPassword } = this.state;

        if (!bcFormValidator.validate()) return;

        this.setState({ resetPasswordStatus: "pending", error: null }, async () => {
            if (password !== confirmPassword) {
                this.setState({ resetPasswordStatus: "error", error: i18n._(t`Passwords do not match.`) });

                return;
            }

            const resetPasswordResponse = await Http.POST("resetPassword", undefined, { email: email, recoveryToken: recoveryToken, newPassword: password }, Http.JSON_HEADERS);

            if (Http.isStatusOk(resetPasswordResponse)) {
                if (resetPasswordResponse.data.error) {
                    this.setState({ resetPasswordStatus: "error", error: resetPasswordResponse.data.error });
                } else {
                    this.setState({ resetPasswordStatus: "complete", error: null }, () => {
                        window.setTimeout(() => {
                            this.props.onComplete(email, password);
                        }, 1000);
                    });
                }
            } else {
                const error = Http.buildError(resetPasswordResponse);

                this.setState({ resetPasswordStatus: "error", error: error.errorMessage || i18n._(t`Could not reset password.`) });
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes } = this.props;
        const { email, password, confirmPassword, resetPasswordStatus, error } = this.state;

        return (
            <div id={"set-password"} className={classes.root}>
                {resetPasswordStatus !== "complete" && (
                    <>
                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Reset Password</Trans>
                        </Typography>

                        <FieldWrapper className={classes.field} type={"text"} name={"email"} value={email} disabled={true} />

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"password"}
                            name={"password"}
                            value={password}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={resetPasswordStatus != null && ["pending", "complete"].includes(resetPasswordStatus)}
                            autoFocus={true}
                            options={
                                {
                                    placeholder: i18n._(t`Password`),
                                    minLength: PASSWORD_LENGTH_MIN,
                                    autoComplete: "password",
                                    showPasswordManagerExtensions: true,
                                } as TextFieldOptions
                            }
                        />

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"password"}
                            name={"confirmPassword"}
                            value={confirmPassword}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={resetPasswordStatus != null && ["pending", "complete"].includes(resetPasswordStatus)}
                            options={
                                {
                                    placeholder: i18n._(t`Confirm Password`),
                                    minLength: PASSWORD_LENGTH_MIN,
                                } as TextFieldOptions
                            }
                        />

                        <Button
                            ref={this.confirmButtonRef}
                            className={classes.button}
                            id={"reset-password"}
                            type={"primary"}
                            onClick={this.onResetPassword}
                            disabled={resetPasswordStatus != null && ["pending", "complete"].includes(resetPasswordStatus)}
                        >
                            {resetPasswordStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Set Password</Trans>}
                        </Button>

                        <Button className={classes.button} id={"cancel"} type={"secondary"} onClick={this.props.onCancel} disabled={resetPasswordStatus != null && ["pending", "complete"].includes(resetPasswordStatus)}>
                            <Trans>Cancel</Trans>
                        </Button>

                        {resetPasswordStatus === "error" && <Chip className={classes.chip} label={error} />}
                    </>
                )}

                {resetPasswordStatus === "complete" && (
                    <div className={classes.resetPasswordComplete}>
                        <SuccessIcon />

                        <Typography>
                            <Trans>Password Reset</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        resetPasswordComplete: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",

            overflow: "hidden",

            "& > *": {
                marginLeft: "auto",
                marginRight: "auto",

                "&.MuiSvgIcon-root": {
                    width: "6rem",
                    height: "6rem",

                    color: "var(--label-positive-color, inherit)",
                },

                "&.MuiTypography-root": {
                    marginTop: "2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                },
            },
        },
    });

export default withFormValidator()(withI18n()(withStyles(styles)(ResetPassword)));
