// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import User from "types/common/User";

// Import components.
import { Typography } from "@material-ui/core";

// Import redux actions.
import { SET_FAVORITES } from "store/actions/favorites";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";

interface STATE_PROPS {
    currentUser: User | null;
    favorites: string[];
}
interface DISPATCH_PROPS {
    setFavorites: (favorites: string[]) => void;
}
interface OWN_PROPS {
    id: string;
    originalPath?: string;
    children?: React.ReactNode;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
        favorites: state.favorites,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setFavorites: (favorites: string[]) => dispatch(SET_FAVORITES(favorites)),
    };
};

class Screen extends React.PureComponent<PROPS> {
    componentDidMount(): void {
        // Google Analytics - Page View Event
        if ((window as any).gtag) {
            (window as any).gtag("event", "page_view", {
                page_path: window.location.pathname,
                page_search: window.location.search,
                page_hash: window.location.hash,
                page_location: window.location.href,
            });
        }
    }

    render() {
        const { classes, id, originalPath } = this.props;

        console.debug("Rendering Screen", originalPath || id, PortalRouteUtils.sanitizeRoutePath(id));

        return (
            <div id={this.props.id} className={classes.root}>
                {this.props.children && <div className={classes.content}>{this.props.children}</div>}

                {!this.props.children && (
                    <div className={classes.notImplemented}>
                        <Typography>
                            <Trans>Component Not Implemented.</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        content: {
            position: "relative",
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            "& > *": {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        notImplemented: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Screen)));
