// Import libraries.
import React from "react";
import Redux from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Theme, createStyles, withStyles, WithStyles, withWidth, WithWidthProps } from "@material-ui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { t } from "@lingui/macro";
import classnames from "classnames";
import md5 from "md5";

// Import types.
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import User from "types/common/User";
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import components.
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import ImageWrapper from "components/common/ImageWrapper";
import IntercomLauncher from "components/common/widgets/Intercom/IntercomLauncher";
/* import Notifications from "../Notifications"; */
import Help from "../Help";

// Import icons.
import OpenNavigatorIcon from "@material-ui/icons/MenuRounded";
import CloseNavigatorIcon from "@material-ui/icons/CloseRounded";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    themeConfiguration: ThemeConfiguration;
    session: Session;
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    navigatorOpen: boolean;
    navigatorPinned: boolean;
    profileOpen: boolean;
    profilePinned: boolean;
    searchOpen: boolean;
    searchString: string;
    onToggleNavigatorOpen: (open?: boolean) => void;
    onToggleNavigatorPinned: (pinned?: boolean) => void;
    onToggleProfileOpen: (open?: boolean) => void;
    onToggleProfilePinned: (pinned?: boolean) => void;
    onToggleSearchOpen: (open?: boolean) => void;
    onSearchChange: (value: string, force?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps, WithWidthProps {}

interface STATE {
    currentSearchString: string;
    currentSearchTimeout: number;
    miniSearchHidden: boolean;
}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        applicationInformation: state.applicationInformation,
        themeConfiguration: state.themeConfiguration,
        session: state.session,
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (_dispatch: Redux.Dispatch<Redux.AnyAction>): DISPATCH_PROPS => {
    return {};
};

const SEARCH_TIMEOUT = 1000;

class Header extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        currentSearchString: this.props.searchString,
        currentSearchTimeout: 0,
        miniSearchHidden: true,
    };

    componentDidMount() {
        // User is initially on the Cloud Code Editor, so force close both the navigator and profile blades (regardless of whether or not they are pinned).
        if (this.props.location.pathname === "/app/design/cloud-code/scripts") {
            if (this.props.navigatorOpen && this.props.navigatorPinned) {
                this.props.onToggleNavigatorOpen();
            }

            if (this.props.profileOpen && this.props.profilePinned) {
                this.props.onToggleProfileOpen();
            }
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.location !== this.props.location) {
            // User is initially on the Cloud Code Editor, so force close both the navigator and profile blades (regardless of whether or not they are pinned).
            if (prevProps.location.pathname !== "/app/design/cloud-code/scripts" && this.props.location.pathname === "/app/design/cloud-code/scripts") {
                if (this.props.navigatorOpen && this.props.navigatorPinned) {
                    this.props.onToggleNavigatorOpen();
                }

                if (this.props.profileOpen && this.props.profilePinned) {
                    this.props.onToggleProfileOpen();
                }
            }

            // User navigated away from the Cloud Code Editor, so force open both the navigator and profile blades (if they are currently pinned... respectively).
            if (prevProps.location.pathname === "/app/design/cloud-code/scripts" && this.props.location.pathname !== "/app/design/cloud-code/scripts") {
                if (!this.props.navigatorOpen && this.props.navigatorPinned) {
                    this.props.onToggleNavigatorOpen();
                }

                if (!this.props.profileOpen && this.props.profilePinned) {
                    this.props.onToggleProfileOpen();
                }
            }
        }

        if (prevProps.searchString !== this.props.searchString) {
            this.setState({ currentSearchString: this.props.searchString });
        }

        if (prevProps.width === "xs" && this.props.width !== "xs") {
            this.setState({ miniSearchHidden: this.props.searchOpen ? false : true });
        }

        if (prevProps.width !== "xs" && this.props.width === "xs") {
            this.setState({ miniSearchHidden: this.props.searchOpen ? false : true });
        }
    }

    handleToggleMiniSearchHidden = () => {
        this.setState({ miniSearchHidden: !this.state.miniSearchHidden });
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "currentSearchString":
                this.setState({ currentSearchString: value }, () => {
                    this.completeSearch(false, SEARCH_TIMEOUT);
                });

                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["currentSearchString"].includes(name)) {
            switch (key) {
                case "Enter":
                    // In case the search results is closed, we ensure it is open.
                    // If it is already open then this is essentially a no-op.
                    this.startSearch();

                    // When the user presses the "Enter" key, we evaluate the search immediately by omitting the timeout parameter to "completeSearch".
                    // We also pass a 'force' argument of true to force the evaluation of the search without changing the query string.
                    this.completeSearch(true);

                    break;
                case "Escape":
                    // when the user presses the "Escape" key, we cancel the search.
                    this.cancelSearch();

                    break;
                default:
                    // In case the search results is closed, we ensure it is open.
                    // If it is already open then this is essentially a no-op.
                    this.startSearch();
            }
        }
    };

    startSearch = () => {
        const { searchOpen } = this.props;

        if (!searchOpen) {
            this.props.onToggleSearchOpen();
        }
    };

    completeSearch = (force: boolean, timeout: number = 150) => {
        const { currentSearchString, currentSearchTimeout } = this.state;

        // In case the search results is closed, we ensure it is open.
        // If it is already open then this is essentially a no-op.
        // This can occur when a value is pasted into the search field which does not result in a key press event.
        this.startSearch();

        if (timeout != null) {
            if (currentSearchTimeout !== 0) {
                window.clearTimeout(currentSearchTimeout);
            }

            const newSearchTimeout = window.setTimeout(() => {
                this.props.onSearchChange(currentSearchString, force);
            }, timeout);

            this.setState({ currentSearchTimeout: newSearchTimeout });
        } else {
            if (currentSearchTimeout !== 0) {
                window.clearTimeout(currentSearchTimeout);

                this.setState({ currentSearchTimeout: 0 });
            }

            this.props.onSearchChange(currentSearchString, force);
        }
    };

    cancelSearch = () => {
        const { searchOpen } = this.props;
        const { currentSearchTimeout } = this.state;

        if (currentSearchTimeout !== 0) {
            window.clearTimeout(currentSearchTimeout);

            this.setState({ currentSearchTimeout: 0 });
        }

        if (searchOpen) {
            this.props.onToggleSearchOpen();
        }

        this.setState({ currentSearchString: "" }, () => {
            this.props.onSearchChange(this.state.currentSearchString);
        });
    };

    handleNavigatorClick = (event: React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();

        this.props.onToggleNavigatorOpen();

        if (this.props.profileOpen && !this.props.profilePinned) {
            this.props.onToggleProfileOpen();
        }
    };

    handleProfileClick = (event: React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();

        this.props.onToggleProfileOpen();

        if (this.props.navigatorOpen && !this.props.navigatorPinned) {
            this.props.onToggleNavigatorOpen();
        }
    };

    handleSearchClick = (event: React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();

        this.startSearch();

        if (this.props.navigatorOpen && !this.props.navigatorPinned) this.props.onToggleNavigatorOpen();
        if (this.props.profileOpen && !this.props.profilePinned) this.props.onToggleProfileOpen();
    };

    render() {
        const { classes, i18n, location, applicationInformation, themeConfiguration, session, currentUser, navigatorPinned, navigatorOpen } = this.props;
        const { currentSearchString, miniSearchHidden } = this.state;

        const activeTheme = themeConfiguration.activeTheme;
        const activeMode = activeTheme && activeTheme[session.themeMode] ? activeTheme[session.themeMode] : null;

        const realMainServiceLogoUrl = activeMode?.mainServiceLogoUrl?.startsWith("../") ? "/api/" + activeMode.mainServiceLogoUrl.substring(3) : activeMode?.mainServiceLogoUrl || null;

        const context = location.pathname.startsWith("/super") ? "super" : location.pathname.startsWith("/team") ? "team" : location.pathname.startsWith("/app") ? "app" : null;

        const isCloudCodeEditorActive = this.props.location.pathname === "/app/design/cloud-code/scripts";

        return (
            <div id={"header"} className={classes.root}>
                <div id={"navigator"} className={classes.navigator} style={{ height: "3em" }}>
                    <span
                        style={{
                            flex: "0 0 auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "3em",
                            height: "3em",
                            backgroundColor: !(!navigatorPinned || isCloudCodeEditorActive)
                                ? "inherit"
                                : context === "super"
                                ? "var(--navigation-super-context-color, inherit)"
                                : context === "team"
                                ? "var(--navigation-team-context-color, inherit)"
                                : context === "app"
                                ? "var(--navigation-app-context-color, inherit)"
                                : undefined,
                        }}
                    >
                        {(!navigatorPinned || isCloudCodeEditorActive) && (
                            <IconButton
                                id={"toggle-navigator"}
                                color={"var(--header-menu-launcher-color, inherit)"}
                                style={{ width: "2em", height: "2em", margin: "auto", padding: 0 }}
                                onClick={this.handleNavigatorClick}
                                disabled={!(!navigatorPinned || isCloudCodeEditorActive)}
                                onTouchEnd={(event: React.SyntheticEvent<HTMLElement>) => {
                                    event.stopPropagation();
                                }}
                            >
                                {navigatorOpen ? <CloseNavigatorIcon /> : <OpenNavigatorIcon />}
                            </IconButton>
                        )}
                    </span>

                    <ImageWrapper style={{ width: "calc(100% - 3em)", height: "100%" }} src={realMainServiceLogoUrl ? realMainServiceLogoUrl : "/images/brainCloud_logo.png"} disableCache={activeTheme?.updatedAt?.getTime() || true} />
                </div>

                <div id={"main-search"} className={classes.search} style={{ height: "3em" }}>
                    <span onClick={this.handleSearchClick}>
                        <FieldWrapper
                            type={"text"}
                            name={"currentSearchString"}
                            value={currentSearchString}
                            onChange={this.handleChange}
                            onFocus={this.startSearch}
                            onKeyPress={this.handleKeyPress}
                            options={
                                {
                                    startAdornment: currentSearchString ? (
                                        <IconButton id={"clear-search"} onClick={() => this.handleChange("currentSearchString", "")}>
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <SearchIcon />
                                    ),
                                    placeholder: i18n._(t`Search in brainCloud`),
                                    spellCheck: false,
                                } as TextFieldOptions
                            }
                        />
                    </span>
                </div>

                <div id={"buttons"} className={classes.buttons} style={{ height: "3em" }}>
                    <IconButton id={"toggle-search"} color={"var(--header-color, inherit)"} style={{ width: "1.5em", height: "1.5em", margin: "0 0 0 0.3125em", padding: 0 }} onClick={this.handleToggleMiniSearchHidden}>
                        <SearchIcon />
                    </IconButton>

                    {/* <Notifications style={{ width: "1.5em", height: "1.5em", margin: "0 0 0 0.3125em", padding: 0 }} /> */}

                    <Help style={{ width: "1.5em", height: "1.5em", margin: "0 0 0 0.3125em", padding: 0 }} />

                    {applicationInformation.intercomConfiguration.enabled && <IntercomLauncher style={{ width: "1.5em", height: "1.5em", margin: "0 0 0 0.3125em", padding: 0 }} />}

                    <IconButton
                        id={"toggle-profile"}
                        color={"var(--header-color, inherit)"}
                        style={{ width: "2em", height: "2em", margin: "0 0 0 0.625em", padding: 0 }}
                        onClick={this.handleProfileClick}
                        onTouchEnd={(event: React.SyntheticEvent<HTMLElement>) => {
                            event.stopPropagation();
                        }}
                    >
                        {currentUser?.email && <img style={{ borderRadius: "50%", overflow: "hidden" }} alt={"user-avatar"} src={"https://s.gravatar.com/avatar/" + md5(currentUser.email) + ".jpg?s=32&d=mm"} />}
                    </IconButton>
                </div>

                <div id={"mini-search"} className={classnames(classes.search, miniSearchHidden ? classes.miniSearchHidden : undefined)}>
                    <span style={{ padding: "0.3125em" }} onClick={this.handleSearchClick}>
                        <FieldWrapper
                            type={"text"}
                            name={"currentSearchString"}
                            value={currentSearchString}
                            onChange={this.handleChange}
                            onFocus={this.startSearch}
                            onKeyPress={this.handleKeyPress}
                            options={
                                {
                                    startAdornment: <SearchIcon />,
                                    placeholder: i18n._(t`Search in brainCloud`),
                                    spellCheck: false,
                                } as TextFieldOptions
                            }
                        />
                    </span>
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "grid",
            gridTemplateColumns: "12.3755em auto 12.375em",

            backgroundColor: "var(--header-background-color, inherit)",
            color: "var(--header-color, inherit)",
            borderColor: "var(--header-border-color, inherit)",

            overflow: "hidden",

            "& > #navigator": {
                gridRow: 1,
                gridColumn: 1,
            },
            "& > #main-search": {
                gridRow: 1,
                gridColumn: 2,
            },
            "& > #buttons": {
                gridRow: 1,
                gridColumn: 3,
            },
            "& > #mini-search": {
                gridRow: 2,
                gridColumn: "1 / span 3",

                display: "none",
                height: 0,

                transition: "height 0.2s linear",
            },

            [theme.breakpoints.down("xs")]: {
                gridTemplateColumns: "minmax(3em, 12.375em) minmax(8em, 1fr)",

                "& > #navigator": {
                    gridRow: 1,
                    gridColumn: 1,
                },
                "& > #main-search": {
                    display: "none",
                },
                "& > #buttons": {
                    gridRow: 1,
                    gridColumn: 2,

                    "&  #icon-button-toggle-profile": {
                        display: "none",
                    },
                },
                "& > #mini-search": {
                    display: "block",

                    gridRow: 2,
                    gridColumn: "1 / span 2",

                    height: "calc(0.625em + var(--field-height))",
                },
            },

            [theme.breakpoints.up("sm")]: {
                "& > #buttons": {
                    "& #icon-button-toggle-search": {
                        display: "none",
                    },
                },
                "& > #mini-search": {
                    maxHeight: 0,
                },
            },
        },

        navigator: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",

            overflow: "hidden",

            "& > img": {
                flex: "0 0 auto",
                width: "9.375em",
                height: "1.875em",
                overflow: "hidden",
            },
        },

        search: {
            display: "flex",
            alignItems: "center",

            overflow: "hidden",

            "& > span": {
                flex: "1 1 auto",

                display: "flex",

                maxWidth: "30em",

                marginLeft: "auto",
                marginRight: "auto",

                overflow: "hidden",

                position: "relative",

                [theme.breakpoints.down("xs")]: {
                    minWidth: "100%",
                },

                "& > *": {
                    width: "100%",

                    margin: 0,

                    overflow: "hidden",

                    "& > #control": {
                        "& .MuiFormControl-root, & .MuiOutlinedInput-root": {
                            borderRadius: "0.25em",
                        },
                    },
                },
            },
        },
        miniSearchHidden: {
            height: "0px !important",
        },

        buttons: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",

            overflow: "hidden",

            padding: "0.5em",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withWidth()(withStyles(styles)(Header)))));
