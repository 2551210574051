// Import libraries.
import React, { CSSProperties } from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import components.
import { Typography } from "@material-ui/core";
import Button from "components/common/button/Button";
import WidgetContainer from "components/common/widgets/WidgetContainer";

interface OWN_PROPS {
    style?: CSSProperties;
    type?: "info" | "warning" | "error";
    text?: React.ReactNode | null;
    content?: React.ReactNode | null;
    learnMore?: string | null;
    hideBorder?: boolean;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class InfoBox extends React.PureComponent<PROPS> {
    render() {
        const { classes, style, type, text, content, learnMore, hideBorder } = this.props;

        return (
            <WidgetContainer className={classnames({ [classes.root]: true, [classes.hideBorder]: hideBorder, [classes.info]: type === "info" || type == null, [classes.warning]: type === "warning", [classes.error]: type === "error" })} style={style}>
                <span className={classes.content}>
                    {text && (
                        <div className={classes.text} style={{ marginBottom: content ? "0.3125em" : 0 }}>
                            <Typography>{text}</Typography>
                        </div>
                    )}

                    {content && content}

                    {learnMore && (
                        <div className={classes.learnMore}>
                            <Button id={"learn-more"} type={"secondary"} onClick={() => window.open(learnMore)}>
                                <Trans>Learn More</Trans>
                            </Button>
                        </div>
                    )}
                </span>
            </WidgetContainer>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            minWidth: "unset",
            maxWidth: "calc(100% - 0.625em)",

            margin: "0.3125em",

            padding: "0.625em",
            borderWidth: "0 0 0 0.5em",
        },
        hideBorder: {
            borderStyle: "none",
            borderRadius: 0,
        },
        content: {
            flex: "0 0 auto",
            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        info: {
            backgroundColor: "var(--infobox-info-background-color, inherit)",
            color: "var(--infobox-info-color, inherit)",
            borderColor: "var(--infobox-info-border-color, inherit)",
        },
        warning: {
            backgroundColor: "var(--infobox-warning-background-color, inherit)",
            color: "var(--infobox-warning-color, inherit)",
            borderColor: "var(--infobox-warning-border-color, inherit)",
        },
        error: {
            backgroundColor: "var(--infobox-error-background-color, inherit)",
            color: "var(--infobox-error-color, inherit)",
            borderColor: "var(--infobox-error-border-color, inherit)",
        },
        text: {
            flex: "1 1 auto",
            alignItems: "stretch",
            color: "inherit",
        },
        learnMore: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
        },
    });

export default withStyles(styles)(InfoBox);
