// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import DialogMode from "types/enums/DialogMode";
import AppInfo from "types/models/AppInfo";

// Import components.
import { Typography, Link } from "@material-ui/core";
import LabelWithCopy from "components/common/LabelWithCopy";
import SendIntercomMessageDialog from "components/common/dialog/App/SendIntercomMessageDialog";
import ImageWrapper from "components/common/ImageWrapper";

// Import icons
import EmailIcon from "@material-ui/icons/Email";
import NoAppImage from "components/icons/NoTeamImage";

// Import utilities.
import StringUtils from "utils/String";

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    appInfo: AppInfo | null;
}
interface PROPS extends OWN_PROPS, STATE_PROPS, DISPATCH_PROPS, WithStyles<typeof styles> {}
interface STATE {
    sendIntercomMessageDialogMode: DialogMode.CREATE | null;
}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = () => {
    return {};
};

class AppInfoSection extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        sendIntercomMessageDialogMode: null,
    };

    render() {
        const { classes, appInfo, session } = this.props;
        const { sendIntercomMessageDialogMode } = this.state;

        return (
            <div className={classes.root}>
                <ImageWrapper
                    style={{ width: "6em", height: "6em", margin: "0.3125em" }}
                    src={!StringUtils.isNullOrEmpty(appInfo?.imageUrl) ? "files/portal/g/" + appInfo?.appId + appInfo?.imageUrl : null}
                    fallback={<NoAppImage />}
                    disableCache={appInfo?.updatedAt || null}
                />

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <LabelWithCopy id={"appId"} value={appInfo ? `${appInfo.appName} (${appInfo.appId})` : null}>
                            <Trans>App Name</Trans>
                        </LabelWithCopy>

                        <Typography style={{ fontWeight: "bold", maxWidth: "18.75em" }} noWrap>
                            {appInfo ? appInfo.appName : "---"}
                        </Typography>
                    </span>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span className={classes.nameValuePair}>
                            <LabelWithCopy id={"appId"} value={appInfo ? appInfo.appId : null}>
                                <Trans>App ID</Trans>
                            </LabelWithCopy>

                            <Typography style={{ fontWeight: "bold" }} noWrap>
                                {appInfo ? appInfo.appId : "---"}
                            </Typography>
                        </span>

                        {session.isSuper && (
                            <span style={{ flex: "0 0 auto", display: "flex", flexDirection: "row", alignItems: "center", alignSelf: "flex-end" }}>
                                <EmailIcon style={{ color: "var(--button-primary-background-color, inherit)", marginRight: "0.3125em" }} />

                                <Link data-id={appInfo?.appId} data-name={appInfo?.appName} data-type={"app"} onClick={() => this.setState({ sendIntercomMessageDialogMode: DialogMode.CREATE })}>
                                    <Trans>Send Message</Trans>
                                </Link>
                            </span>
                        )}
                    </div>
                </div>

                {sendIntercomMessageDialogMode === DialogMode.CREATE && appInfo && <SendIntercomMessageDialog appInfo={appInfo} onClose={() => this.setState({ sendIntercomMessageDialogMode: null })} />}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppInfoSection));
