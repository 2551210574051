// Import libraries.
import React from "react";
import { Trans } from "@lingui/macro";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Import types.
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import { Typography } from "@material-ui/core";
import LabelWithCopy from "components/common/LabelWithCopy";
import ImageWrapper from "components/common/ImageWrapper";
import Timestamp from "components/common/Timestamp";

// Import Icons.
import NoPlayerImage from "components/icons/NoPlayerImage";

interface PROPS extends WithStyles<typeof styles>, RouteComponentProps {
    playerSummary: PlayerSummary;
}

class PlayerBanner extends React.PureComponent<PROPS> {
    render() {
        const { classes, playerSummary } = this.props;
        return (
            <div className={classes.root}>
                <div style={{ display: "flex", borderColor: "inherit" }}>
                    <ImageWrapper style={{ width: "6em", height: "6em", margin: "0.3125em" }} src={playerSummary.pictureUrl} fallback={<NoPlayerImage />} />

                    <div className={classes.content}>
                        <span className={classes.nameValuePair}>
                            <LabelWithCopy id={"playerId"} value={playerSummary ? playerSummary.playerId : null}>
                                <Trans>Profile ID</Trans>
                            </LabelWithCopy>

                            <Typography style={{ fontWeight: "bold" }} noWrap>
                                {playerSummary.playerId}
                            </Typography>
                        </span>
                        <span className={classes.nameValuePair}>
                            <Typography noWrap>{<Trans>Is Tester</Trans>}</Typography>

                            <Typography style={{ fontWeight: "bold" }} noWrap>
                                {playerSummary.isTester ? <Trans>Yes</Trans> : <Trans>No</Trans>}
                            </Typography>
                        </span>
                    </div>
                </div>

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Profile Name</Trans>}</Typography>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {playerSummary.name ? playerSummary.name : "---"}
                        </Typography>
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Profile Email</Trans>}</Typography>

                        <Typography style={{ fontWeight: "bold" }} noWrap>
                            {playerSummary.email ? playerSummary.email : "---"}
                        </Typography>
                    </span>
                </div>

                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Created</Trans>}</Typography>
                        <Timestamp style={{ fontWeight: "bold" }} value={playerSummary.createdAt} humanize={true} />
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Updated</Trans>}</Typography>
                        <Timestamp style={{ fontWeight: "bold" }} value={playerSummary.updatedAt} humanize={true} />
                    </span>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            "& > *:nth-child(2)": {
                marginLeft: "auto",
            },

            "& > *:nth-child(3)": {
                marginLeft: "1.25em",
            },
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
        },
    });

export default withRouter(withStyles(styles)(PlayerBanner));
