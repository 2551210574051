// Import libraries.
import React, { CSSProperties } from "react";
import { Trans } from "@lingui/macro";

// Import components.
import { Tooltip } from "@material-ui/core";
import IconButton from "components/common/button/IconButton";

// Import icons.
import IntercomIcon from "@material-ui/icons/Comment";

const IntercomLauncher = (props: { style?: CSSProperties }) => {
    if ((window as any).Intercom == null) return null;

    return (
        <Tooltip arrow title={<Trans>Ask Support</Trans>}>
            <IconButton id="toggle-intercom" style={{ color: "var(--header-intercom-launcher-color, inherit)", margin: 0, padding: 0, ...props.style }}>
                <IntercomIcon style={{ width: "100%", height: "100%" }} />
            </IconButton>
        </Tooltip>
    );
};

export default React.memo(IntercomLauncher);
