// Basic/Common Patterns.
export const ALPHA_NUMERIC: RegExp = /^[a-zA-Z0-9]+$/;
export const ALPHA_NUMERIC_NO_SPACE: RegExp = /^[a-zA-Z0-9_]*$/;
export const ALPHA_NUMERIC_PERIOD: RegExp = /^([a-zA-Z0-9_.]+)(,[a-zA-Z0-9_.]+)*$/;
export const ALPHA_NUMERIC_UNDERSCORE_DASH_NO_SPACE: RegExp = /^[a-zA-Z0-9_]*$/;
export const ALPHA_NUMERIC_UNDERSCORE_HYPHEN_NO_SPACE: RegExp = /^[a-zA-Z0-9_-]*$/;
export const ALPHA_NUMERIC_HYPHEN_NO_SPACE: RegExp = /^[a-zA-Z0-9-]*$/;
export const ALPHA: RegExp = /^[a-zA-Z]+$/;
export const NUMERIC: RegExp = /^[0-9]+$/;
export const EMAIL: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const URL: RegExp = /^(https?:\/\/)([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])*)(:\d+)?(\/[^\s]*)?$/;
export const DOMAIN_NAME: RegExp = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])*)$/;
export const PHONE_CONTACT: RegExp = /^(\+?([0-9]{1,5}))?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})?[-. ]?([0-9]{4})?$/;
export const GUID: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const SIMPLE_IDENTIFIER: RegExp = /^[a-zA-Z0-9_-]*$/;
export const SIMPLE_IDENTIFIER_NO_HYPHEN: RegExp = /^[a-zA-Z0-9_]*$/;
export const SIMPLE_IDENTIFIER_WITH_SPACE: RegExp = /^[a-zA-Z0-9_-\s]*$/;
export const IPV4_ADDRESS: RegExp = /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])$/;
export const IP_RANGES: RegExp = /^[\d+\-.\s]*$/;
export const IP_RANGE: RegExp = /^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})?(\s?-\s?([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3}))?$/;

// Regex Patterns for matching different CSS color formats (HEX, RGB, RGBa, HSL and HSLa).
export const HEX_PATTERN: RegExp = /^#(([0-9A-F]{6})|([0-9A-F]{3}))$/i;
export const RGB_PATTERN: RegExp = /^rgb\(\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*\)$/i;
export const RGBA_PATTERN: RegExp = /^rgba\(\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*(1|1\.0+|0|0\.\d+)\s*\)$/i;
export const HSL_PATTERN: RegExp = /^hsl\(\s*\d{1,3}\s*,\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*\)$/;
export const HSLA_PATTERN: RegExp = /^hsla\(\s*\d{1,3}\s*,\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*,\s*(1|1\.0+|0|0\.\d+)\s*\)$/i;

// Regex Patterns for matching different CSS size formats (px, em and rem).
export const PX_PATTERN: RegExp = /^\d+(.\d+)?px$/i;
export const EM_PATTERN: RegExp = /^\d+(.\d+)?em$/i;
export const REM_PATTERN: RegExp = /^\d+(.\d+)?rem$/i;
export const CSS_SIZE_PATTERN: RegExp = /^\d+(.\d+)?(px|em|rem)$/i;

// Cloud Code Script Patterns.
export const FOLDER_NAME = /^[a-z0-9-_]*$/;
export const SCRIPT_NAME = /^[a-zA-Z0-9_]*$/;
