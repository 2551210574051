const FlyoutFilled = (props: any) => {
    return (
        <svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 18 18" {...props}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(3.000000, 4.270000)"></g>
                <rect x="0" y="0" width="18" height="18"></rect>
                <g transform="translate(0.000000, 2.000000)"></g>
                <path
                    d="M17.21,2.76 C16.7,2.28 16.03,2.01 15.29,2.01 L5.8,2.01 C5.08,2.01 4.41,2.28 3.88,2.76 C3.37,3.24 3.08,3.89 3.08,4.57 L3.08,13.54 C3.08,14.23 3.37,14.86 3.88,15.36 C4.39,15.84 5.06,16.11 5.8,16.11 L15.28,16.11 C16,16.11 16.68,15.84 17.2,15.36 C17.71,14.88 17.99,14.24 17.99,13.54 L17.99,4.58 C17.99,3.89 17.71,3.26 17.2,2.77 L17.21,2.76 Z M0,2 L1.08,2 L1.08,16.2 L0,16.2 L0,2 Z"
                    fill="currentColor"
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
};

export default FlyoutFilled;
