import moment from "moment-timezone";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";

export const HUMAN_DATE_FORMAT = "MMM Do YYYY";
export const HUMAN_TIME_FORMAT = "h:mmA";

export const DEFAULT_TIMEZONE = "UTC";

export default abstract class DateTimeFormatter {
    private static CURRENT_TIMEZONE: string = DEFAULT_TIMEZONE;

    public static formatDate(timestamp?: Date | moment.Moment | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        return requestedDateTime.format(DEFAULT_DATE_FORMAT);
    }

    public static formatDateHuman(timestamp?: Date | moment.Moment | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        return requestedDateTime.format(HUMAN_DATE_FORMAT);
    }

    public static formatTime(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (includeTimezone) {
            return requestedDateTime.format(DEFAULT_TIME_FORMAT + " z");
        } else {
            return requestedDateTime.format(DEFAULT_TIME_FORMAT);
        }
    }

    public static formatTimeHuman(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (includeTimezone) {
            return requestedDateTime.format(HUMAN_TIME_FORMAT + " z");
        } else {
            return requestedDateTime.format(HUMAN_TIME_FORMAT);
        }
    }

    public static formatDateTime(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        return DateTimeFormatter.formatDate(timestamp, timezone) + " " + DateTimeFormatter.formatTime(timestamp, includeTimezone, timezone);
    }

    public static formatDateTimeHuman(timestamp?: Date | moment.Moment | null, includeTimezone?: boolean, timezone?: string | null) {
        if (timestamp == null) return "---";

        return DateTimeFormatter.formatDateHuman(timestamp, timezone) + " " + DateTimeFormatter.formatTimeHuman(timestamp, includeTimezone, timezone);
    }

    public static formatDateTimeCustom(timestamp?: Date | moment.Moment | null, format?: string | null, timezone?: string | null) {
        if (timestamp == null) return "---";

        const requestedTimezone = timezone ? timezone : DateTimeFormatter.CURRENT_TIMEZONE;
        const requestedDateTime = moment(timestamp).tz(requestedTimezone);

        if (format) {
            return requestedDateTime.format(format);
        } else {
            return requestedDateTime.format();
        }
    }

    public static convertToCurrentTimezone(timestamp?: Date | moment.Moment | null) {
        return moment(timestamp).tz(DateTimeFormatter.CURRENT_TIMEZONE);
    }

    public static setCurrentTimezone(timezone?: string | null) {
        DateTimeFormatter.CURRENT_TIMEZONE = timezone ? timezone : DEFAULT_TIMEZONE;
    }
}
