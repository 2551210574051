// Import libraries.
import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import types.
import AppInfo from "types/models/AppInfo";
import PublishState from "types/enums/PublishState";
import AppStatus from "types/enums/AppStatus";
import RunState from "types/enums/RunState";

// Import components.
import { Chip, Typography } from "@material-ui/core";

interface OWN_PROPS {
    appInfo: AppInfo | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class AppStatusSection extends React.PureComponent<PROPS> {
    render() {
        const { classes, appInfo } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Publishing:</Trans>}</Typography>

                        <Chip
                            style={{
                                height: "1.5em",
                                marginLeft: "0.3125em",
                                backgroundColor:
                                    appInfo && appInfo.publishState === PublishState.LIVE
                                        ? "var(--chip-positive-background-color)"
                                        : appInfo && appInfo.publishState === PublishState.DELETED
                                        ? "var(--chip-negative-background-color)"
                                        : appInfo && appInfo.publishState === PublishState.SUSPENDED
                                        ? "var(--chip-warning-background-color)"
                                        : "var(--chip-default-background-color)",
                                color:
                                    appInfo && appInfo.publishState === PublishState.LIVE
                                        ? "var(--chip-positive-color)"
                                        : appInfo && appInfo.publishState === PublishState.DELETED
                                        ? "var(--chip-negative-color)"
                                        : appInfo && appInfo.publishState === PublishState.SUSPENDED
                                        ? "var(--chip-warning-color)"
                                        : "var(--chip-default-color)",
                                fontWeight: "bold",
                            }}
                            label={
                                appInfo && appInfo.publishState === PublishState.LIVE ? (
                                    <Trans>Live</Trans>
                                ) : appInfo && appInfo.publishState === PublishState.DELETED ? (
                                    <Trans>Deleted</Trans>
                                ) : appInfo && appInfo.publishState === PublishState.SUSPENDED ? (
                                    <Trans>Suspended</Trans>
                                ) : appInfo ? (
                                    appInfo.publishState
                                ) : (
                                    "---"
                                )
                            }
                        />
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Plan:</Trans>}</Typography>

                        <Typography style={{ marginLeft: "0.3125em" }} noWrap>
                            {appInfo ? appInfo.billing_plan_name : "---"}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Status:</Trans>}</Typography>

                        <Typography
                            style={{
                                marginLeft: "0.3125em",
                                color:
                                    appInfo && appInfo.app_status === AppStatus.DELETED
                                        ? "var(--label-negative-color)"
                                        : appInfo && appInfo.app_status === AppStatus.DISABLED
                                        ? "var(--label-warning-color)"
                                        : appInfo && appInfo.app_status === AppStatus.ENABLED
                                        ? "var(--label-positive-color)"
                                        : "var(--label-default-color)",
                            }}
                            noWrap
                        >
                            {appInfo && appInfo.app_status === AppStatus.DELETED ? (
                                <Trans>Deleted</Trans>
                            ) : appInfo && appInfo.app_status === AppStatus.DISABLED ? (
                                <Trans>Disabled</Trans>
                            ) : appInfo && appInfo.app_status === AppStatus.ENABLED ? (
                                <Trans>Enabled</Trans>
                            ) : appInfo ? (
                                appInfo.app_status
                            ) : (
                                "---"
                            )}
                        </Typography>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Run State:</Trans>}</Typography>

                        <Typography
                            style={{
                                marginLeft: "0.3125em",
                                color:
                                    appInfo && appInfo.run_state === RunState.DELETED
                                        ? "var(--label-negative-color)"
                                        : appInfo && appInfo.run_state === RunState.DISABLED
                                        ? "var(--label-warning-color)"
                                        : appInfo && appInfo.run_state === RunState.LIMITED
                                        ? "var(--label-warning-color)"
                                        : appInfo && appInfo.run_state === RunState.RESTRICTED
                                        ? "var(--label-warning-color)"
                                        : appInfo && appInfo.run_state === RunState.UNLIMITED
                                        ? "var(--label-positive-color)"
                                        : "var(--label-default-color)",
                            }}
                            noWrap
                        >
                            {appInfo && appInfo.run_state === RunState.DELETED ? (
                                <Trans>Deleted</Trans>
                            ) : appInfo && appInfo.run_state === RunState.DISABLED ? (
                                <Trans>Disabled</Trans>
                            ) : appInfo && appInfo.run_state === RunState.LIMITED ? (
                                <Trans>Limited</Trans>
                            ) : appInfo && appInfo.run_state === RunState.RESTRICTED ? (
                                <Trans>Restricted</Trans>
                            ) : appInfo && appInfo.run_state === RunState.UNLIMITED ? (
                                <Trans>Unlimited</Trans>
                            ) : appInfo ? (
                                appInfo.run_state
                            ) : (
                                "---"
                            )}
                        </Typography>
                    </span>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        content: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",

            padding: "0.3125em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    });

export default withStyles(styles)(AppStatusSection);
