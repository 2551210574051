// Import libraries.
import { useCallback, useEffect, useRef } from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classnames from "classnames";

// Import types.
import { ActionDefinition, ColumnDefinition, RowData } from "../../types";

// Import components.
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import FieldWrapper from "components/common/form/FieldWrapper";

interface OWN_PROPS {
    id: string;

    className?: string;

    definitions: ColumnDefinition[];

    selectMode?: "single" | "multi"; // The selection mode behaviour ("single" or "multi" mode, applies to the current page. Changing the page clears the selections).
    allSelected?: boolean;

    orderBy: string | null;
    orderDirection: "asc" | "desc";

    actions?: ActionDefinition[] | ((row: RowData) => React.ReactNode);

    actionColumnWidth?: string | number;

    swapSelectAndActionColumns?: boolean; // Determines whether the "select" and "actions" columns are swapped (such that actions is on the left and the select is on the right).

    onSort?: (orderBy: string, orderDirection: "asc" | "desc") => void;

    onHeightCalculated: ((height: number) => void) | null;
    onStickyWidthCalculated: ((width: number) => void) | null;

    onToggleAllSelected?: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const Header = (props: PROPS) => {
    const { classes, id, className, definitions, selectMode, allSelected, orderBy, orderDirection, actions, actionColumnWidth, swapSelectAndActionColumns, onSort, onHeightCalculated, onStickyWidthCalculated, onToggleAllSelected } = props;

    const headerRef = useRef<HTMLTableSectionElement | null>(null);

    const stickyColumnLeftRef = useRef<HTMLHeadElement | null>(null);
    const stickyColumnRightRef = useRef<HTMLHeadElement | null>(null);

    useEffect(() => {
        if (onHeightCalculated && headerRef.current) {
            onHeightCalculated(headerRef.current.offsetHeight);
        }

        if (onStickyWidthCalculated && (stickyColumnLeftRef.current || stickyColumnRightRef.current)) {
            onStickyWidthCalculated((stickyColumnLeftRef.current?.offsetWidth || 0) + (stickyColumnRightRef.current?.offsetWidth || 0));
        }
    });

    const handleToggleAllSelected = useCallback(() => {
        if (onToggleAllSelected) {
            onToggleAllSelected();
        }
    }, [onToggleAllSelected]);

    return (
        <TableHead ref={headerRef} id={id} className={classnames(classes.root, className)}>
            <TableRow>
                {!swapSelectAndActionColumns && (
                    <>
                        {selectMode && (
                            <TableCell ref={stickyColumnLeftRef} id={id + "-select"} className={classes.stickyColumnLeft} padding="checkbox" style={{ zIndex: 9, minWidth: "2em", width: "2em", maxWidth: "2rem", padding: 0 }}>
                                {selectMode === "multi" && (
                                    <div
                                        style={{
                                            flex: "1 1 auto",
                                            alignSelf: "center",

                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",

                                            overflow: "hidden",
                                        }}
                                    >
                                        <FieldWrapper
                                            style={{ margin: 0 }}
                                            controlStyle={{ zIndex: 7, backgroundColor: "transparent" }}
                                            innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                            name={"selectAll"}
                                            type={"checkbox"}
                                            value={allSelected}
                                            onChange={handleToggleAllSelected}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        )}
                    </>
                )}

                {swapSelectAndActionColumns && (
                    <>
                        {actions && (typeof actions === "function" || actions.length > 0) && (
                            <TableCell
                                ref={stickyColumnRightRef}
                                id={id + "-actions"}
                                className={classes.stickyColumnRight}
                                style={{
                                    zIndex: 9,

                                    minWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    width: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    maxWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                }}
                            ></TableCell>
                        )}
                    </>
                )}

                {definitions.map((definition: ColumnDefinition, idx: number) => (
                    <TableCell
                        key={definition.id}
                        id={id + "-" + idx}
                        data-name={definition.id}
                        align={definition.align}
                        style={{ minWidth: definition.width, width: definition.width, maxWidth: definition.width, zIndex: 8, padding: "0.3125em" }}
                        sortDirection={orderBy === definition.id ? orderDirection : false}
                    >
                        {definition.sortable && (
                            <TableSortLabel
                                style={{ paddingLeft: definition.align === "center" ? "1.375em" : 0 }}
                                active={orderBy === definition.id}
                                direction={orderBy === definition.id ? orderDirection : "asc"}
                                onClick={() => {
                                    if (onSort && definition.sortable) {
                                        const newOrderDirection = definition.id === orderBy ? (orderDirection === "desc" ? "asc" : "desc") : "asc";

                                        onSort(definition.id, newOrderDirection);
                                    }
                                }}
                            >
                                <span style={{ whiteSpace: "nowrap" }}>{definition.label}</span>

                                {orderBy === definition.id ? <span className={classes.visuallyHidden}>{orderDirection === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                            </TableSortLabel>
                        )}

                        {!definition.sortable && <span style={{ whiteSpace: "nowrap" }}>{definition.label}</span>}
                    </TableCell>
                ))}

                {!swapSelectAndActionColumns && (
                    <>
                        {actions && (typeof actions === "function" || actions.length > 0) && (
                            <TableCell
                                ref={stickyColumnRightRef}
                                id={id + "-actions"}
                                className={classes.stickyColumnRight}
                                style={{
                                    zIndex: 9,

                                    minWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    width: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    maxWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                }}
                            ></TableCell>
                        )}
                    </>
                )}

                {swapSelectAndActionColumns && (
                    <>
                        {selectMode && (
                            <TableCell ref={stickyColumnLeftRef} id={id + "-select"} className={classes.stickyColumnRight} padding="checkbox" style={{ zIndex: 9, minWidth: "2em", width: "2em", maxWidth: "2em", padding: 0 }}>
                                {selectMode === "multi" && (
                                    <div
                                        style={{
                                            alignSelf: "center",

                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",

                                            overflow: "hidden",
                                        }}
                                    >
                                        <FieldWrapper
                                            style={{ margin: 0 }}
                                            controlStyle={{ backgroundColor: "transparent" }}
                                            innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                            name={"select"}
                                            type={"checkbox"}
                                            value={allSelected}
                                            onChange={handleToggleAllSelected}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        )}
                    </>
                )}
            </TableRow>
        </TableHead>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",
        },
        visuallyHidden: {
            display: "none",
        },
        stickyColumnLeft: {
            position: "sticky",
            left: 0,
        },
        stickyColumnRight: {
            position: "sticky",
            right: 0,

            paddingLeft: "0.5em",
            paddingRight: "0.5em",
        },
    });

export default withStyles(styles)(Header);
