// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import QueryString from "query-string";
import { connect } from "react-redux";

// Import types.
import User from "types/common/User";
import PortalState from "types/store";
import SupportedLanguage from "types/enums/SupportedLanguage";

// Import components.
import { Divider, MenuItem, Popover, Tooltip, Typography } from "@material-ui/core";
import CustomDialog from "components/common/dialog/CustomDialog";
import IconButton from "components/common/button/IconButton";

// Import icons.
import HelpIcon from "@material-ui/icons/Help";

// Import services.
import BrainCloudProperties from "services/BrainCloudProperties";

export interface AdditionalLink {
    label: {
        en: string;
        fr: string;
    };
    url: string;
}

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    style: React.CSSProperties;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

const mapStateToProps = (state: PortalState) => {
    return { currentUser: state.currentUser };
};
const mapDispatchToProps = () => {
    return {};
};

interface STATE {
    additionalLinks: AdditionalLink[] | null;
    anchorEl: any;
}

class Help extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        additionalLinks: null,
        anchorEl: null,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        let additionalLinks: any = null;

        try {
            additionalLinks = await BrainCloudProperties.getSystemProperty("portalxHeaderHelpLinks");

            this.setState({ additionalLinks: additionalLinks });
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            additionalLinks = [];
        }

        this.setState({ additionalLinks: additionalLinks });
    };

    onToggleOpen = (event?: any) => {
        this.setState({ anchorEl: this.state.anchorEl == null && event ? event.currentTarget : null });
    };

    onAboutClicked = () => {
        this.onToggleOpen();

        window.setTimeout(() => {
            window.open(window.origin + "/about");
        }, 500);
    };

    onLinkClicked = (url: string) => {
        const { history } = this.props;

        this.onToggleOpen();

        if (url.startsWith("#")) {
            const parts = url.substring(1).split("?");

            let targetPath: string | null = null;
            let targetState: any = null;

            if (parts.length > 0) {
                targetPath = parts[0];

                if (parts.length > 1) {
                    targetState = QueryString.parse(parts[1].substring(1));
                }
            }

            if (targetPath) {
                history.push({ pathname: targetPath, state: targetState });
            }
        } else {
            window.setTimeout(() => {
                window.open(url);
            }, 500);
        }
    };

    renderContent = () => {
        const { classes, currentUser } = this.props;
        const { additionalLinks } = this.state;

        return (
            <div className={classes.content}>
                {additionalLinks &&
                    additionalLinks.length > 0 &&
                    additionalLinks.map((item, idx) => {
                        return (
                            <MenuItem key={"link" + idx} component={"div"} className={classes.helpItem} onClick={() => this.onLinkClicked(item.url)}>
                                <Typography>{currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? item.label.fr : item.label.en}</Typography>
                            </MenuItem>
                        );
                    })}

                {additionalLinks && additionalLinks.length > 0 && <Divider style={{ backgroundColor: "var(--popup-menu-border-color, inherit)", marginTop: "auto" }} />}

                <MenuItem component={"div"} className={classes.helpItem} onClick={this.onAboutClicked}>
                    <Typography>
                        <Trans>About</Trans>
                    </Typography>
                </MenuItem>
            </div>
        );
    };

    render() {
        const { classes, style } = this.props;
        const { anchorEl } = this.state;

        return (
            <div id={"help"} className={classes.root} style={style}>
                <Tooltip arrow title={<Trans>Additional Help</Trans>}>
                    <IconButton id={"toggle-open"} style={{ width: "100%", height: "100%", margin: 0, padding: 0 }} onClick={this.onToggleOpen}>
                        <HelpIcon />
                    </IconButton>
                </Tooltip>

                {!isMobile && (
                    <Popover
                        id={"popover-help"}
                        className={classes.popover}
                        open={Boolean(anchorEl)}
                        onClose={this.onToggleOpen}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "2.875em", height: "2.875em" }}>
                            <Typography style={{ fontWeight: "bold" }}>
                                <Trans>Help</Trans>
                            </Typography>
                        </span>

                        <Divider style={{ backgroundColor: "var(--popup-menu-border-color, inherit)" }} />

                        {this.renderContent()}
                    </Popover>
                )}

                {isMobile && <CustomDialog id={"help"} className={classes.dialog} open={Boolean(anchorEl)} ready={true} onClose={this.onToggleOpen} header={<Trans>Help</Trans>} content={this.renderContent()} />}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
        },
        popover: {
            "& .MuiPopover-paper": {
                minWidth: "20em",
            },
        },
        dialog: {
            "& .MuiDialogContent-root": {
                padding: 0,
            },
        },
        content: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            overflow: "auto",

            "& > .MuiMenuItem-root": {
                borderColor: "inherit",

                "&:hover": {
                    backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                    color: "var(--popup-menu-hover-color, inherit)",
                },
            },
        },
        helpItem: {
            minHeight: "2.875em",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                flex: "0 0 auto",
                width: "1.5em",
                height: "1.5em",
            },
            "& .MuiTypography-root": {
                flex: "1 1 auto",
                marginRight: "0.5em",
                marginLeft: "0.5em",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Help)));
