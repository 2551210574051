import { EnvironmentInformationAction } from "store/actions/env";

import EnvironmentInformation from "types/common/EnvironmentInformation";

const initialState: EnvironmentInformation = {
    supportEmail: null,

    portalDomainName: null,
    portalxDomainName: null,
};

const reducer = (state: EnvironmentInformation = initialState, action: EnvironmentInformationAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_ENVIRONMENT_INFORMATION":
            newState = Object.assign({}, action.payload);

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
