// Import libraries.
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import PortalRoute from "types/common/PortalRoute";

// Import components.
import { Divider } from "@material-ui/core";
import NavigatorRouteItem from "./NavigatorRouteItem";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";
import CloneUtils from "utils/Clone";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    availablePrivileges: PortalPrivilege[];
    favorites: string[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    context: "super" | "team" | "app";

    definitions: PortalRouteDefinition[];

    itemHeight: number;

    onNavigation: (pathChanged: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        availablePrivileges: state.availablePrivileges,
        favorites: state.favorites,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

interface STATE {
    availableRoutes: PortalRoute[];
    flyoutPath: string | null;
}

class NavigatorRouteTree extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        availableRoutes: [],
        flyoutPath: null,
    };
    private flyoutRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const { definitions } = this.props;

        const availableRoutes = PortalRouteUtils.generatePortalRoutesFromDefinitions(definitions);

        this.setState({ availableRoutes: availableRoutes }, () => {
            this.refreshStateBasedOnLocation();
        });
    }

    componentDidUpdate(prevProps: PROPS) {
        const { definitions, location } = this.props;
        const { availableRoutes } = this.state;

        // Define a "postProcess" function that gets exeucted at the end of the update (if necessary).
        // This function will trigger the normal logic that happens when the location path or context changes.
        const postProcess = () => {
            if (prevProps.location.pathname !== location.pathname) {
                this.refreshStateBasedOnLocation();
            }
        };

        if (prevProps.definitions !== definitions) {
            // If the route definitions have changed we need to update the local "availableRoutes" state to reflect any changes.
            let updatedRoutes = CloneUtils.clone(availableRoutes) as PortalRoute[];

            // Generate a new set of available routes based on the new route definitions.
            const newlyAvailableRoutes = PortalRouteUtils.generatePortalRoutesFromDefinitions(definitions);

            // Remove any available routes that no longer exist.
            updatedRoutes = updatedRoutes.filter((route) => {
                return newlyAvailableRoutes.find((newRoute) => newRoute.path === route.path);
            });

            // Add any newly available routes.
            newlyAvailableRoutes.forEach((newRoute) => {
                if (!updatedRoutes.find((route) => route.path === newRoute.path)) {
                    updatedRoutes.push(newRoute);
                }
            });

            // Update the local "availableRoutes" state.
            this.setState({ availableRoutes: updatedRoutes }, () => {
                postProcess();
            });
        } else {
            postProcess();
        }
    }

    refreshStateBasedOnLocation() {
        const { location } = this.props;
        const { availableRoutes } = this.state;

        const currentPath = location.pathname;

        const currentPathContext = currentPath.startsWith("/super") ? "super" : currentPath.startsWith("/team") ? "team" : currentPath.startsWith("/app") ? "app" : null;

        const updatedRoutes = CloneUtils.clone(availableRoutes) as PortalRoute[];

        const superRoutes = updatedRoutes.filter((route) => PortalRouteUtils.sanitizeRoutePath(route.path).startsWith("/super"));
        const teamRoutes = updatedRoutes.filter((route) => PortalRouteUtils.sanitizeRoutePath(route.path).startsWith("/team"));
        const appRoutes = updatedRoutes.filter((route) => PortalRouteUtils.sanitizeRoutePath(route.path).startsWith("/app"));

        const routesInCurrentPath = PortalRouteUtils.getPortalRoutesInPath(updatedRoutes, currentPath);

        switch (currentPathContext) {
            case "super":
                superRoutes.forEach((route) => {
                    if (routesInCurrentPath.includes(route)) {
                        route.expanded = true;
                        route.selected = true;
                    } else {
                        route.expanded = false;
                        route.selected = false;
                    }
                });
                break;
            case "team":
                teamRoutes.forEach((route) => {
                    if (routesInCurrentPath.includes(route)) {
                        route.expanded = true;
                        route.selected = true;
                    } else {
                        route.expanded = false;
                        route.selected = false;
                    }
                });
                break;
            case "app":
                appRoutes.forEach((route) => {
                    if (routesInCurrentPath.includes(route)) {
                        route.expanded = true;
                        route.selected = true;
                    } else {
                        route.expanded = false;
                        route.selected = false;
                    }
                });
                break;
            default:
            // Do nothing.
        }

        this.setState({
            availableRoutes: updatedRoutes,
        });
    }

    onExpandOrCollapsePath = (path: string) => {
        const { context, definitions } = this.props;
        const { availableRoutes } = this.state;

        const updatedRoutes = CloneUtils.clone(availableRoutes) as PortalRoute[];

        const routesForContext = updatedRoutes.filter((route) => PortalRouteUtils.sanitizeRoutePath(route.path).startsWith("/" + context));

        const routesInPath = PortalRouteUtils.getPortalRoutesInPath(routesForContext, path);

        const targetRoute = routesInPath[routesInPath.length - 1];
        const ancestorRoutes = routesInPath.slice(0, routesInPath.length - 1);
        const parentRoute = ancestorRoutes[ancestorRoutes.length - 1];

        const parentDefinition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, PortalRouteUtils.sanitizeRoutePath(parentRoute.path));

        const otherRoutes = routesForContext.filter(
            (route) =>
                PortalRouteUtils.sanitizeRoutePath(route.path).startsWith(PortalRouteUtils.sanitizeRoutePath(parentRoute.path)) &&
                PortalRouteUtils.sanitizeRoutePath(route.path) !== PortalRouteUtils.sanitizeRoutePath(parentRoute.path) &&
                !PortalRouteUtils.sanitizeRoutePath(route.path).startsWith(PortalRouteUtils.sanitizeRoutePath(targetRoute.path))
        );

        let siblingRoutes: PortalRoute[] = [];
        if (parentDefinition && parentDefinition.routes) {
            parentDefinition.routes.forEach((route) => {
                const candidateRoute = otherRoutes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === PortalRouteUtils.sanitizeRoutePath(parentRoute.path) + "/" + PortalRouteUtils.sanitizeRoutePath(route.path));

                if (candidateRoute) {
                    siblingRoutes.push(candidateRoute);
                }
            });
        }

        if (routesInPath.length > 1) {
            for (let x = 1; x < ancestorRoutes.length; x++) {
                ancestorRoutes[x].expanded = true;
            }

            siblingRoutes.forEach((route) => {
                route.expanded = false;
            });

            targetRoute.expanded = !targetRoute.expanded;

            this.setState({ availableRoutes: updatedRoutes });
        }
    };

    onNavigateToPath = (path: string) => {
        const { history, location } = this.props;

        this.onChangeFlyout(null);

        if (path !== location.pathname) {
            history.push(path);

            this.props.onNavigation(true);
        } else {
            this.props.onNavigation(false);
        }
    };

    onChangeFlyout = (path: string | null) => {
        const { flyoutPath } = this.state;

        this.setState({ flyoutPath: flyoutPath !== path ? path : null });
    };

    determineNavItemHeight = (path: string, definition: PortalRouteDefinition) => {
        const { itemHeight, session, currentUser, availableCompanies, availableApps, availablePrivileges } = this.props;
        const { availableRoutes } = this.state;

        const isAccessible = !definition.hasAccess || definition.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges);

        let height = 0;

        if (isAccessible) {
            height = itemHeight;
        }

        const route = availableRoutes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === path);

        if (route && route.expanded && definition.routes && definition.routes.length > 0) {
            for (let x = 0; x < definition.routes.length; x++) {
                height += this.determineNavItemHeight(path + PortalRouteUtils.PATH_SEP + definition.routes[x].path, definition.routes[x]);
            }
        }

        return height;
    };

    renderNavItem = (basePath: string, definition: PortalRouteDefinition, indent: number): React.ReactNode => {
        const { i18n, classes, session, currentUser, availableCompanies, availableApps, availablePrivileges, context, itemHeight, definitions, favorites } = this.props;
        const { availableRoutes, flyoutPath } = this.state;

        const basePathComponents = PortalRouteUtils.routePathComponents(basePath);

        const route = availableRoutes.find((route) => PortalRouteUtils.sanitizeRoutePath(route.path) === basePath + PortalRouteUtils.PATH_SEP + PortalRouteUtils.sanitizeRoutePath(definition.path));

        if (!route) return null;

        let hasNoAccess = definition.hasAccess && !definition.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges);

        if (!hasNoAccess) {
            switch (context) {
                case "super":
                    if (!session.isSuper || !session.companyId) hasNoAccess = true;

                    break;
                case "team":
                    if (session.isSuper && !session.companyIdAlias) hasNoAccess = true;
                    if (!session.isSuper && !session.companyId) hasNoAccess = true;

                    break;
                case "app":
                    if (!session.appId) hasNoAccess = true;

                    break;
                default:
                // Do nothing.
            }
        }

        // If the user has no access to the navigation item, then just return null;
        if (hasNoAccess) return null;

        // Apply styling (height, indentation padding etc).
        const navItemStyle: CSSProperties = {
            height: itemHeight + "em",
            paddingLeft: "calc(0.5625em + calc(1em * " + indent + "))",
        };

        // Determine the height of the navigation item (if it we fully expanded).
        const navItemHeight = this.determineNavItemHeight(PortalRouteUtils.sanitizeRoutePath(route.path), definition);

        // Determine the height of the navigation items "expanded" section.
        const navItemWrapperHeight = navItemHeight - itemHeight;

        // Determine if this navigation item represents the "Favorites".
        const isFavorites = ["/super/favorites", "/team/favorites", "/app/favorites"].includes(route.path);

        // Determine if this navigation item represents an "expandable" item.
        const isExpandable = !isFavorites && definition.routes && definition.routes.length > 0;

        // Determine if this navigation item should have a "flyout".
        const hasFlyout = isFavorites || (isExpandable && basePathComponents.length === 1 && basePathComponents[0] === "app");

        return (
            <React.Fragment key={PortalRouteUtils.sanitizeRoutePath(route.path)}>
                <div style={{ borderColor: "inherit" }}>
                    <NavigatorRouteItem
                        id={route.path}
                        style={navItemStyle}
                        icon={definition.icon}
                        label={definition.label(i18n)}
                        onClick={() => {
                            if (isExpandable) {
                                this.onExpandOrCollapsePath(PortalRouteUtils.sanitizeRoutePath(route.path));
                            } else {
                                if (isFavorites) {
                                    this.onChangeFlyout(PortalRouteUtils.sanitizeRoutePath(route.path));
                                } else {
                                    this.onNavigateToPath(PortalRouteUtils.sanitizeRoutePath(route.path));
                                }
                            }
                        }}
                        toggleFlyout={hasFlyout ? () => this.onChangeFlyout(PortalRouteUtils.sanitizeRoutePath(route.path)) : undefined}
                        onNavigateToPath={this.onNavigateToPath}
                        context={context}
                        definitions={definitions}
                        flyoutPath={flyoutPath}
                        selected={route.selected}
                        expandable={isExpandable}
                        expanded={isExpandable && route.expanded}
                        favorite={favorites.includes(route.path)}
                    />

                    {basePathComponents.length >= 1 && definition.routes && (
                        <div className={classes.wrapper} style={{ height: navItemWrapperHeight + "em", transition: "height 0.2s linear" }}>
                            {definition.routes.map((item) => this.renderNavItem(PortalRouteUtils.sanitizeRoutePath(route.path), item, indent + 1))}
                        </div>
                    )}
                </div>

                {isFavorites && <Divider key={"favorites-divider"} className={classes.divider} />}
            </React.Fragment>
        );
    };

    render() {
        const { classes, context, definitions } = this.props;

        let basePath = "/" + context;

        const baseDefinition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, basePath);

        if (!baseDefinition || !baseDefinition.routes || baseDefinition.routes.length === 0) return null;

        return (
            <>
                <div ref={this.flyoutRef} className={classes.root}>
                    {baseDefinition.routes.map((route) => this.renderNavItem(basePath, route, 0))}
                </div>
            </>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            paddingBottom: "13em",
        },
        wrapper: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        divider: {
            backgroundColor: "var(--navigation-border-color, inherit)",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withStyles(styles)(NavigatorRouteTree))));
