// Import libraries.
import React from "react";
import { Theme, createStyles, withStyles, WithStyles, Typography, Link } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import { BulkActionDefinition, FilterData, FilterDefinition, FilterOptions, RowData } from "../../types";
import { MonacoFieldOptions } from "components/common/form/fields/MonacoField";

// Import components.
import Button from "components/common/button/Button";
import FieldWrapper from "components/common/form/FieldWrapper";
import BasicFilter from "../../filters/BasicFilter";

// Import icons.
import CheckIcon from "@material-ui/icons/Check";

interface OWN_PROPS {
    id: string;

    className?: string;

    selectedRows: RowData[];

    filters?: FilterDefinition[];
    filterData?: FilterData | null;

    customQuery?: {
        key: string;
        recommendedKeys: string[];
        sample?: string;
        active?: boolean;
    };

    bulkActions?: BulkActionDefinition[];

    onFilterChanged?: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    customQueryOpen: boolean;
    customQueryValue: string | null;
}

class Toolbar extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        customQueryOpen: false,
        customQueryValue: null,
    };

    openCustomQuery = () => {
        const { customQuery } = this.props;
        const { customQueryValue } = this.state;

        if (customQuery) {
            this.setState({ customQueryOpen: true, customQueryValue: customQueryValue != null ? customQueryValue : customQuery?.sample || null });
        }
    };

    closeCustomQuery = () => {
        const { customQuery } = this.props;

        if (customQuery) {
            this.setState({ customQueryOpen: false });
        }
    };

    applyCustomQuery = () => {
        const { customQuery } = this.props;
        const { customQueryValue } = this.state;

        if (customQuery) {
            if (this.props.onFilterChanged) {
                this.props.onFilterChanged(customQuery.key, customQueryValue);
            }
        }
    };

    clearCustomQuery = () => {
        const { customQuery } = this.props;

        if (customQuery) {
            this.setState({ customQueryValue: customQuery.sample || null }, () => {
                if (this.props.onFilterChanged) {
                    this.props.onFilterChanged(customQuery.key, null);
                }
            });
        }
    };

    render() {
        const { classes, id, className, filters, filterData, customQuery, bulkActions, selectedRows } = this.props;
        const { customQueryOpen, customQueryValue } = this.state;

        if (!filters && !bulkActions) return null;

        return (
            <>
                <div id={id} className={classnames(classes.root, className)}>
                    <div id={id + "-filters"} className={classes.filters}>
                        {filters &&
                            filters.map((filter: FilterDefinition, idx: number) => {
                                const hidden = typeof filter.hidden === "function" ? filter.hidden() : filter.hidden === true;

                                let disabled = typeof filter.disabled === "function" ? filter.disabled() : filter.disabled === true;

                                if (typeof filter.type === "string") {
                                    // If a the "custom query" is open, then all other filters become disabled.
                                    if (customQueryOpen) {
                                        disabled = true;
                                    }

                                    // If the filter type is a string, then it's a basic filter (using a field type).
                                    return (
                                        <div key={idx} id={id + "-filter-" + idx} data-name={filter.id} style={filter.style} className={classnames({ [classes.visuallyHidden]: hidden })}>
                                            <BasicFilter
                                                name={filter.id}
                                                type={filter.type}
                                                label={filter.label}
                                                labelPosition={filter.labelPosition}
                                                labelAlignment={filter.labelAlignment}
                                                value={filterData != null ? filterData[filter.id] : null}
                                                required={["select"].includes(filter.type)}
                                                disabled={disabled}
                                                onChange={(name: string, value: any) => {
                                                    this.setState({ customQueryOpen: false, customQueryValue: null }, () => {
                                                        if (this.props.onFilterChanged) {
                                                            this.props.onFilterChanged(name, value);
                                                        }
                                                    });
                                                }}
                                                options={filter.options}
                                            />
                                        </div>
                                    );
                                } else {
                                    // Otherwise the filter is a custom filter.
                                    // In this case the "type" is actually a component class.
                                    const CustomFilter = filter.type as React.ComponentType<{ name: string; value?: any; onChange: (name: string, value: any) => void; options?: FilterOptions }>;

                                    return (
                                        <div key={idx} id={id + "-filter-" + idx} data-name={filter.id} style={filter.style} className={classnames({ [classes.visuallyHidden]: filter.hidden })}>
                                            <CustomFilter
                                                name={filter.id}
                                                value={filterData != null ? filterData[filter.id] : null}
                                                onChange={(name: string, value: any) => {
                                                    if (this.props.onFilterChanged) {
                                                        this.props.onFilterChanged(name, value);
                                                    }
                                                }}
                                                options={filter.options}
                                            />
                                        </div>
                                    );
                                }
                            })}

                        {customQuery && (
                            <Button id={"custom-query-toggle"} disabled={customQueryOpen} onClick={this.openCustomQuery} endIcon={customQuery.active ? <CheckIcon /> : undefined}>
                                <Trans>Refine</Trans>
                            </Button>
                        )}
                    </div>

                    <div id={id + "-bulk-actions"} className={classes.bulkActions}>
                        {bulkActions &&
                            bulkActions.map((bulkAction: BulkActionDefinition, idx: number) => {
                                const hidden = typeof bulkAction.hidden === "function" ? bulkAction.hidden(selectedRows) : bulkAction.hidden === true;
                                const disabled = typeof bulkAction.disabled === "function" ? bulkAction.disabled(selectedRows) : bulkAction.disabled === true;

                                return (
                                    <div key={idx} id={id + "-bulk-action-" + idx} data-name={bulkAction.id} className={classnames({ [classes.visuallyHidden]: hidden, [classes.visuallyDisabled]: disabled })}>
                                        <div
                                            style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}
                                            onClick={() => {
                                                if (!disabled) bulkAction.action(selectedRows);
                                            }}
                                        >
                                            {bulkAction.render(selectedRows)}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {customQuery && customQueryOpen && (
                    <span style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", padding: "0.3125em", borderStyle: "solid", borderColor: "inherit", borderRadius: "0.25em", borderWidth: "0.06125em" }}>
                        <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }}>
                            <Trans>Advanced Filter</Trans>
                        </Typography>

                        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "stretch" }}>
                            <span style={{ flex: "1 1 0px", display: "flex", flexDirection: "column" }}>
                                <FieldWrapper
                                    name={customQuery.key}
                                    style={{ flex: "1 1 auto" }}
                                    controlStyle={{ minHeight: "8em" }}
                                    type={"monaco"}
                                    value={customQueryValue}
                                    options={
                                        {
                                            mode: "json",
                                            recommendedKeys: customQuery.recommendedKeys,
                                        } as MonacoFieldOptions
                                    }
                                    onChange={(_name: string, value: any) => {
                                        this.setState({ customQueryValue: value });
                                    }}
                                />
                            </span>

                            <span style={{ flex: "1 1 0px", display: "flex", flexDirection: "column" }}>
                                <Typography style={{ flex: "1 1 auto" }}>
                                    <Trans>
                                        Specify a custom filter using JSON syntax. Custom entity fields can be specified by prefixing with \"data\". Accepts advanced logical ($or, $not) and comparison operations ($ne, $gt, $gte, $lt and $lte).
                                    </Trans>
                                    &nbsp;
                                    <Link href="https://getbraincloud.com/apidocs/apiref/#appendix-mongodbwherequeries" rel="noreferrer" target="_blank">
                                        <Trans>Click for more info...</Trans>
                                    </Link>
                                </Typography>

                                <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                                    <span style={{ flex: "0 0 auto" }}>
                                        <Button id={"clear-custom-query"} onClick={this.clearCustomQuery}>
                                            <Trans>Clear</Trans>
                                        </Button>
                                    </span>

                                    <span style={{ flex: "1 1 auto", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <Button id={"close-custom-query"} onClick={this.closeCustomQuery}>
                                            <Trans>Close</Trans>
                                        </Button>

                                        <Button id={"apply-custom-query"} onClick={this.applyCustomQuery}>
                                            <Trans>Apply</Trans>
                                        </Button>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </span>
                )}
            </>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "inherit",
            padding: "0.3125em",
            "@media(max-width: 45em)": {
                display: "block",
            },
        },
        filters: {
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& > *:not(:first-child)": {
                marginLeft: "0.3125em",
            },
        },
        bulkActions: {
            flex: "0 0 auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            marginLeft: "0.3125em",
            "& > *:not(:first-child)": {
                marginLeft: "0.3125em",
            },
        },
        visuallyHidden: {
            display: "none",
        },
        visuallyDisabled: {
            cursor: "not-allowed",
        },
    });

export default withStyles(styles)(Toolbar);
