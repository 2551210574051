// Import libraries.
import React, { ReactNode } from "react";
import { Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

// Import components.
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import classnames from "classnames";

// Define the various properties accepted by this component.
interface OWN_PROPS {
    className?: string;
    type?: "circular" | "linear";
    label?: ReactNode;
    hideLabel?: boolean | null;
    indicatorWidth?: number | string;
    value?: number | null;
    disablePadding?: boolean | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

// Styling for this component.
const DEFAULT_INDICATOR_WIDTH = "4rem";

class LoadingProgress extends React.Component<PROPS> {
    render() {
        const { classes, className, type, label, hideLabel, indicatorWidth, value, disablePadding } = this.props;

        if (type === "linear") {
            return (
                <div className={classnames(classes.root, !disablePadding ? classes.padding : null, className)}>
                    {value != null && <LinearProgress variant={"determinate"} value={value} style={{ width: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH }} />}
                    {value == null && <LinearProgress style={{ width: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH }} />}

                    {!hideLabel && (
                        <span className={classes.label}>
                            {label != null && label}
                            {label == null && <Trans>Loading...</Trans>}
                        </span>
                    )}
                </div>
            );
        } else {
            return (
                <div className={classnames(classes.root, !disablePadding ? classes.padding : null, className)}>
                    {value != null && <CircularProgress variant={"determinate"} value={value} style={{ width: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH, height: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH }} />}
                    {value == null && <CircularProgress style={{ width: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH, height: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH }} />}

                    {!hideLabel && (
                        <span className={classes.label}>
                            {label != null && label}
                            {label == null && <Trans>Loading...</Trans>}
                        </span>
                    )}
                </div>
            );
        }
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            "& > *:first-child": {
                color: "var(--progress-indicator-color, inherit)",
            },

            minWidth: "2em",
        },
        padding: {
            padding: "0.3125rem",
        },
        label: {
            color: "var(--progress-indicator-text-color, inherit)",

            marginTop: "0.625rem",
        },
    });

export default withStyles(styles)(LoadingProgress);
