// Import libraries.
import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import { PasswordFieldOptions } from "components/common/form/fields/PasswordField";

// Import components.
import { Chip, Typography } from "@material-ui/core";
import CustomDialog from "components/common/dialog/CustomDialog";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";

interface OWN_PROPS {
    details: React.ReactNode;
    appName: string;
    onClose: (submitted?: boolean, appName?: string | null) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    appName: string;
    appNameStatus: "does_not_match" | null;
}

class VerifyAppNameDialog extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        appName: "",
        appNameStatus: null,
    };
    private confirmButtonRef = React.createRef<HTMLElement>();

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "appName":
                this.setState({ appNameStatus: null, appName: value != null ? value : "" });

                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["appName"].includes(name) && key === "Enter") {
            this.confirmButtonRef.current?.focus();

            this.onConfirm();
        }
    };

    onAbort = () => {
        this.props.onClose(false);
    };

    onConfirm = () => {
        const { bcFormValidator } = this.props;

        if (!bcFormValidator.validate()) return;

        this.setState({ appNameStatus: null }, () => {
            if (this.state.appName.trim() === this.props.appName.trim()) {
                this.props.onClose(true, this.state.appName);
            } else {
                this.setState({ appNameStatus: "does_not_match" }, bcFormValidator.validate);
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes, details } = this.props;
        const { appName, appNameStatus } = this.state;

        return (
            <CustomDialog
                id={"verify-app-name"}
                className={classes.root}
                open={true}
                ready={true}
                hideFullscreen={true}
                hideDismiss={true}
                onClose={this.props.onClose}
                header={<Trans>Confirm This Action</Trans>}
                content={
                    <div className={classes.content}>
                        <Typography>{details}</Typography>

                        <Typography style={{ fontStyle: "italic" }}>
                            <Trans>Please enter the app name to confirm this action.</Trans>
                        </Typography>

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            type={"text"}
                            name={"appName"}
                            value={appName}
                            autoFocus={true}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            options={
                                {
                                    placeholder: i18n._(t`App Name`),
                                } as PasswordFieldOptions
                            }
                        />

                        {appNameStatus === "does_not_match" && <Chip className={classes.chip} label={<Trans>App name does not match</Trans>} />}
                    </div>
                }
                actions={
                    <>
                        <Button id={"cancel"} type={"neutral"} onClick={this.onAbort}>
                            <Trans>Abort</Trans>
                        </Button>

                        <Button ref={this.confirmButtonRef} id={"confirm"} type={"primary"} onClick={this.onConfirm}>
                            <Trans>Verify</Trans>
                        </Button>
                    </>
                }
            />
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            "& > .MuiDialog-container > .MuiDialog-paper": {
                flex: "0 1 auto",
                minWidth: "unset",
                padding: "1em",
                gap: ".5em",
            },
        },
        content: {
            display: "flex",
            flexDirection: "column",
            gap: ".5em",
        },
        chip: {
            height: "2em",
            fontWeight: "bold",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },
    });

export default withFormValidator()(withI18n()(withStyles(styles)(VerifyAppNameDialog)));
