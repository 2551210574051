// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import AppDailyCounts from "types/models/AppDailyCounts";
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import { Link, Tooltip } from "@material-ui/core";
import TeamBanner from "./TeamBanner";
import AppBanner from "./AppBanner";
import PlayerBanner from "./PlayerBanner";

// Import icons.
import PipIcon from "@material-ui/icons/FiberManualRecord";

// Import services.
import Services from "./services";
import CloneUtils from "utils/Clone";
import PlayerSummaryInfo from "types/models/PlayerSummaryInfo";

interface STATE_PROPS {
    session: Session;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    open: boolean;
    mode: "team" | "app" | "player";
    onToggle: (mode: "team" | "app" | "player", open?: boolean, closeIfSame?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

interface STATE {
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
    appDailyCounts: AppDailyCounts | null;
    appCurrentUserCount: number | null;
    playerSummary: PlayerSummary | null;
    isReady: boolean;
}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = () => {
    return {};
};

class TeamAndAppInfoBanner extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        teamInfo: null,
        appInfo: null,
        appDailyCounts: null,
        appCurrentUserCount: null,
        playerSummary: null,
        isReady: false,
    };
    private _isMounted: boolean = false;

    componentDidMount() {
        this._isMounted = true;

        this.loadInfo();

        if (this.props.location.pathname.startsWith("/team")) {
            this.props.onToggle("team");
        }

        if (this.props.location.pathname.startsWith("/app")) {
            this.props.onToggle("app");
        }

        if (this.props.location.pathname.startsWith("/app/user") && this.state.playerSummary !== null) {
            this.props.onToggle("player");
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        const { mode, session, location, availableCompanies, availableApps } = this.props;

        if (prevProps.location.pathname !== location.pathname) {
            if (!prevProps.location.pathname.startsWith("/team") && location.pathname.startsWith("/team")) {
                this.props.onToggle("team");
            }

            if (!prevProps.location.pathname.startsWith("/app") && location.pathname.startsWith("/app")) {
                this.props.onToggle("app");
            }

            if (!prevProps.location.pathname.startsWith("/app/user") && location.pathname.startsWith("/app/user")) {
                this.props.onToggle("player");
            }

            if (prevProps.location.pathname.startsWith("/app/user") && !location.pathname.startsWith("/app/user") && location.pathname.startsWith("/app")) {
                this.props.onToggle("app");
            }

            // Force close the banner if we are on the Cloud Code Editor screen.
            if (location.pathname === "/app/design/cloud-code/scripts") {
                this.props.onToggle("app", false);
            }

            // Force close the banner if we are on the Team Info screen.
            if (location.pathname === "/team/setup/team-info") {
                this.props.onToggle("team", false);
            }

            // Force open the banner if we are on the Team Dashboard screen.
            if (location.pathname === "/team/dashboard") {
                this.props.onToggle("team", true);
            }

            // Force open the banner if we are on the App Dashboard screen.
            if (location.pathname === "/app/dashboard") {
                this.props.onToggle("app", true);
            }
        }

        if (mode === "player" && this.state.playerSummary === null) {
            if (location.pathname.startsWith("/app")) {
                this.props.onToggle("app");
            } else {
                this.props.onToggle("team");
            }
        }

        if (
            prevProps.session.companyId !== session.companyId ||
            prevProps.session.companyIdAlias !== session.companyIdAlias ||
            prevProps.session.appId !== session.appId ||
            prevProps.session.playerId !== session.playerId ||
            prevProps.availableCompanies !== availableCompanies ||
            prevProps.availableApps !== availableApps
        ) {
            this.loadInfo();
        }

        if (prevProps.session.playerSummary !== session.playerSummary) {
            this.setState({ playerSummary: CloneUtils.clone(session.playerSummary) as PlayerSummaryInfo });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo = async () => {
        const { session } = this.props;

        if (!this._isMounted) return;

        this.setState({ teamInfo: null, appInfo: null, appDailyCounts: null, appCurrentUserCount: null, playerSummary: null, isReady: false });

        try {
            const companyId = session.isSuper ? session.companyIdAlias : session.companyId;
            const appId = session.appId;
            const playerId = session.playerId;

            const promiseBatch = await Promise.all([
                companyId ? Services.getTeam(companyId) : null,
                companyId && appId ? Services.getApps() : null,
                companyId && appId ? Services.getAppDailyCounts() : null,
                companyId && appId ? Services.getAppCurrentUserCount() : null,
                companyId && appId && playerId ? Services.getPlayerSummary(playerId) : null,
            ]);

            const teamInfo = promiseBatch[0];
            const appInfo = (promiseBatch[1] || []).find((item) => item.appId === appId) || null;
            const appDailyCounts = promiseBatch[2];
            const appCurrentUserCount = promiseBatch[3];
            const playerSummary = promiseBatch[4];

            if (!this._isMounted) return;

            this.setState({
                teamInfo: teamInfo ? teamInfo : null,
                appInfo: appInfo ? appInfo : null,
                appDailyCounts: appDailyCounts ? appDailyCounts : null,
                appCurrentUserCount: appCurrentUserCount,
                playerSummary: playerSummary,
                isReady: true,
            });
        } catch (error) {
            if (!this._isMounted) return;

            this.setState({ appDailyCounts: null, appCurrentUserCount: null, playerSummary: null, isReady: true });
        }
    };

    render() {
        const { location, classes, open, mode } = this.props;
        const { teamInfo, appInfo, appDailyCounts, appCurrentUserCount, playerSummary } = this.state;

        return (
            <div className={classnames({ [classes.root]: true, [classes.closed]: !open || location.pathname.startsWith("/super") || location.pathname === "/team/setup/team-info" })}>
                <div className={classes.banner}>
                    {mode === "team" && <TeamBanner teamInfo={teamInfo} />}
                    {mode === "app" && <AppBanner appInfo={appInfo} appDailyCounts={appDailyCounts} appCurrentUserCount={appCurrentUserCount} />}
                    {mode === "player" && playerSummary && <PlayerBanner playerSummary={playerSummary} />}
                </div>

                <div className={classes.pips}>
                    <Tooltip arrow title={<Trans>Team Info</Trans>}>
                        <Link data-id={"team"} style={{ fontWeight: mode === "team" ? "bold" : "" }} onClick={() => this.props.onToggle("team", true)}>
                            <Trans>Team</Trans>
                        </Link>
                    </Tooltip>

                    <PipIcon />

                    <Tooltip arrow title={<Trans>App Info</Trans>}>
                        <Link
                            data-id={"app"}
                            style={{ fontWeight: mode === "app" ? "bold" : "", cursor: !location.pathname.startsWith("/app") ? "not-allowed" : "pointer" }}
                            onClick={() => {
                                if (location.pathname.startsWith("/app")) this.props.onToggle("app", true);
                            }}
                        >
                            <Trans>App</Trans>
                        </Link>
                    </Tooltip>

                    <PipIcon />

                    <Tooltip arrow title={<Trans>Player Info</Trans>}>
                        <Link
                            data-id={"player"}
                            style={{ fontWeight: mode === "player" ? "bold" : "", cursor: !location.pathname.startsWith("/app/user") || !playerSummary ? "not-allowed" : "pointer" }}
                            onClick={() => {
                                if (location.pathname.startsWith("/app/user") && playerSummary) this.props.onToggle("player", true);
                            }}
                        >
                            <Trans>User</Trans>
                        </Link>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            position: "relative",
            overflow: "hidden",

            "& > *": {
                overflowY: "hidden",
                overflowX: "auto",
            },

            maxHeight: "calc(8.75rem + var(--scrollbar-size, 0))",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625rem",

            transition: "max-height 0.2s linear, border-bottom-width 0.2s linear",
        },
        banner: {
            borderStyle: "solid",
            borderWidth: "0 0.3125em",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",

            "& > *": {
                backgroundColor: "var(--secondary-background-color, inherit)",
                color: "var(--secondary-color, inherit)",
                borderColor: "var(--secondary-border-color, inherit)",

                borderRadius: "0.25em",
            },
        },
        pips: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",

            fontSize: "0.75em",
            padding: "0.3125em",
            gap: "0.3125em",

            "& .MuiTypography-root": {
                color: "inherit",
                textAlign: "center",
                margin: "0.2em",
            },
            "& > .MuiSvgIcon-root": {
                width: "0.25em",
                height: "0.25em",
            },
        },
        closed: {
            maxHeight: 0,
            borderBottomWidth: 0,
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(TeamAndAppInfoBanner)));
