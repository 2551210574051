import { ThemeOptions } from "@material-ui/core";
// import { Theme } from "@material-ui/core/styles/createMuiTheme";
// import { Styles } from "@material-ui/core/styles/withStyles";

// export const defaultGlobalCSS: Styles<Theme, {}, "@global"> = {
//     // @global is handled by jss-plugin-global.
//     '@global': {
//         // You should target [class*="MuiButton-root"] instead if you nest themes.
//         // '.MuiSelect-root': {
//         //     display: 'flex',
//         //     flexDirection: 'row',
//         //     alignItems: 'center'
//         // },
//         '*::-webkit-scrollbar': {
//             width: '1.4em',
//             height: '1.4em'
//           },
//           '*::-webkit-scrollbar-track': {
//             '-webkit-box-shadow': 'inset 0 0 6px var(--scrollbar-track)'
//           },
//           '*::-webkit-scrollbar-thumb': {
//             backgroundColor: 'var(--scrollbar-thumb)',
//             outline: '1px solid slategrey'
//           }
//     }
// };

const defaultMuiTheme: ThemeOptions = {
    palette: {},
    overrides: {
        /** Font Styling **/
        MuiTypography: {
            root: {
                fontFamily: "inherit !important",
                lineHeight: "inherit",
            },
            body1: {
                lineHeight: "inherit",
            },
            body2: {
                lineHeight: "inherit",
            },
            button: {
                lineHeight: "inherit",
            },
        },
        MuiButton: {
            root: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },
        MuiListItem: {
            root: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },
        MuiMenuItem: {
            root: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },
        MuiTab: {
            root: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },
        MuiChip: {
            root: {
                fontFamily: "inherit",
                lineHeight: "inherit",
            },
        },

        /** Surface Styling (paper, card etc) **/

        MuiPaper: {
            root: {
                backgroundColor: "var(--secondary-background-color, inherit)",
                color: "var(--secondary-color, inherit)",
                borderColor: "var(--secondary-border-color, inherit)",
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",

                "&.MuiAutocomplete-paper": {
                    fontFamily: "inherit",
                    backgroundColor: "var(--popup-menu-background-color, inherit)",
                    color: "var(--popup-menu-color, inherit)",
                    borderColor: "inherit",
                    boxShadow: "var(--popup-menu-box-shadow, inherit)",

                    "& .MuiAutocomplete-listbox li.MuiAutocomplete-option": {
                        borderColor: "inherit",

                        "&[aria-selected='true']": {
                            backgroundColor: "var(--popup-menu-active-background-color, inherit)",
                            color: "var(--popup-menu-active-color, inherit)",
                        },
                        "&:not([aria-selected='true']):hover": {
                            backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                            color: "var(--popup-menu-hover-color, inherit)",
                        },
                    },
                },

                "&.MuiPopover-paper": {
                    fontFamily: "inherit",
                    backgroundColor: "var(--popup-menu-background-color, inherit)",
                    color: "var(--popup-menu-color, inherit)",
                    borderColor: "inherit",
                    boxShadow: "var(--popup-menu-box-shadow, inherit)",

                    "& > .MuiMenu-list > .MuiListItem-root": {
                        borderColor: "inherit",

                        "&.Mui-selected": {
                            backgroundColor: "var(--popup-menu-active-background-color, inherit)",
                            color: "var(--popup-menu-active-color, inherit)",
                        },

                        "&:not(.Mui-selected):hover": {
                            backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                            color: "var(--popup-menu-hover-color, inherit)",
                        },
                    },
                },
            },
        },
        MuiCard: {
            root: {
                backgroundColor: "var(--secondary-background-color, inherit)",
                color: "var(--secondary-color, inherit)",
                borderColor: "var(--secondary-border-color, inherit)",
                padding: 0,
                margin: "0.3125em",
                flex: "0 0 auto",
                display: "flex",
                flexDirection: "column",
            },
        },
        MuiContainer: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                padding: "0.3125em",
                margin: 0,
                maxWidth: "unset",
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
            },
            maxWidthLg: {
                maxWidth: "unset",
                padding: "0.3125em",
                margin: 0,
            },
            maxWidthMd: {
                maxWidth: "unset",
                padding: "0.3125em",
                margin: 0,
            },
            maxWidthSm: {
                maxWidth: "unset",
                padding: "0.3125em",
                margin: 0,
            },
            maxWidthXl: {
                maxWidth: "unset",
                padding: "0.3125em",
                margin: 0,
            },
            maxWidthXs: {
                maxWidth: "unset",
                padding: "0.3125em",
                margin: 0,
            },
        },

        /** Field Control Styling **/

        MuiSelect: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                "& > .MuiformControll-root, & > .MuiformControll-root > span": {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
            select: {
                "&:focus": {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
            icon: {
                color: "inherit",
                "&.Mui-disabled": {
                    color: "inherit",
                },
            },
        },

        MuiRadio: {
            root: {
                padding: "calc(var(--field-height) * 0.1875)",
                minWidth: "unset",
                width: "var(--field-height)",
                maxWidth: "unset",
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
                lineHeight: "var(--field-line-height)",
                borderRadius: "calc(var(--field-height) / 2)",
                overflow: "hidden",
                "& > .MuiIconButton-label": {
                    width: "calc(var(--field-height) * 0.70588235)",
                    height: "calc(var(--field-height) * 0.70588235)",
                    "& > div > .MuiSvgIcon-root": {
                        width: "calc(var(--field-height) * 0.70588235)",
                        height: "calc(var(--field-height) * 0.70588235)",
                    },
                },
            },
        },

        MuiCheckbox: {
            root: {
                padding: "calc(var(--field-height) * 0.1875)",
                alignSelf: "center",
                minWidth: "unset",
                width: "var(--field-height)",
                maxWidth: "unset",
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
                lineHeight: "var(--field-line-height)",
                borderRadius: "calc(var(--field-height) / 2)",
                overflow: "hidden",
                "& > *": {
                    height: "100%",
                    width: "100%",
                    overflow: "visible",
                    "& > .MuiSvgIcon-root": {
                        width: "100%",
                        height: "100%",
                    },
                },
            },
        },

        MuiSwitch: {
            root: {
                padding: "calc(var(--field-height) * 0.35294118)",
                alignSelf: "center",
                minWidth: "unset",
                width: "calc(var(--field-height) * 1.70588235)",
                maxWidth: "unset",
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
                lineHeight: "var(--field-line-height)",
                borderRadius: "calc(var(--field-height) / 2)",
            },
            switchBase: {
                padding: "calc(var(--field-height) * 0.26470588)",
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
                lineHeight: "var(--field-line-height)",
            },
            thumb: {
                width: "calc(var(--field-height) * 0.58823529)",
                height: "calc(var(--field-height) * 0.58823529)",
            },
        },

        MuiFormGroup: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                alignItems: "stretch",
                "& > .MuiFormControlLabel-root": {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },

        MuiInputBase: {
            root: {
                overflow: "hidden",
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
            },
            formControl: {
                height: "100%",
                width: "100%",
                "& > fieldset": {
                    height: "100%",
                },
            },
            input: {
                height: "100%",
                "&[type=number]": {
                    "-moz-appearance": "textfield",
                },
                "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                },
                "&.Mui-disabled": {
                    textFillColor: "var(--field-disabled-color, inherit)",
                },
            },
        },

        MuiOutlinedInput: {
            root: {
                minHeight: "unset",
                height: "var(--field-height)",
                maxHeight: "unset",
                borderRadius: "0.25em",
            },
            input: {
                minHeight: "unset",
                height: "100%",
                maxHeight: "unset",
                width: "100%",
                paddingTop: 0,
                paddingBottom: 0,
                lineHeight: "var(--field-line-height)",
            },
            multiline: {
                minHeight: "unset",
                height: "100%",
                maxHeight: "unset",
                width: "100%",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: "0.875em",
                paddingRight: 0,
                lineHeight: "var(--field-line-height)",
            },
            notchedOutline: {
                top: 0,
                borderColor: "inherit !important",
                "&:hover": {
                    bordercolor: "inherit !important",
                },
                "& > legend": {
                    height: 0,
                },
            },
        },

        MuiFormControl: {
            root: {
                minHeight: "unset",
                height: "100%",
                maxHeight: "unset",
                width: "100%",
                lineHeight: "var(--field-line-height)",
                borderRadius: "0.25em",

                backgroundColor: "var(--field-background-color, inherit)",
                color: "var(--field-color, inherit)",
                borderColor: "var(--field-border-color, inherit)",

                "& .MuiInputBase-root": {
                    fontSize: "inherit",
                },

                "& .MuiAutocomplete-root, & .MuiAutocomplete-input, & .MuiAutocomplete-inputRoot": {
                    minHeight: "unset",
                    height: "var(--field-height, inherit)",
                    maxHeight: "unset",
                    lineHeight: "var(--field-line-height, inherit)",
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                },

                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]  .MuiAutocomplete-endAdornment': {
                    right: "0.4375em",
                },

                "& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment": {
                    display: "flex",
                    alignItems: "center",
                    top: 0,
                    height: "100%",
                    "& .MuiIconButton-root, & .MuiIconButton-root.Mui-disabled": {
                        color: "inherit",
                    },
                },

                "& .MuiSelect-root": {
                    backgroundColor: "var(--field-background-color, inherit)",
                    color: "var(--field-color, inherit)",
                    borderColor: "var(--field-border-color, inherit)",
                },
                "& .MuiSelect-icon": {
                    color: "var(--field-color, inherit)",
                },

                "&.Mui-disabled .MuiSelect-root": {
                    backgroundColor: "var(--field-disabled-background-color, inherit)",
                    color: "var(--field-disabled-color, inherit)",
                    borderColor: "var(--field-disabled-border-color, inherit)",
                },
                "&.Mui-disabled .MuiSelect-icon": {
                    color: "var(--field-disabled-color, inherit)",
                },
                "&.Mui-focused:not(.Mui-disabled) .MuiSelect-root": {
                    backgroundColor: "var(--field-focused-background-color, inherit)",
                    color: "var(--field-focused-color, inherit)",
                    borderColor: "var(--field-focused-border-color, inherit)",
                },
                "&.Mui-focused:not(.Mui-disabled) .MuiSelect-icon": {
                    color: "var(--field-focused-color, inherit)",
                },
                "&.Mui-error .MuiSelect-root": {
                    backgroundColor: "var(--field-error-background-color, inherit)",
                    color: "var(--field-error-color, inherit)",
                    borderColor: "var(--field-error-border-color, inherit)",
                },
                "&.Mui-error .MuiSelect-icon": {
                    color: "var(--field-error-color, inherit)",
                },

                "&.Mui-disabled": {
                    backgroundColor: "var(--field-disabled-background-color, inherit)",
                    color: "var(--field-disabled-color, inherit)",
                    borderColor: "var(--field-disabled-border-color, inherit)",
                    "& .MuiInputBase-root, & .MuiSelect-root": {
                        backgroundColor: "var(--field-disabled-background-color, inherit)",
                        color: "var(--field-disabled-color, inherit)",
                        borderColor: "var(--field-disabled-border-color, inherit)",
                    },
                },
                "&.Mui-focused:not(.Mui-disabled)": {
                    backgroundColor: "var(--field-focused-background-color, inherit)",
                    color: "var(--field-focused-color, inherit)",
                    borderColor: "var(--field-focused-border-color, inherit)",
                    "& .MuiInputBase-root, & .MuiSelect-root": {
                        backgroundColor: "var(--field-focused-background-color, inherit)",
                        color: "var(--field-focused-color, inherit)",
                        borderColor: "var(--field-focused-border-color, inherit)",
                    },
                },
                "&.Mui-error": {
                    backgroundColor: "var(--field-error-background-color, inherit)",
                    color: "var(--field-error-color, inherit)",
                    borderColor: "var(--field-error-border-color, inherit)",
                    "& .MuiInputBase-root, & .MuiSelect-root": {
                        backgroundColor: "var(--field-error-background-color, inherit)",
                        color: "var(--field-error-color, inherit)",
                        borderColor: "var(--field-error-border-color, inherit)",
                    },
                },

                "& .MuiFormControl-root": {
                    backgroundColor: "var(--field-background-color, inherit)",
                    color: "var(--field-color, inherit)",
                    borderColor: "var(--field-border-color, inherit)",
                    "& .MuiInputBase-root": {
                        backgroundColor: "var(--field-background-color, inherit)",
                        color: "var(--field-color, inherit)",
                        borderColor: "var(--field-border-color, inherit)",
                    },
                },
                "&.Mui-disabled .MuiFormControl-root": {
                    backgroundColor: "var(--field-disabled-background-color, inherit)",
                    color: "var(--field-disabled-color, inherit)",
                    borderColor: "var(--field-disabled-border-color, inherit)",
                    "& .MuiInputBase-root": {
                        backgroundColor: "var(--field-disabled-background-color, inherit)",
                        color: "var(--field-disabled-color, inherit)",
                        borderColor: "var(--field-disabled-border-color, inherit)",
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                        display: "none",
                    },
                },
                "&.Mui-focused:not(.Mui-disabled) .MuiFormControl-root": {
                    backgroundColor: "var(--field-focused-background-color, inherit)",
                    color: "var(--field-focused-color, inherit)",
                    borderColor: "var(--field-focused-border-color, inherit)",
                    "& .MuiInputBase-root": {
                        backgroundColor: "var(--field-focused-background-color, inherit)",
                        color: "var(--field-focused-color, inherit)",
                        borderColor: "var(--field-focused-border-color, inherit)",
                    },
                },
                "&.Mui-error .MuiFormControl-root": {
                    backgroundColor: "var(--field-error-background-color, inherit)",
                    color: "var(--field-error-color, inherit)",
                    borderColor: "var(--field-error-border-color, inherit)",
                    "& .MuiInputBase-root": {
                        backgroundColor: "var(--field-error-background-color, inherit)",
                        color: "var(--field-error-color, inherit)",
                        borderColor: "var(--field-error-border-color, inherit)",
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                        display: "none",
                    },
                },
            },
        },

        /** List Stlying **/
        MuiList: {
            root: {
                backgroundColor: "var(--popup-menu-background-color, inherit)",
                color: "var(--popup-menu-color, inherit)",
                borderColor: "var(--popup-menu-border-color, inherit)",
                boxShadow: "var(--popup-menu-box-shadow, inherit)",

                "& > .MuiListItem-root": {
                    minHeight: "unset",
                    height: "var(--field-height)",
                    lineHeight: "var(--field-line-height)",
                    "& > span": {
                        width: "100%",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "var(--popup-menu-active-background-color, inherit)",
                        color: "var(--popup-menu-active-color, inherit)",
                        borderColor: "inherit",
                    },
                },
            },
        },

        /** Tabs Styling **/
        MuiTabs: {
            root: {
                backgroundColor: "var(--tabs-background-color, inherit)",
                color: "var(--tabs-color, inherit)",
                borderColor: "inherit",

                minHeight: "3em",

                "& .MuiTab-root": {
                    minHeight: "3em",
                },

                "& .MuiTab-root:not(.Mui-selected):not(.Mui-disabled):hover": {
                    backgroundColor: "var(--tabs-hover-background-color, inherit)",
                    color: "var(--tabs-hover-color, inherit)",
                    borderColor: "inherit",
                },

                "& .MuiTab-root.Mui-selected": {
                    backgroundColor: "var(--tabs-active-background-color, inherit)",
                    color: "var(--tabs-active-color, inherit)",
                    borderColor: "inherit",
                },

                "& .MuiTab-root.Mui-disabled": {
                    backgroundColor: "var(--tabs-disabled-background-color, inherit)",
                    color: "var(--tabs-disabled-color, inherit)",
                    borderColor: "inherit",
                },

                "& .MuiTabs-indicator": {
                    backgroundColor: "var(--tabs-active-border-color, inherit)",
                    width: "var(--tabs-active-indicator-size)",
                    height: "var(--tabs-active-indicator-size)",
                },
            },
        },

        /** Table Styling **/
        MuiTableContainer: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        MuiTable: {
            root: {
                userSelect: "auto",
            },
        },
        MuiTableHead: {
            root: {
                "& th.MuiTableCell-root.MuiTableCell-stickyHeader": {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                    fontWeight: "bold",
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                "&:hover": {
                    color: "inherit",
                },
            },
        },
        MuiTableBody: {
            root: {
                "& td.MuiTableCell-stickyHeader": {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiTableRow: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                "&.MuiTableRow-hover:hover": {
                    backgroundColor: "inherit",
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiTableCell: {
            root: {
                fontFamily: "inherit",
                fontSize: "1em",
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                overflow: "hidden",
            },
            body: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        MuiTablePagination: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
                borderTopWidth: "0.0625em",
                borderTopStyle: "solid",
            },
            selectRoot: {
                borderRadius: "0.25em",
                overflow: "hidden",
            },
            input: {
                border: "0.0625em solid",
                backgroundColor: "var(--field-background-color, inherit)",
                color: "var(--field-color, inherit)",
                borderColor: "var(--field-border-color, inherit)",
                "&.Mui-disabled": {
                    backgroundColor: "var(--field-disabled-background-color, inherit)",
                    color: "var(--field-disabled-color, inherit)",
                    borderColor: "var(--field-disabled-border-color, inherit)",
                },
                "&.Mui-focused:not(.Mui-disabled)": {
                    backgroundColor: "var(--field-focused-background-color, inherit)",
                    color: "var(--field-focused-color, inherit)",
                    borderColor: "var(--field-focused-border-color, inherit)",
                },
            },
        },

        /** Link Styling **/
        MuiLink: {
            root: {
                cursor: "pointer",
                color: "var(--link-color, inherit)",
            },
        },

        /** Button Styling **/
        MuiIconButton: {
            root: {
                backgroundColor: "inherit",
                color: "inherit",
            },
        },

        /** Tooltip Styling **/
        MuiTooltip: {
            popper: {
                "& > .MuiTooltip-tooltip": {
                    backgroundColor: "var(--tooltip-background-color)",
                    color: "var(--tooltip-color)",
                    border: "0.0625em solid var(--tooltip-border-color)",
                    "& > .MuiTooltip-arrow": {
                        color: "var(--tooltip-border-color)",
                    },
                },
                "&.error > .MuiTooltip-tooltip": {
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--field-error-background-color)",
                    color: "var(--field-error-color)",
                    border: "0.0625em solid var(--field-error-border-color)",
                    "& > .MuiTooltip-arrow": {
                        color: "var(--field-error-border-color)",
                    },
                },
            },
            tooltip: {
                fontSize: "0.875em",
                "&.error > .MuiTooltip-tooltip": {
                    backgroundColor: "var(--field-error-background-color)",
                    color: "var(--field-error-color)",
                    border: "0.0625em solid var(--field-error-border-color)",
                },
                "&.MuiTooltip-tooltipPlacementBottom, &.MuiTooltip-tooltipPlacementTop": {
                    marginTop: "0.5em",
                    marginBottom: "0.5em",
                },
            },
        },
    },
};

export default defaultMuiTheme;
