// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { I18n } from "@lingui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { Theme, createStyles, withStyles, WithStyles, ClickAwayListener } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import { SwitchFieldOptions } from "components/common/form/fields/SwitchField";

// Import components.
import { Divider, Link, Typography, MenuItem } from "@material-ui/core";
import Button from "components/common/button/Button";
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import UserSummary from "./UserSummary";
import UserProfile from "components/common/dialog/User/UserProfile";
import ServerStatus from "components/common/widgets/ServerStatus";
import AppVersion from "components/common/widgets/AppVersion";
import TeamMenu from "./TeamMenu";
import TeamSelector from "./TeamSelector";
import FlyOutPanel from "components/common/FlyOutPanel";

// Import icons.
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronUpIcon from "@material-ui/icons/KeyboardArrowUp";
import WbSunnyOutlined from "@material-ui/icons/WbSunnyOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";

// Import actions.
import { SET_SESSION } from "store/actions/session";

// Import utilities.
import CloneUtils from "utils/Clone";
import EnvironmentInformation from "types/common/EnvironmentInformation";

interface STATE_PROPS {
    environmentInformation: EnvironmentInformation;
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
    setCompanyId: (i18n: I18n, companyId: string, path?: string, history?: History) => void;
    populateCurrentUser: (i18n: I18n, basic: boolean) => void;
    logout: () => void;
}
interface OWN_PROPS {
    open: boolean;

    onToggleOpen: (open?: boolean) => void;

    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        environmentInformation: state.environmentInformation,
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
        setCompanyId: (i18n: I18n, companyId: string, path?: string, history?: History) => dispatch({ type: "company.setCompanyId", payload: { i18n, companyId, path, history } }),
        populateCurrentUser: (i18n: I18n, basic: boolean) => dispatch({ type: "user.populateCurrentUser", payload: { i18n, basic } }),
        logout: () => dispatch({ type: "authentication.logout" }),
    };
};
const TEAM_ITEM_HEIGHT = 40;
const TEAM_ITEM_COUNT = 5;

interface STATE {
    panelType: "basic" | "security" | "all" | null;
    teamSelectorOpen: boolean;
}
class Profile extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        panelType: null,
        teamSelectorOpen: false,
    };

    openUserPanel = (panelType: "basic" | "security" | "all") => {
        this.setState({ panelType });
    };

    closeUserPanel = () => {
        this.setState({ panelType: null });
    };

    onProfileChanged = () => {
        const { i18n } = this.props;

        this.props.populateCurrentUser(i18n, false);
    };

    handleChange = (name: string, value: any) => {
        const { session } = this.props;

        switch (name) {
            case "darkModeEnabled":
                const updatedSessionForDarkMode: Session = CloneUtils.clone(session) as Session;

                updatedSessionForDarkMode.themeMode = updatedSessionForDarkMode.themeMode === "light" ? "dark" : "light";

                // Update the session in the redux store.
                this.props.setSession(updatedSessionForDarkMode);
                this.props.saveSession();

                break;
            default:
            // Do nothing.
        }
    };

    onToggleAppSelector = () => {
        this.setState({ teamSelectorOpen: !this.state.teamSelectorOpen });
    };

    onAppChange = (value: any) => {
        const { i18n, history } = this.props;

        if (value != null) {
            this.props.setCompanyId(i18n, value, "/super/dashboard", history);
        }
    };

    onLegacyPortalClicked = () => {
        const { environmentInformation } = this.props;

        window.setTimeout(() => {
            window.open("https://" + environmentInformation.portalDomainName + "/admin/dashboard", "_self");
        }, 250);
    };

    handleClickAway = () => {
        const { open } = this.props;

        if (open) {
            this.props.onToggleOpen();
            this.closeUserPanel();
        }
    };

    render() {
        const { classes, environmentInformation, session, currentUser, availableCompanies } = this.props;
        const { panelType, teamSelectorOpen } = this.state;

        const targetCompany = availableCompanies.find((item) => item.companyId === session.companyId) || null;

        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <div id="profile-blade" className={classes.root} onClick={this.props.onClick}>
                    <span className={classes.content}>
                        <UserSummary />

                        <Divider className={classes.divider} />

                        <Typography style={{ paddingTop: "0.5em", textAlign: "center" }}>
                            <Trans> Current Team</Trans>
                        </Typography>

                        <span style={{ display: "flex", alignItems: "center", border: "1px solid var(--navigation-app-selector-border-color, inherit)", borderRadius: "0.5em", margin: "0.5em" }} onClick={this.onToggleAppSelector}>
                            <TeamMenu style={{ flex: "1 1 auto", minHeight: TEAM_ITEM_HEIGHT, maxHeight: TEAM_ITEM_HEIGHT }} height={TEAM_ITEM_HEIGHT} teamInfo={targetCompany} />

                            <IconButton id={"toggle-unified-selector"} style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                {!teamSelectorOpen && <ChevronDownIcon />}
                                {teamSelectorOpen && <ChevronUpIcon />}
                            </IconButton>
                        </span>

                        <TeamSelector open={teamSelectorOpen} itemHeight={TEAM_ITEM_HEIGHT} itemCount={Math.min(availableCompanies.length, TEAM_ITEM_COUNT)} onToggleOpen={this.onToggleAppSelector} onAppChange={this.onAppChange} />

                        <Divider className={classes.divider} />

                        {environmentInformation.portalDomainName && (
                            <>
                                <Link data-id={"legacy-portal"} onClick={this.onLegacyPortalClicked} style={{ alignSelf: "center", margin: "0.3125em" }} noWrap>
                                    <Trans>Switch to Legacy View</Trans>
                                </Link>

                                <Divider className={classes.divider} />
                            </>
                        )}

                        <FieldWrapper
                            type={"switch"}
                            name={"darkModeEnabled"}
                            label={<Trans>Theme Mode</Trans>}
                            labelStyle={{ flex: "1 1 auto", flexWrap: "nowrap", marginLeft: "1em" }}
                            controlStyle={{ flex: "0 0 auto", overflow: "hidden" }}
                            value={session.themeMode === "dark" ? true : false}
                            onChange={this.handleChange}
                            options={
                                {
                                    clickableLabel: true,
                                    uncheckedIcon: <WbSunnyOutlined />,
                                    checkedIcon: <FontAwesomeIcon icon={faMoon} />,
                                } as SwitchFieldOptions
                            }
                        />

                        <MenuItem id={"profile"} component={"button"} className={classes.helpItem} onClick={() => this.openUserPanel("basic")}>
                            <Typography>
                                <Trans>Profile</Trans>
                            </Typography>

                            <ChevronRightIcon style={{ fontSize: "1em", width: "1.25em", height: "1.25emm" }} />
                        </MenuItem>

                        <MenuItem id={"security"} component={"button"} className={classes.helpItem} onClick={() => this.openUserPanel("security")}>
                            <Typography>
                                <Trans>Security</Trans>
                            </Typography>

                            <ChevronRightIcon style={{ fontSize: "1em", width: "1.25emm", height: "1.25em" }} />
                        </MenuItem>

                        <Button id={"logout"} type={"semantic-negative-secondary"} style={{ margin: "auto 3em 1em 3em" }} onClick={this.props.logout}>
                            <Trans>Logout</Trans>
                        </Button>

                        <FlyOutPanel
                            open={panelType !== null && currentUser !== null}
                            title={
                                <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <IconButton id={"close-panel"} onClick={() => this.closeUserPanel()}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography>{panelType === "security" ? <Trans>Security</Trans> : <Trans>Profile</Trans>}</Typography>
                                </span>
                            }
                            resizable={false}
                            fullScreen={true}
                        >
                            {panelType && currentUser?.email && <UserProfile context={"self"} buttonAlignment={"vertical"} type={panelType} email={currentUser.email} onClose={this.closeUserPanel} onChangesApplied={this.onProfileChanged} />}
                        </FlyOutPanel>
                    </span>

                    <Divider className={classes.divider} />

                    <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden", padding: "0.3125em" }}>
                        <Link
                            data-id={"cookie-preferences"}
                            className={classes.cookiePreferences}
                            onClick={() => {
                                const observer = new MutationObserver((mutations_list) => {
                                    mutations_list.forEach((mutation) => {
                                        mutation.addedNodes.forEach((added_node) => {
                                            if ((added_node as Element).id === "freeprivacypolicy-com---preferences-center") {
                                                const saveButtons = window.document.getElementsByClassName("cc-cp-foot-save");
                                                const saveButton = saveButtons.length > 0 ? saveButtons[0] : null;

                                                const divListener = (e: Event) => {
                                                    e.stopPropagation();
                                                };

                                                added_node.addEventListener("click", divListener);
                                                added_node.addEventListener("touchstart", divListener);
                                                added_node.addEventListener("touchend", divListener);

                                                const buttonListener = (e: Event) => {
                                                    window.document.body.click();
                                                };

                                                if (saveButton) {
                                                    saveButton.addEventListener("click", buttonListener);
                                                    saveButton.addEventListener("touchstart", buttonListener);
                                                    saveButton.addEventListener("touchend", buttonListener);
                                                }

                                                observer.disconnect();
                                            }
                                        });
                                    });
                                });
                                observer.observe(window.document.body, { subtree: false, childList: true });

                                const cookiePreferencesCenter = window.document.getElementById("open_preferences_center");
                                if (cookiePreferencesCenter) {
                                    cookiePreferencesCenter.dispatchEvent(new Event("click", { bubbles: false, cancelable: false }));
                                }
                            }}
                            noWrap
                        >
                            <Trans>Change Your Cookie Preferences</Trans>
                        </Link>

                        <ServerStatus />

                        <AppVersion />
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--navigation-background-color, inherit)",
            color: "var(--navigation-color, inherit)",
            borderColor: "var(--navigation-border-color, inherit)",

            overflow: "hidden",

            position: "relative",
        },

        header: {
            flex: "0 0 auto",

            height: "2.25em",

            position: "relative",

            overflow: "hidden",
        },

        content: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },

        divider: {
            backgroundColor: "var(--navigation-border-color, inherit)",
        },

        cookiePreferences: {
            flex: "0 0 auto",
            fontSize: "0.75em",
            marginTop: "0.3125em",
            alignSelf: "center",
        },

        helpItem: {
            minHeight: "2.875em",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .MuiSvgIcon-root": {
                flex: "0 0 auto",
                width: "1.5em",
                height: "1.5em",
            },
            "& .MuiTypography-root": {
                flex: "1 1 auto",
                marginRight: "0.25em",
                marginLeft: "0.25em",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withStyles(styles)(Profile))));
