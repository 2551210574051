import Theme from "types/common/Theme";

export interface ThemeAction {
    type: "ADD_THEME" | "UPDATE_THEME" | "DELETE_THEME" | "SET_ACTIVE_THEME";
    payload: Theme | null;
}

export const ADD_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "ADD_THEME",
        payload: theme,
    };
};

export const UPDATE_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "UPDATE_THEME",
        payload: theme,
    };
};

export const DELETE_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "DELETE_THEME",
        payload: theme,
    };
};

export const SET_ACTIVE_THEME = (theme: Theme | null): ThemeAction => {
    return {
        type: "SET_ACTIVE_THEME",
        payload: theme,
    };
};
