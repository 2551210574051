// Unique id for the Session Idle poller.
export const SESSION_IDLE_POLLER_ID = "sessionIdle";

// Unique id for the Server Session Context poller.
export const SERVER_SESSION_CONTEXT_POLLER_ID = "serverSessionContext";

// Unique id for the Authy OneTouch poller.
export const AUTHY_ONETOUCH_POLLER_ID = "authyOneTouch";

// Unique id for the Config Upload
export const ADMIN_TOOLS_CONFIG_POLLER_ID = "configUpload";

// Unique id for the Custom Files Upload
export const ADMIN_TOOLS_CUSTOM_FILE_POLLER_ID = "customFileUpload";

// Unique id for the Deploy options
export const ADMIN_TOOLS_DEPLOYMENT_POLLER_ID = "deployOptions";

// Unique id for the Restore
export const ADMIN_TOOLS_RESTORE_CHECKPOINT_POLLER_ID = "restoreCheckPoint";

// Unique id for the RTT heartbeat poller.
export const RTT_HEARTBEAT_POLLER_ID = "rttHeartbeat";

// Unique id for the Custom Files Upload
export const GLOBAL_FILE_POLLER_ID = "globalFileUpload";

// Collection of reserved poller id's.
// When implementing custom pollers (for your own components), please make sure the id(s) you pick do NOT exist in this list.
// These are used by the internal Portal-X framework (messing with them could have unintended side-effects).
export const RESERVED_POLLER_IDS = [
    SESSION_IDLE_POLLER_ID,
    SERVER_SESSION_CONTEXT_POLLER_ID,
    AUTHY_ONETOUCH_POLLER_ID,
    ADMIN_TOOLS_CONFIG_POLLER_ID,
    ADMIN_TOOLS_CUSTOM_FILE_POLLER_ID,
    ADMIN_TOOLS_DEPLOYMENT_POLLER_ID,
    ADMIN_TOOLS_RESTORE_CHECKPOINT_POLLER_ID,
    RTT_HEARTBEAT_POLLER_ID.replace,
];

// TODO: Clean this up. Poller ID's that are not used by the Portal-X framework should NOT be defined here.
