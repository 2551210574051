// Import libraries.
import React, { CSSProperties } from "react";
import { createStyles, isWidthUp, Theme, withStyles, WithStyles, withWidth, WithWidthProps } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { Resizable, ResizeDirection, NumberSize } from "re-resizable";

// Import components.
import { Divider, Tooltip, Slide } from "@material-ui/core";
import IconButton from "components/common/button/IconButton";

// Import icons.
import CloseIcon from "@material-ui/icons/CloseOutlined";
import FileCopy from "@material-ui/icons/FileCopy";
import DragHandleIcon from "@material-ui/icons/DragHandle";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";

interface OWN_PROPS {
    open: boolean;

    direction?: "left" | "right" | "up" | "down";

    title?: React.ReactNode;
    subTitle?: React.ReactNode;

    copyData?: any;

    resizable?: boolean;
    fullScreen?: boolean;

    onClose?: () => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;

    children: React.ReactNode;
}

interface PROPS extends OWN_PROPS, WithWidthProps, WithStyles<typeof styles> {}

interface STATE {
    defaultFlyoutWidth: string;
    defaultFlyoutHeight: string;

    flyoutWidth: string;
    flyoutHeight: string;

    transitioning: boolean;
}

const MIN_FLYOUT_WIDTH = "300px";
const MIN_FLYOUT_HEIGHT = "300px";

const DEFAULT_WIDTHS = {
    sm: "100%",
    md: MIN_FLYOUT_WIDTH,
    lg: "420px",
    xl: "540px",
};

class FlyOutPanel extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        defaultFlyoutWidth: MIN_FLYOUT_WIDTH,
        defaultFlyoutHeight: MIN_FLYOUT_HEIGHT,

        flyoutWidth: sessionStorage.getItem("resizedWidth_flyout") || MIN_FLYOUT_WIDTH,
        flyoutHeight: sessionStorage.getItem("resizedHeight_flyout") || MIN_FLYOUT_HEIGHT,

        transitioning: false,
    };

    componentDidMount() {
        this.setState({ defaultFlyoutWidth: this.getDefaultFlyoutWidth() });
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.width !== this.props.width) {
            this.setState({ defaultFlyoutWidth: this.getDefaultFlyoutWidth() });
        }
    }

    getDefaultFlyoutWidth = () => {
        const { width } = this.props;

        let defaultFlyoutWidth = DEFAULT_WIDTHS.sm;

        if (width) {
            if (isWidthUp("sm", width)) {
                defaultFlyoutWidth = DEFAULT_WIDTHS.md;

                if (isWidthUp("md", width)) {
                    defaultFlyoutWidth = DEFAULT_WIDTHS.lg;

                    if (isWidthUp("lg", width)) {
                        defaultFlyoutWidth = DEFAULT_WIDTHS.xl;
                    }
                }
            }
        }

        return defaultFlyoutWidth;
    };

    isResizable = () => {
        const { width, resizable = true, fullScreen = false } = this.props;

        return width && isWidthUp("sm", width) && resizable && !fullScreen;
    };

    onResizeStop = (_event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, _delta: NumberSize) => {
        if (this.isResizable()) {
            if (direction === "left" || direction === "right") {
                sessionStorage.setItem("resizedWidth_flyout", elementRef.style.width);
                this.setState({ flyoutWidth: elementRef.style.width });
            }

            if (direction === "top" || direction === "bottom") {
                sessionStorage.setItem("resizedHeight_flyout", elementRef.style.height);
                this.setState({ flyoutHeight: elementRef.style.height });
            }
        }
    };

    onCopyToClipboard = () => {
        const { copyData } = this.props;

        ClipboardUtils.writeText(copyData);
    };

    render() {
        const { width, classes, open, direction = "left", title, subTitle, copyData, fullScreen = false, children } = this.props;
        const { defaultFlyoutWidth, flyoutWidth, flyoutHeight, transitioning } = this.state;

        const style: CSSProperties = {
            zIndex: 1,
            position: "absolute",
            top: direction === "left" || direction === "right" || direction === "down" ? 0 : undefined,
            bottom: direction === "left" || direction === "right" || direction === "up" ? 0 : undefined,
            left: direction === "right" || direction === "up" || direction === "down" ? 0 : undefined,
            right: direction === "left" || direction === "up" || direction === "down" ? 0 : undefined,

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            borderLeftStyle: (this.isResizable() || transitioning) && direction === "left" ? "solid" : "none",
            borderLeftWidth: (this.isResizable() || transitioning) && direction === "left" ? "0.03125em" : 0,
            borderRightStyle: (this.isResizable() || transitioning) && direction === "right" ? "solid" : "none",
            borderRightWidth: (this.isResizable() || transitioning) && direction === "right" ? "0.03125em" : 0,
            borderTopStyle: (this.isResizable() || transitioning) && direction === "up" ? "solid" : "none",
            borderTopWidth: (this.isResizable() || transitioning) && direction === "up" ? "0.03125em" : 0,
            borderBottomStyle: (this.isResizable() || transitioning) && direction === "down" ? "solid" : "none",
            borderBottomWidth: (this.isResizable() || transitioning) && direction === "down" ? "0.03125em" : 0,
        };

        const enabled = {
            top: this.isResizable() && direction === "up",
            right: this.isResizable() && direction === "right",
            bottom: this.isResizable() && direction === "down",
            left: this.isResizable() && direction === "left",
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
        };

        const size = fullScreen
            ? { width: "100%", height: "100%" }
            : {
                  width: (direction === "left" || direction === "right") && width && isWidthUp("sm", width) ? flyoutWidth : "100%",
                  height: direction === "up" || direction === "down" ? flyoutHeight : "100%",
              };

        const handles = {
            left:
                this.isResizable() && direction === "left" ? (
                    <IconButton
                        id={"flyout-resize-handle"}
                        style={{
                            position: "absolute",
                            top: "calc((100% / 2) - (20px / 2))",
                            left: -5,
                            width: 20,
                            height: 20,
                            margin: 0,
                            backgroundColor: "var(--secondary-background-color, inherit)",
                            color: "var(--secondary-color, inherit)",
                            borderColor: "var(--secondary-border-color, inherit)",
                            borderStyle: "solid",
                            borderWidth: "0.03125em",
                            cursor: "col-resize",
                        }}
                    >
                        <DragHandleIcon style={{ width: "100%", height: "100%", transform: "rotate(90deg)" }} />
                    </IconButton>
                ) : undefined,
            right:
                this.isResizable() && direction === "right" ? (
                    <IconButton
                        id={"flyout-resize-handle"}
                        style={{
                            position: "absolute",
                            top: "calc((100% / 2) - (20px / 2))",
                            right: -5,
                            width: 20,
                            height: 20,
                            margin: 0,
                            backgroundColor: "var(--secondary-background-color, inherit)",
                            color: "var(--secondary-color, inherit)",
                            borderColor: "var(--secondary-border-color, inherit)",
                            borderStyle: "solid",
                            borderWidth: "0.03125em",
                            cursor: "col-resize",
                        }}
                    >
                        <DragHandleIcon style={{ width: "100%", height: "100%", transform: "rotate(90deg)" }} />
                    </IconButton>
                ) : undefined,
            top:
                this.isResizable() && direction === "up" ? (
                    <IconButton
                        id={"flyout-resize-handle"}
                        style={{
                            position: "absolute",
                            left: "calc((100% / 2) - (20px / 2))",
                            top: -5,
                            width: 20,
                            height: 20,
                            margin: 0,
                            backgroundColor: "var(--secondary-background-color, inherit)",
                            color: "var(--secondary-color, inherit)",
                            borderColor: "var(--secondary-border-color, inherit)",
                            borderStyle: "solid",
                            borderWidth: "0.03125em",
                            cursor: "row-resize",
                        }}
                    >
                        <DragHandleIcon style={{ width: "100%", height: "100%" }} />
                    </IconButton>
                ) : undefined,
            bottom:
                this.isResizable() && direction === "down" ? (
                    <IconButton
                        id={"flyout-resize-handle"}
                        style={{
                            position: "absolute",
                            left: "calc((100% / 2) - (20px / 2))",
                            bottom: -5,
                            width: 20,
                            height: 20,
                            margin: 0,
                            backgroundColor: "var(--secondary-background-color, inherit)",
                            color: "var(--secondary-color, inherit)",
                            borderColor: "var(--secondary-border-color, inherit)",
                            borderStyle: "solid",
                            borderWidth: "0.03125em",
                            cursor: "row-resize",
                        }}
                    >
                        <DragHandleIcon style={{ width: "100%", height: "100%" }} />
                    </IconButton>
                ) : undefined,
        };

        return (
            <Slide
                direction={direction}
                in={open}
                timeout={250}
                mountOnEnter
                unmountOnExit
                onEnter={() => this.setState({ transitioning: true })}
                onEntered={() => this.setState({ transitioning: false })}
                onExit={() => this.setState({ transitioning: true })}
                onExited={() => this.setState({ transitioning: false })}
            >
                <Resizable style={style} enable={enabled} size={size} minWidth={!fullScreen ? defaultFlyoutWidth : "100%"} maxWidth={"100%"} minHeight={MIN_FLYOUT_HEIGHT} maxHeight={"100%"} onResizeStop={this.onResizeStop} handleComponent={handles}>
                    <div className={classes.root} onClick={this.props.onClick}>
                        <div className={classes.header}>
                            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                <span className={classes.title}>{title}</span>

                                {subTitle && <span className={classes.subTitle}>{subTitle}</span>}
                            </div>

                            <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                                {copyData && (
                                    <Tooltip arrow title={<Trans>Copy All Data</Trans>}>
                                        <IconButton id={"copy-to-clipboard"} onClick={this.onCopyToClipboard}>
                                            <FileCopy />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {this.props.onClose && (
                                    <IconButton id={"dismiss"} onClick={this.props.onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </span>
                        </div>

                        <Divider />

                        <div className={classes.content}>{children}</div>
                    </div>
                </Resizable>
            </Slide>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            overflow: "hidden",

            position: "relative",
        },

        header: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            paddingTop: "0.3125em",
            paddingBottom: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.3125em",
        },

        title: {
            "& .MuiTypography-root": {
                fontSize: "1.25em",
                fontWeight: "bold",

                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },

        subTitle: {
            fontSize: "0.875em",
            fontStyle: "italic",
        },

        content: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            "& > *": {
                margin: "0.3125em",
            },
        },
    });

export default withWidth()(withStyles(styles)(FlyOutPanel));
